<template>
  <v-dialog
    v-model="dialogs.returnInfo"
    max-width="400px"
    @click:outside="hideDialog"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t(`page.return.returnInfos.title`) }}</span>
        <v-spacer />
        <v-btn
          icon
          color="white"
          @click="hideDialog"
        >
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container
          fluid
          class="pa-0"
        >
          <v-list
            two-line
          >
            <v-list-item
              v-for="(item, index) in returnInfos"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.value }}
                </v-list-item-subtitle>
                <v-divider class="mb-2" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="white"
          depressed
          @click="hideDialog"
        >
          {{ $t(`page.return.cancel`) }}
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="copyToClipboard"
        >
          {{ $t(`page.return.copyAll`) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapMutations, mapActions, mapGetters } from 'vuex';

export default {
  props: {
    infos: {
      type: Array,
      default: null
    }
  },

  data: () => ({
    returnInfos: []
  }),

  computed: {
    ...mapGetters('dialogs', {
      dialogs: 'get'
    })
  },

  watch: {
    /**
     * @param {boolean} visible
     * @returns {void}
     */
    /* eslint-disable-next-line */
    'dialogs.returnInfo': function (visible) {
      if (visible) {
        this.returnInfos = this.infos;
      }

      this.$emit(visible ? 'show' : 'hide', this.returnInfo);
    }
  },

  methods: {
    ...mapMutations('notice', {
      addNotice: 'add'
    }),

    ...mapActions('dialogs', {
      hideReturnInfoDialog: 'HIDE_RETURN_INFO'
    }),

    /**
     * Hide the dialog and reset the form.
     *
     * @returns {void}
     */
    hideDialog() {
      this.hideReturnInfoDialog();
    },

    copyToClipboard() {
      // get all text and value from returnInfos and put them into a string
      const text = this.returnInfos.map((item) => `${item.text}: ${item.value}`).join('\n');
      // copy text to clipboard
      navigator.clipboard.writeText(text).then(() => {
        this.addNotice({
          message: this.$t('page.return.copied')
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.licence-type-label {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>
