<template>
  <div>
    <v-container>
      <pv-hero
        ref="hero"
        :title="$t('page.list.title')"
      />
    </v-container>
    <v-main>
      <v-container
        class="table-width"
      >
        <v-data-table
          :headers="headers"
          :items="pruefungen"
          item-key="name"
          :loading="loadingExams"
          :options="options"
          @update:options="onDataTableOptionsUpdate"
        >
          <template #loading>
            <pv-loader type="table-tbody" />
          </template>
          <template #no-data>
            {{ $t('page.list.noData') }}
          </template>
          <template #no-results>
            {{ $t('page.list.noFilterResults') }}
          </template>
          <!-- Override default v-progress-loader -->
          <template #progress>
            <div />
          </template>

          <!-- Start Filter Section -->
          <template #top>
            <v-container
              fluid
            >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <subject-filter
                    v-model="subjectFilterValue"
                  />
                </v-col>
                <v-col
                  id="status-filter"
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="statusFilterValue"
                    :items="status.statusList"
                    :label="$t('page.list.filters.state.label')"
                    chips
                    small-chips
                    multiple
                    outlined
                    dense
                    :hide-details="true"
                    clearable
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="!eiUuid"
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="customerNumberFilterValue"
                    :loading="customerNumberFilterValueLoading"
                    :items="customerNumberList"
                    :no-data-text="$t('page.list.filters.customerNumber.noText')"
                    :label="$t('page.list.filters.customerNumber.label')"
                    :search-input.sync="customerNumberFilterSearch"
                    no-filter
                    return-object
                    outlined
                    dense
                    hide-details
                    clearable
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    id="examOrderNumber"
                    v-model="examOrderNumber"
                    :label="$t('page.list.filters.searchOrderNumber.label')"
                    outlined
                    dense
                    :no-data-text="$t('page.list.filters.searchOrderNumber.noText')"
                    :rules="examOrderNumberRule"
                    clearable
                    hide-details="auto"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="!eiUuid"
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="instituteFilterValue"
                    :loading="instituteFilterValueLoading"
                    :items="instituteList"
                    :no-data-text="$t('page.list.filters.institute.noText')"
                    :label="$t('page.list.filters.institute.label')"
                    :search-input.sync="instituteFilterSearch"
                    no-filter
                    return-object
                    outlined
                    dense
                    hide-details
                    clearable
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="zentrumFilterValue"
                    :disabled="zentrumList.length === 0"
                    :items="zentrumList"
                    item-text="select"
                    item-value="uuid"
                    :label="$t('page.list.filters.examCenter.label')"
                    outlined
                    dense
                    :hide-details="true"
                    clearable
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="telcConnectFilterValue"
                    :items="status.telcConnectList"
                    :label="$t('page.list.filters.telcConnect.label')"
                    outlined
                    dense
                    hide-selected
                    :hide-details="true"
                    clearable
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="lateRegistrationFilterValue"
                    :items="status.lateRegistrationList"
                    :label="$t('page.list.filters.lateRegistration.label')"
                    outlined
                    dense
                    hide-selected
                    :hide-details="true"
                    clearable
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    id="federal-state-filter"
                    v-model="federalStateFilterValue"
                    :items="status.federalStateList"
                    :label="$t('page.list.filters.federalState.label')"
                    outlined
                    dense
                    hide-selected
                    :hide-details="true"
                    clearable
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="countryFilterValue"
                    :items="status.countryList"
                    :label="$t('page.list.filters.country.label')"
                    outlined
                    dense
                    hide-selected
                    :hide-details="true"
                    clearable
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <h3>{{ $t('page.list.filters.examDate.heading') }}</h3>
                </v-col>
                <v-col
                  cols="4"
                  md="3"
                >
                  <date-menu
                    v-model="startDate"
                    icon="$vuetify.icons.startDate"
                    :label="$t('page.list.filters.examDate.start')"
                    :max-date="endDate"
                    :readonly="showAllPastExams"
                  />
                </v-col>
                <v-col
                  cols="4"
                  md="3"
                >
                  <date-menu
                    v-model="endDate"
                    icon="$vuetify.icons.endDate"
                    :label="$t('page.list.filters.examDate.end')"
                    :min-date="startDate"
                    :readonly="showAllPastExams"
                  />
                </v-col>
                <v-col
                  cols="4"
                  md="3"
                >
                  <v-checkbox
                    id="checkbox-all-past"
                    v-model="showAllPastExams"
                    :label="$t('page.list.filters.examDate.allPast')"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-col>
                  <v-btn
                    normal
                    outlined
                    color="primary"
                    @click="resetFilter"
                  >
                    {{ $t('page.list.filters.resetFilters') }}
                    <v-icon
                      right
                      small
                    >
                      $vuetify.icons.reset
                    </v-icon>
                  </v-btn>
                </v-col>

                <v-col>
                  <v-btn
                    normal
                    :outlined="!dashboardLinkButtonClicked"
                    color="primary"
                    min-width="18em"
                    @click="copyDashboardLink"
                  >
                    {{ dashboardLinkButtonText }}
                    <v-icon
                      right
                      small
                    >
                      {{ dashboardLinkButtonIcon }}
                    </v-icon>
                  </v-btn>
                </v-col>

                <v-col
                  v-if="userHasAnyRole('pv_admin')"
                >
                  <tooltip-header-button
                    button-id="importSurpass"
                    :button-text="$t('page.list.surpassExcelImport.button')"
                    :tooltip-string="$t('page.list.surpassExcelImport.tooltip')"
                    vuetify-button-icon-code="importAction"
                    @buttonClicked="showImportSurpassDialog"
                  />
                </v-col>
                <v-col v-if="userHasAnyRole('pv_admin', 'pv_super_user')">
                  <tooltip-header-button
                    :button-text="$t('page.list.exportReport.buttonText')"
                    :tooltip-string="$t('page.list.exportReport.tooltip')"
                    vuetify-button-icon-code="fileDocket"
                    :button-is-loading="reportLoading"
                    @buttonClicked="downloadReport"
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="tooManyResults"
                class="mt-3"
              >
                <v-col>
                  <v-alert
                    outlined
                    prominent
                    text
                    type="warning"
                  >
                    <template #prepend>
                      <v-icon
                        color="warning"
                        left
                      >
                        $vuetify.icons.info
                      </v-icon>
                    </template>
                    <template #default>
                      {{ $t('page.warning.tooManyResults') }}
                    </template>
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <!-- End Filter Section -->

          <!-- Start Resultlist -->
          <template #header.fach="item">
            <span v-html="item.header.text" />
          </template>
          <template #item.auftragsnummer="{ item }">
            <span class="table-cell id">
              <span class="table-cell-inner">
                {{ item.auftragsnummer }}
              </span>
            </span>
          </template>
          <template #item.bydAuftragsnummer="{ item }">
            <span class="table-cell">
              <span class="table-cell-inner">
                {{ item.bydAuftragsnummer }}
              </span>
            </span>
          </template>
          <template #item.fach="{ item }">
            <span class="table-cell">
              <span class="table-cell-inner flex-column align-start">
                <span>
                  {{ item.fach }}
                </span>
                <span
                  v-if="item.id"
                >
                  {{ item.id }}
                </span>
              </span>
            </span>
          </template>
          <template #item.pruefungszentrum="{ item }">
            <span class="table-cell">
              <span class="table-cell-inner table-cell-min-width">
                <v-tooltip
                  max-width="400"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <span
                      class="table-cell-line-wrap"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ getPruefungszentrumName(item) }}
                    </span>
                  </template>
                  <span>{{ getPruefungszentrumName(item) }}</span>
                </v-tooltip>
              </span>
            </span>
          </template>
          <template #item.datum="{ item }">
            <span class="table-cell">
              <span class="table-cell-inner">
                {{ item.datum | formatDate }}
              </span>
            </span>
          </template>
          <template #item.status="{ item }">
            <span class="table-cell">
              <span class="table-cell-inner">
                <v-tooltip
                  v-if="getStatus(item.status).message"
                  max-width="400"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ getStatus(item.status).translation }}
                    </span>
                  </template>
                  <span>{{ getStatus(item.status).message }}</span>
                </v-tooltip>
                <span v-else>
                  {{ getStatus(item.status).translation }}
                </span>
              </span>
            </span>
          </template>
          <template #item.telcConnect="{ item }">
            <span class="table-cell">
              <span class="table-cell-inner">
                <v-tooltip
                  max-width="400"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ isPublished(item) }}
                      </v-icon>
                    </span>
                  </template>
                  <span>{{ $t(`page.tooltips.telcConnect.${isPublishedLabel(item)}`) }}</span>
                </v-tooltip>
              </span>
            </span>
          </template>
          <template #item.lateRegistration="{ item }">
            <span
              class="table-cell"
            >
              <span class="table-cell-inner">
                <v-tooltip
                  max-width="400"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ isLateRegistration(item) }}
                      </v-icon>
                    </span>
                  </template>
                  <span>{{ $t(`page.tooltips.lateRegistration.${isLateRegistrationLabel(item)}`) }}</span>
                </v-tooltip>
              </span>
            </span>
          </template>
          <template #item.teilnehmerSchriftlich="{ item }">
            <span class="table-cell primary--text">
              <b class="table-cell-inner">
                <v-icon
                  color="primary"
                  small
                  class="mr-2 d-none d-md-block"
                >
                  $vuetify.icons.writingGroup
                </v-icon>
                {{ item.teilnehmerSchriftlich }}
              </b>
            </span>
          </template>
          <template #item.teilnehmerMuendlich="{ item }">
            <span class="table-cell primary--text">
              <b class="table-cell-inner">
                <v-icon
                  color="primary"
                  small
                  class="mr-2 d-none d-md-block"
                >
                  $vuetify.icons.oralGroup
                </v-icon>
                {{ item.teilnehmerMuendlich }}
              </b>
            </span>
          </template>
          <template #item.actions="{ item }">
            <span class="table-cell actions">
              <span
                class="table-cell-inner"
                :class="item.status === 'terminated' ? 'single-button' : ''"
              >
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      @click="onTelcConnect(item)"
                    >
                      <v-icon
                        color="primary"
                        small
                      >
                        $vuetify.icons.telcConnectAction
                      </v-icon>
                    </a>
                  </template>
                  <span>{{ $t('page.tooltips.telcConnect.action') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="item.lateRegistration && !item.lateRegistrationEdited"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      @click="onLateRegistration(item)"
                    >
                      <v-icon
                        color="primary"
                        small
                      >
                        $vuetify.icons.lateRegistrationInfoAction
                      </v-icon>
                    </a>
                  </template>
                  <span>{{ $t('page.tooltips.lateRegistration.action') }}</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      @click="$router.push({ name: 'read', params: { readId: item.uuid }, query: queryParams(item.eiUuid)})"
                    >
                      <v-icon
                        color="primary"
                        small
                      >
                        $vuetify.icons.viewAction
                      </v-icon>
                    </a>
                  </template>
                  <span>{{ $t('page.tooltips.view') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="editExamButtonVisible(item)"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      @click="$router.push(item.actions.editExam)"
                    >
                      <v-icon
                        color="primary"
                        small
                      >
                        $vuetify.icons.editAction
                      </v-icon>
                    </a>
                  </template>
                  <span>{{ $t('page.tooltips.edit') }}</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      :href="item.actions.editParticipants"
                      v-on="on"
                    >
                      <v-icon
                        color="primary"
                        small
                      >
                        $vuetify.icons.editParticipantsAction
                      </v-icon>
                    </a>
                  </template>
                  <span>{{ $t('page.tooltips.register') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="(isLateRegistrationPossible(item.datum)) && (item.status === 'submitted' || item.status === 'in_submission')"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      @click="$router.push({ name: 'lateRegistration', params: { lateId: item.uuid }, query: queryParams(item.eiUuid) })"
                    >
                      <v-icon
                        color="primary"
                        small
                      >
                        $vuetify.icons.lateRegistrationAction
                      </v-icon>
                    </a>
                  </template>
                  <span>{{ $t('page.tooltips.registerLater') }}</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      @click="$router.push({ name: 'returnFlow', params: { returnId: item.uuid }, query: { institute: item.eiUuid } })"
                    >
                      <v-icon
                        color="primary"
                        small
                      >
                        $vuetify.icons.returnFlowAction
                      </v-icon>
                    </a>
                  </template>
                  <span>{{ $t('page.tooltips.returnFlow') }}</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      @click="downloadDocket(item)"
                    >
                      <v-icon
                        color="primary"
                        small
                      >
                        $vuetify.icons.fileDocket
                      </v-icon>
                    </a>
                  </template>
                  <span>{{ $t('page.tooltips.downloadDocket') }}</span>
                </v-tooltip>
              </span>
            </span>
          </template>
          <!-- End Resultlist -->
        </v-data-table>

        <!-- Start Add Institute Action Overlay -->
        <v-container>
          <pv-actionbar
            :primary-actions="ui"
            @add="onAdd"
            @setInstitute="onSetInstitute"
          />
        </v-container>
        <!-- End Add Institute Action Overlay -->
      </v-container>
    </v-main>

    <!-- Add SetInstitute dialog -->
    <pv-set-institute-dialog
      :institute-list="instituteList"
      :institutes="institutes"
    />

    <!-- Add telcConnect dialog -->
    <pv-telc-connect-dialog
      :contacts="telcConnectContacts"
      :exam="telcConnectExam"
      :examination-center="telcConnectExaminationCenter"
      @update="updateTelcConnect"
    />

    <!-- Add lateRegistration dialog -->
    <pv-late-registration-dialog
      :late-registrations="lateRegistrations"
      @edited="editedLateRegistrations"
    />

    <!--  Add import surpass dialog  -->
    <import-surpass-results />

    <!-- Before marking late registrations as handled Dialog -->
    <v-dialog
      v-model="beforeLateRegistrationDialog"
      name="before-late-registrations-dialog"
      max-width="400"
    >
      <v-card>
        <v-card-title>
          {{ $t('page.lateRegistration.beforeDialog.title') }}
        </v-card-title>
        <v-card-text>
          <p
            class="text-subtitle-1 mb-0"
            v-html="$t('page.lateRegistration.beforeDialog.text')"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            outlined
            depressed
            @click="beforeLateRegistrationDialog = false"
          >
            {{ $t('page.lateRegistration.beforeDialog.cancelAction') }}
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="handledLateRegistrations"
          >
            {{ $t('page.lateRegistration.beforeDialog.transferAction') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import fileDownload from 'js-file-download';
import _ from 'lodash';
import { matchOrderNumberPattern, stripSpaces } from '@/utils/general';
import storage from '@/utils/storage';
import pvHero from '@/components/Hero';
import pvActionbar from '@/components/Actionbar';
import pvLoader from '@/components/Loader';
import pvSetInstituteDialog from '@/components/dialogs/SetInstitute';
import pvLateRegistrationDialog from '@/components/dialogs/LateRegistration';
import pvTelcConnectDialog from '@/components/dialogs/TelcConnect';
import subjectFilter from '@/components/filters/Subject';
import { getLicence as pfvGetLicence } from '@/services/lizenzverwaltung';
import { getLimitSet as pfvGetLimitSet } from '@/services/limitsetverwaltung';
import { downloadDocket as ptvDownloadDocket } from '@/services/teilnehmerverwaltung';
import ImportSurpassResults from '@/components/dialogs/ImportSurpassResults';
import TooltipHeaderButton from '@/components/TooltipHeaderButton';
import MarkInactiveMixin from '@/mixins/MarkInactive.vue';
import DateMenu from '@/components/DateMenu';
import { endOfTheDay, formatDateForBackendCall, startOfTheDay } from '@/utils/dateutils';
import germanFederalStates from '../data/germanFederalStates';

export default {
  name: 'TelcUserList',

  components: {
    DateMenu,
    TooltipHeaderButton,
    ImportSurpassResults,
    pvHero,
    pvActionbar,
    pvLoader,
    pvSetInstituteDialog,
    pvTelcConnectDialog,
    pvLateRegistrationDialog,
    subjectFilter
  },

  filters: {
    formatDate(value) {
      if (!value) return '';
      return moment(value).format('DD.MM.YYYY');
    }
  },

  mixins: [MarkInactiveMixin],

  inject: [
    'pruefungsfachverwaltung',
    'pruefungsverwaltung',
    'institutverwaltung'
  ],

  data: (vm) => {
    const defaultStartDate = moment().subtract(2, 'months').format('YYYY-MM-DD');
    const defaultEndDate = moment().add(2, 'months').format('YYYY-MM-DD');
    const filterValues = storage.parseFromLocalStorage(storage.FILTERS_KEY) || {};
    const coalesceUndefined = (val, defaultVal) => (val !== undefined ? val : defaultVal);
    return {
      // Search filters model state
      customerNumberFilterValue: filterValues.customerNumber ?? null,
      instituteFilterValue: filterValues.institute ?? null,
      zentrumFilterValue: filterValues.zentrum ?? null,
      federalStateFilterValue: filterValues.federalState ?? null,
      countryFilterValue: filterValues.country ?? null,
      examOrderNumber: filterValues.orderNumber ?? '',
      showAllPastExams: filterValues.allPast ?? false,
      statusFilterValue: filterValues.status ?? false,
      subjectFilterValue: filterValues.subject ?? [],
      lateRegistrationFilterValue: filterValues.lateRegistration ?? null,
      telcConnectFilterValue: filterValues.telcConnect ?? null,
      startDateValue: coalesceUndefined(filterValues.startDate, defaultStartDate),
      endDateValue: coalesceUndefined(filterValues.endDate, defaultEndDate),

      // Search filters derived state
      instituteUuid: filterValues.institute?.uuid ?? filterValues.customerNumber?.uuid ?? null,
      examinationCenterUuid: filterValues.zentrum ?? null,

      // Search filters auxiliary state
      customerNumberFilterSearch: filterValues.customerNumber?.text ?? null,
      customerNumberFilterTimeout: null,
      customerNumberFilterValueLoading: false,
      customerNumberList: filterValues.customerNumber ? [filterValues.customerNumber] : [],

      instituteFilterSearch: filterValues.institute?.text ?? null,
      InstituteFilterTimeout: null,
      instituteFilterValueLoading: false,
      instituteList: filterValues.instituteFilterValue ? [filterValues.institute] : [],

      zentrumList: [],

      // data table options
      options: {
        sortBy: ['datum'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 10
      },

      // Exam search state
      searchExamsTimeout: null,
      searchClock: 0,
      latestSearchResultClock: -1,
      tooManyResults: false,
      exams: [],

      // Currently selected institute
      instituteData: null,
      limits: [],

      // Static data
      institutes: [], // fetched on create => why do we search for exam centers in the backend?
      courses: [],
      defaultStartDate,
      defaultEndDate,

      // View state
      reportLoading: false,
      dashboardLinkButtonClicked: false,

      // Dialog state
      // Late registration dialog
      beforeLateRegistrationDialog: false,
      lateRegistrations: null,
      lateRegistrationExam: null,
      // Telc connect dialog
      telcConnectContacts: [],
      telcConnectExaminationCenter: '',
      telcConnectExam: null,

      // Validation
      examOrderNumberRule: [
        (v) => {
          if (v) {
            return matchOrderNumberPattern(v) || vm.$t('page.list.filters.searchOrderNumber.matchPattern');
          }
          return true;
        }
      ]
    };
  },

  computed: {
    ui() {
      const primaryActions = [];

      const addAction = {
        title: this.$t('page.list.actions.add'),
        color: 'primary',
        icon: '$vuetify.icons.addAction',
        event: 'add',
        hidden: false
      };

      addAction.event = 'setInstitute';

      primaryActions.push(addAction);

      return primaryActions;
    },
    headers() {
      return [
        {
          text: this.$t('page.list.headers.orderNumber.label'),
          align: 'left',
          value: 'auftragsnummer'
        },
        {
          text: this.$t('page.list.headers.bydOrderNumber.label'),
          align: 'left',
          value: 'bydAuftragsnummer'
        },
        {
          text: this.$t('page.create.fields.basicData.examCenter.label'),
          value: 'pruefungszentrum',
          filter: this.zentrumFilter
        },
        {
          text: this.$t('page.list.headers.examSubjectWithId.label'),
          align: 'left',
          sortable: false,
          value: 'fach',
          filter: this.fachFilter
        },
        {
          text: this.$t('page.list.headers.date.label'),
          value: 'datum'
        },
        {
          text: this.$t('page.list.headers.status.label'),
          value: 'status',
          filter: this.statusFilter
        },
        {
          text: this.$t('page.list.headers.telcConnect.label'),
          value: 'telcConnect',
          filter: this.telcConnectFilter
        },
        {
          text: this.$t('page.list.headers.lateRegistration.label'),
          value: 'lateRegistration',
          filter: this.lateRegistrationFilter
        },
        {
          text: this.$t('page.list.headers.attendeesWritten.label'),
          value: 'teilnehmerSchriftlich'
        },
        {
          text: this.$t('page.list.headers.attendeesOral.label'),
          value: 'teilnehmerMuendlich'
        },
        {
          text: this.$t('page.list.headers.actions.label'),
          value: 'actions',
          sortable: false,
          width: '50px'
        }
      ];
    },
    loadingExams() {
      return this.latestSearchResultClock !== this.searchClock;
    },
    status() {
      return {
        statusList: [
          { text: this.$t('page.list.states.open.text'), value: 'open' },
          { text: this.$t('page.list.states.inSubmission.text'), value: 'in_submission' },
          { text: this.$t('page.list.states.submitted.text'), value: 'submitted' },
          { text: this.$t('page.list.states.submissionFailed.text'), value: 'submission_failed' },
          { text: this.$t('page.list.states.terminated.text'), value: 'terminated' },
          { text: this.$t('page.list.states.fulfilmentBlocked.text'), value: 'fulfilment_blocked' },
          { text: this.$t('page.list.states.approved.text'), value: 'approved' },
          { text: this.$t('page.list.states.examConducted.text'), value: 'exam_conducted' },
          { text: this.$t('page.list.states.return_incomplete.text'), value: 'return_incomplete' },
          { text: this.$t('page.list.states.return_completed.text'), value: 'return_completed' },
          { text: this.$t('page.list.states.in_assessment.text'), value: 'in_assessment' },
          { text: this.$t('page.list.states.in_evaluation.text'), value: 'in_evaluation' },
          { text: this.$t('page.list.states.preliminary_evaluation.text'), value: 'preliminary_evaluation' },
          { text: this.$t('page.list.states.evaluation_for_exam.text'), value: 'evaluation_for_exam' },
          { text: this.$t('page.list.states.evaluated.text'), value: 'evaluated' },
          { text: this.$t('page.list.states.certificate_in_dispatch.text'), value: 'certificate_in_dispatch' },
          { text: this.$t('page.list.states.exam_failed.text'), value: 'exam_failed' }
        ],
        telcConnectList: [
          { text: this.$t('page.list.telcConnect.all'), value: null },
          { text: this.$t('page.list.telcConnect.published'), value: true },
          { text: this.$t('page.list.telcConnect.notPublished'), value: false }
        ],
        lateRegistrationList: [
          { text: this.$t('page.list.lateRegistration.all'), value: null },
          { text: this.$t('page.list.lateRegistration.isLateRegistered'), value: true },
          { text: this.$t('page.list.lateRegistration.notLateRegistered'), value: false }
        ],
        federalStateList: germanFederalStates.map((federalState) => ({
          text: this.$t(`page.list.federalStates.${federalState.key}`),
          value: federalState.value
        })),
        countryList: [
          { text: this.$t('page.list.filters.country.all'), value: null },
          { text: this.$t('page.list.filters.country.germany'), value: true },
          { text: this.$t('page.list.filters.country.notGermany'), value: false }
        ],
        statusTranslation: [
          {
            code: 'open',
            translation: this.$t('page.list.states.open.text'),
            message: this.$t('page.list.states.open.message')
          },
          {
            code: 'in_submission',
            translation: this.$t('page.list.states.inSubmission.text'),
            message: this.$t('page.list.states.inSubmission.message')
          },
          {
            code: 'submission_failed',
            translation: this.$t('page.list.states.submissionFailed.text'),
            message: this.$t('page.list.states.submissionFailed.message')
          },
          {
            code: 'submitted',
            translation: this.$t('page.list.states.submitted.text'),
            message: this.$t('page.list.states.submitted.message')
          },
          {
            code: 'terminated',
            translation: this.$t('page.list.states.terminated.text'),
            message: this.$t('page.list.states.terminated.message')
          },
          {
            code: 'fulfilment_blocked',
            translation: this.$t('page.list.states.fulfilmentBlocked.text'),
            message: this.$t('page.list.states.fulfilmentBlocked.message')
          },
          {
            code: 'approved',
            translation: this.$t('page.list.states.approved.text'),
            message: this.$t('page.list.states.approved.message')
          },
          {
            code: 'return_incomplete',
            translation: this.$t('page.list.states.return_incomplete.text'),
            message: this.$t('page.list.states.return_incomplete.message')
          },
          {
            code: 'return_completed',
            translation: this.$t('page.list.states.return_completed.text'),
            message: this.$t('page.list.states.return_completed.message')
          },
          {
            code: 'in_evaluation',
            translation: this.$t('page.list.states.in_evaluation.text'),
            message: this.$t('page.list.states.in_evaluation.message')
          },
          {
            code: 'evaluated',
            translation: this.$t('page.list.states.evaluated.text'),
            message: this.$t('page.list.states.evaluated.message')
          },
          {
            code: 'certificate_in_dispatch',
            translation: this.$t('page.list.states.certificate_in_dispatch.text'),
            message: this.$t('page.list.states.certificate_in_dispatch.message')
          },
          {
            code: 'evaluation_for_exam',
            translation: this.$t('page.list.states.evaluation_for_exam.text'),
            message: this.$t('page.list.states.evaluation_for_exam.message')
          },
          {
            code: 'exam_conducted',
            translation: this.$t('page.list.states.examConducted.text'),
            message: this.$t('page.list.states.examConducted.message')
          },
          {
            code: 'order_in_process',
            translation: this.$t('page.list.states.order_in_process.text'),
            message: this.$t('page.list.states.order_in_process.message')
          },
          {
            code: 'preliminary_evaluation',
            translation: this.$t('page.list.states.preliminary_evaluation.text'),
            message: this.$t('page.list.states.preliminary_evaluation.message')
          },
          {
            code: 'exam_failed',
            translation: this.$t('page.list.states.exam_failed.text'),
            message: this.$t('page.list.states.exam_failed.message')
          },
          {
            code: 'in_assessment',
            translation: this.$t('page.list.states.in_assessment.text'),
            message: this.$t('page.list.states.in_assessment.message')
          }
        ]
      };
    },

    /*
     * Returns the exam filter start date as an ISO string or the empty string if the filter value is unset
     */
    queryStartDate() {
      if (!this.startDate) {
        return '';
      }
      return formatDateForBackendCall(startOfTheDay(this.startDate));
    },

    /*
     * Returns the exam filter end date as an ISO string or the empty string if the filter value is unset
     */
    queryEndDate() {
      if (!this.endDate) {
        return '';
      }
      return formatDateForBackendCall(endOfTheDay(this.endDate));
    },

    startDate: {
      get() {
        return this.showAllPastExams ? '' : this.startDateValue;
      },

      set(val) {
        this.startDateValue = val;
      }
    },

    endDate: {
      get() {
        return this.showAllPastExams ? moment().format('YYYY-MM-DD') : this.endDateValue;
      },

      set(val) {
        this.endDateValue = val;
      }
    },

    searchExamParams() {
      if (matchOrderNumberPattern(this.examOrderNumber)) {
        return {
          examOrderNumber: parseInt(stripSpaces(this.examOrderNumber), 10)
        };
      }
      return {
        examDateFrom: this.queryStartDate || null,
        examDateTo: this.queryEndDate || null,
        examSubjectIds: this.subjectFilterValue || null,
        examinationCenterUuid: this.examinationCenterUuid || null,
        instituteUuid: this.instituteUuid || null,
        statuses: this.statusFilterValue || null,
        telcConnectPublished: this.telcConnectFilterValue,
        lateRegistrations: this.lateRegistrationFilterValue,
        invoiceState: this.federalStateFilterValue,
        isDeliveryCertificatesAddressFromGermany: this.countryFilterValue,
        invoiceCountry: this.federalStateFilterValue ? 'DE' : null
      };
    },

    filtersObject() {
      function coalesceFalsy(val) {
        return val || undefined;
      }

      function coalesceArray(val) {
        return val && val.length ? val : undefined;
      }

      function coalesceNull(val) {
        return val || val === false ? val : undefined;
      }

      function coalesceDefault(val, defaultVal) {
        return val !== defaultVal ? val : undefined;
      }

      const storeMe = {
        customerNumber: coalesceFalsy(this.customerNumberFilterValue),
        federalState: coalesceFalsy(this.federalStateFilterValue),
        institute: coalesceFalsy(this.instituteFilterValue),
        orderNumber: coalesceFalsy(this.examOrderNumber),
        allPast: coalesceFalsy(this.showAllPastExams),
        zentrum: coalesceFalsy(this.zentrumFilterValue),

        subject: coalesceArray(this.subjectFilterValue),
        status: coalesceArray(this.statusFilterValue),

        telcConnect: coalesceNull(this.telcConnectFilterValue),
        lateRegistration: coalesceNull(this.lateRegistrationFilterValue),
        country: coalesceNull(this.countryFilterValue),

        startDate: coalesceDefault(this.startDateValue, this.defaultStartDate),
        endDate: coalesceDefault(this.endDateValue, this.defaultEndDate)
      };

      return _(storeMe).omitBy(_.isUndefined).value();
    },

    filtersUrl() {
      return storage.saveToUrl(this.$router.resolve('/').href, this.filtersObject);
    },

    dashboardLinkButtonText() {
      return this.dashboardLinkButtonClicked ? this.$t('page.list.filters.dashboardLinkButton.text.clicked') : this.$t('page.list.filters.dashboardLinkButton.text.unclicked');
    },

    dashboardLinkButtonIcon() {
      return this.dashboardLinkButtonClicked ? '$vuetify.icons.complete' : '$vuetify.icons.telcConnectAction';
    },

    processedExams() {
      return (this.exams || []).map(this.processSingleSearchResult);
    },

    pruefungen() {
      return matchOrderNumberPattern(this.examOrderNumber) ? this.exams.map(this.processSingleSearchResult) : this.processedExams;
    }
  },

  watch: {
    instituteFilterValue() {
      this.zentrumFilterValue = null;
      this.instituteUuid = this.instituteFilterValue?.uuid;
      this.instituteSelector(this.instituteFilterValue?.uuid);
      this.customerNumberFilterSearch = null;
      if (this.instituteFilterValue) {
        this.createExamCenterList(this.instituteData, this.instituteFilterValue);
      }
    },

    customerNumberFilterValue() {
      this.zentrumFilterValue = null;
      this.instituteUuid = this.customerNumberFilterValue?.uuid;
      this.instituteSelector(this.customerNumberFilterValue?.uuid);
      this.instituteFilterSearch = null;
      if (this.customerNumberFilterValue) {
        this.createExamCenterList(this.instituteData, this.customerNumberFilterValue);
      }
    },

    instituteFilterSearch(val) {
      let value = val;
      if (val) [value] = val.split(':');
      if (value && value.length > 2 && value !== this.instituteFilterValue) {
        this.instituteQuerySelections(value);
      }
    },

    customerNumberFilterSearch(val) {
      let value = val;
      if (val) [value] = val.split(':');
      if (value && value.length > 2 && value !== this.customerNumberFilterValue) {
        this.customerNumberQuerySelections(value);
      }
    },

    zentrumFilterValue(val) {
      if (val) {
        this.examinationCenterUuid = val;

        if (this.instituteFilterValue) this.instituteUuid = this.instituteFilterValue.uuid;
        if (this.customerNumberFilterValue) this.instituteUuid = this.customerNumberFilterValue.uuid;
      } else {
        this.examinationCenterUuid = null;
      }
    },

    searchExamParams(newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) {
        this.searchExams();
      }
    },

    loadingExams() {
      this.options = storage.parseFromLocalStorage(storage.OPTIONS_KEY);
    }

  },

  async created() {
    await this.getCourses();
    await this.pullInstitutes();
    this.searchExams();
  },

  methods: {
    ...mapMutations('notice', {
      addNotice: 'add'
    }),
    ...mapActions('dialogs', {
      showSetInstituteDialog: 'SHOW_SET_INSTITUTE',
      showTelcConnectDialog: 'SHOW_TELC_CONNECT',
      showLateRegistrationDialog: 'SHOW_LATE_REGISTRATION',
      showSurpassImportDialog: 'SHOW_SURPASS_IMPORT'
    }),

    onDataTableOptionsUpdate(newOptions) {
      if (!this.loadingExams) {
        storage.saveToLocalStorage(storage.OPTIONS_KEY, newOptions);
      }
    },

    async downloadReport() {
      this.reportLoading = true;
      const response = await this.pruefungsverwaltung.generateReport(this.searchExamParams);
      this.reportLoading = false;

      if (response.status >= 500) {
        this.addNotice({ type: 'error', message: this.$t('page.errors.generic5xx') });
      } else if (response.status >= 400) {
        this.addNotice({ type: 'error', message: this.$t('page.errors.generic4xx') });
      } else if (response.status === 200) {
        const contentDispositionHeader = response.headers['content-disposition'];
        const filename = contentDispositionHeader?.match(/filename=([^;\s]*)/)?.[1] || `bearbeitungszeiten-${moment().format('YYYY-MM-DD-hh-mm-ss')}.xlsx`;
        fileDownload(response.data, filename);
      }
    },

    showImportSurpassDialog() {
      this.showSurpassImportDialog();
    },

    onAdd() {
      this.$router.push({ name: 'add' });
    },

    instituteQuerySelections(v) {
      this.instituteFilterValueLoading = true;
      clearTimeout(this.instituteFilterTimeout);
      this.instituteFilterTimeout = setTimeout(() => {
        this.institutverwaltung.searchInstitute(v, '').then(({ data: { institutes } }) => {
          this.instituteList = this.instituteResultToDropdownItems(institutes);
          this.instituteFilterValueLoading = false;
        });
      }, 500);
    },

    customerNumberQuerySelections(v) {
      this.customerNumberFilterValueLoading = true;
      clearTimeout(this.customerNumberFilterTimeout);
      this.customerNumberFilterTimeout = setTimeout(() => {
        this.institutverwaltung.searchInstitute('', v).then(({ data: { institutes } }) => {
          this.customerNumberList = this.instituteResultToDropdownItems(institutes);
          this.customerNumberFilterValueLoading = false;
        });
      }, 500);
    },

    instituteResultToDropdownItems(result) {
      return result.map(({ uuid }) => {
        const institute = this.institutes.find((item) => item.uuid === uuid);
        const text = this.maybeInactiveSelectOption(institute);

        return {
          text,
          uuid
        };
      });
    },

    searchExams() {
      this.persistFilters();
      clearTimeout(this.searchExamsTimeout);
      this.searchExamsTimeout = setTimeout(() => {
        const requestNumber = this.tickSearchClock();
        this.exams = [];
        this.pruefungsverwaltung.searchExam(this.searchExamParams).then(({ data }) => {
          if (this.isLatestRequest(requestNumber)) {
            this.setLatestSearchResultClock(requestNumber);
            this.exams = data.exams || [];

            if (data.message) {
              if (data.message.messageId === 'limit_mismatch') this.tooManyResults = true;
            } else {
              this.tooManyResults = false;
            }
          }
        });
      }, 500);
    },

    tickSearchClock() {
      this.searchClock += 1;
      return this.searchClock;
    },

    isLatestRequest(clock) {
      return clock >= this.latestSearchResultClock;
    },

    setLatestSearchResultClock(clock) {
      this.latestSearchResultClock = clock;
    },

    filterByFederalState(exams) {
      return this.federalStateFilterValue ? exams.filter((exam) => exam.invoiceAddress.state === this.federalStateFilterValue) : exams;
    },

    filterByCountry(exams) {
      return this.countryFilterValue !== null ? exams.filter((exam) => (exam.deliveryCertificatesAddress.country === 'DE') === this.countryFilterValue) : exams;
    },

    persistFilters() {
      storage.saveToLocalStorage(storage.FILTERS_KEY, this.filtersObject);
    },

    async copyDashboardLink() {
      try {
        await navigator.clipboard.writeText(this.filtersUrl);
        this.dashboardLinkButtonClicked = true;
        setTimeout(() => {
          this.dashboardLinkButtonClicked = false;
        }, 3000);
      } catch {
        console.error('Unable to copy dashboard link');
      }
    },

    /**
     * Called on the 'setInstitute' event from the add dialog.
     *
     * @returns {void}
     */
    onSetInstitute() {
      this.showSetInstituteDialog();
    },

    /**
     * Called on the 'telcConnect' event from the add dialog.
     *
     * @returns {void}
     */
    onTelcConnect(exam) {
      this.telcConnectExam = { ...this.getExamByUuid(exam.uuid) };
      this.telcConnectContacts = this.getContactsFromExaminationCenter(this.telcConnectExam.examinationCenter.uuid);
      this.telcConnectExaminationCenter = this.telcConnectExam.examinationCenter.name;
      this.showTelcConnectDialog();
    },

    /**
     * Called on the 'lateRegistration' event from the add dialog.
     *
     * @returns {void}
     */
    onLateRegistration(item) {
      this.lateRegistrationExam = (this.exams || []).find((exam) => exam.examOrderNumber === item.auftragsnummer);
      this.lateRegistrations = this.lateRegistrationExam.lateRegistrations;
      this.showLateRegistrationDialog();
    },

    getExamByUuid(uuid) {
      return (this.exams || []).find((obj) => obj.uuid === uuid);
    },

    getContactsFromExaminationCenter(examinationCenterUuid) {
      const examCenter = this.getExaminationCenter(examinationCenterUuid);
      if (examCenter !== undefined && examCenter.contacts !== undefined) return examCenter.contacts;
      return null;
    },

    async instituteSelector(uuid = false) {
      if (uuid !== '' && uuid) {
        await this.pullInstitute(uuid);
      }
    },

    async pullInstitute(uuid) {
      return this.institutverwaltung.getInstitute(uuid).then((response) => {
        this.instituteData = response.data.institute;
        if (!this.instituteData.instLicences) {
          this.addNotice({
            message: this.$t('page.list.errors.missingLicence'),
            type: 'error'
          });
        } else {
          this.createExamCenterList(this.instituteData);
          this.pullLicence();
        }
      });
    },

    pullInstitutes() {
      return this.institutverwaltung.getInstitutes().then((response) => {
        this.institutes = response.data.institutes;
      });
    },

    /**
     * It seems like the following five methods are all used to determine whether a
     * pv_user specifically can click the 'Nachmeldung' button, which seems to be used
     * to add attendees to an exam after the deadline has passed.
     *
     * The problem is that this only applies to licenses of the currently selected
     * (via the ByD-ID or exam center name filter fields) institute.
     *
     * It also very much seems like the limits of the last license fetched is applied
     * to all exam subjects.
     *
     * TODO: fix the logic so it applies correctly to all exams, or else remove
     * completely if it turns out it isn't needed.
     */

    pullLicence() {
      const licenceIds = this.instituteData.instLicences || null;
      if (licenceIds) {
        licenceIds.forEach((id) => {
          const licenceId = id.uuid || id.id; // Smooth switch from id to uuid
          pfvGetLicence(licenceId).then(({ data: { licence } }) => {
            this.pullLimitSet(licence.limitSet);
          });
        });
      }
    },

    pullLimitSet(id) {
      pfvGetLimitSet(id).then(({ data: { limitSet } }) => {
        this.limits = limitSet.limits;
      });
    },

    isAfterRegistrationDeadline(date) {
      const examDate = date || moment().format();
      const dateLate = moment(examDate).subtract(this.limits.registrationDeadline, 'd');
      return moment().isAfter(dateLate, 'day');
    },

    isAfterLateRegistrationDeadline(date) {
      const examDate = date || moment().format();
      const dateLate = moment(examDate).subtract(this.limits.lateRegistrationDeadline, 'd');
      return moment().isAfter(dateLate, 'day');
    },

    isLateRegistrationPossible(date) {
      const examDate = date || moment().format();
      return this.isAfterRegistrationDeadline(examDate) && !this.isAfterLateRegistrationDeadline(examDate);
    },

    resetFilter() {
      this.instituteFilterValue = null;
      this.customerNumberFilterValue = null;
      this.zentrumFilterValue = null;
      this.examinationCenterUuid = '';
      this.zentrumList = [];
      this.statusFilterValue = '';
      this.telcConnectFilterValue = null;
      this.lateRegistrationFilterValue = null;
      this.federalStateFilterValue = null;
      this.countryFilterValue = null;
      this.examOrderNumber = '';
      this.instituteUuid = null;
      this.subjectFilterValue = [];
      this.showAllPastExams = false;

      this.startDateValue = this.defaultStartDate;
      this.endDate = this.defaultEndDate;

      this.options = {
        sortBy: ['datum'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 10
      };
    },

    getCourses() {
      return this.pruefungsfachverwaltung.getCourses().then((response) => {
        this.courses = response.data.examSubjects;
      });
    },
    sumParticipants(arr) {
      return arr.reduce((a, b) => a + b.groupSize, 0);
    },

    createExamCenterList(instituteData, instituteFilterValue = null || instituteData, customerNumberFilterValue = null) {
      // Set examination centers
      if (instituteFilterValue && instituteData?.length) instituteData = instituteData.find((institute) => institute.uuid === instituteFilterValue.uuid);
      if (customerNumberFilterValue && instituteData?.length) instituteData = instituteData.find((institute) => institute.uuid === customerNumberFilterValue.uuid);
      if (instituteData === null) return;
      const { examinationCenters } = instituteData;
      examinationCenters.sort((a, b) => {
        if (a.name === '') { return false; }
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });

      examinationCenters.filter((examCenter) => {
        examCenter.select = `${this.maybeInactiveSelectOption(examCenter)}`;
        return true;
      });

      const headerInstitute = { header: this.$t('page.list.filters.institute.label') };
      const institute = { name: this.instituteData.name, select: this.instituteData.name, uuid: this.instituteData.uuid };
      const headerExaminationCenter = { header: this.$t('page.list.filters.examCenter.label') };

      this.zentrumList = [
        headerInstitute,
        institute,
        headerExaminationCenter,
        ...examinationCenters
      ];

      if (instituteFilterValue) this.zentrumList = examinationCenters;
      if (customerNumberFilterValue) this.zentrumList = examinationCenters;
      if (examinationCenters.length === 0) this.zentrumList = [];
    },

    getStatus(status) {
      return this.status?.statusTranslation?.find((obj) => obj.code === status) || {};
    },

    apiDateFormat(date, fallback = true) {
      if (!date && fallback) return moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      if (moment(date).format() === 'Invalid date') return false;
      return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    },

    getExaminationCenter(examinationCenterUuid, instituteUuid) {
      if (!this.instituteData && !instituteUuid) {
        return false;
      }
      let instituteData = null;
      if (!this.instituteData && instituteUuid) {
        instituteData = this.institutes.find((i) => i.uuid === instituteUuid);
      } else {
        instituteData = this.instituteData;
      }
      if (!instituteData) {
        return false;
      }
      if (examinationCenterUuid === instituteData?.uuid) {
        return instituteData;
      }

      const result = instituteData.examinationCenters.find((obj) => obj.uuid === examinationCenterUuid);
      return result;
    },

    queryParams(instituteUuid) {
      return !this.eiUuid ? { institute: instituteUuid } : {};
    },

    updateTelcConnect(exam) {
      this.pruefungsverwaltung.updateExam(exam.examinationInstitute.uuid, exam.uuid, exam).then((response) => {
        if (response.status === 200) {
          this.searchExams();
          this.addNotice({
            message: this.$t('page.create.messages.success'),
            type: 'success'
          });
        }
      });
    },

    isPublished(exam) {
      return exam.telcConnect?.published ? '$vuetify.icons.success' : '$vuetify.icons.clear';
    },

    isPublishedLabel(exam) {
      return exam.telcConnect?.published ? 'published' : 'notPublished';
    },

    isLateRegistration(exam) {
      return exam.lateRegistration ? '$vuetify.icons.success' : '$vuetify.icons.clear';
    },

    isLateRegistrationLabel(exam) {
      return exam.lateRegistration ? 'isLateRegistered' : 'notLateRegistered';
    },

    /**
     * Download the docket for the institute and exam given.
     *
     * @param {String} examinationInstituteId
     * @param {String} examId
     * @returns {Promise}
     */
    downloadDocket(tableItem) {
      return ptvDownloadDocket(tableItem).then((response) => {
        fileDownload(response.data, `Deckblatt_${tableItem.auftragsnummer}.pdf`);
      }).catch(() => {
        this.addNotice({
          message: this.$t('page.list.errors.downloadError'),
          type: 'error'
        });
      });
    },

    editedLateRegistrations() {
      this.beforeLateRegistrationDialog = true;
    },

    handledLateRegistrations() {
      const exam = this.lateRegistrationExam;
      this.pruefungsverwaltung.setLateRegistrationStatus(exam.examinationInstitute.uuid, exam.uuid).then(() => {
        this.lateRegistrationExam = null;
        this.beforeLateRegistrationDialog = false;
        this.addNotice({
          message: this.$t('page.lateRegistration.messages.success'),
          type: 'success'
        });
        this.$router.go();
      }).catch(() => {
        this.addNotice({
          message: this.$t('page.lateRegistration.messages.error'),
          type: 'error'
        });
      });
    },

    getPruefungszentrumName(item) {
      if (item.pruefungszentrumInaktiv) {
        return `[${this.$t('institutes.inactive')}] ${item.pruefungszentrum}`;
      }
      return item.pruefungszentrum;
    },

    editExamButtonVisible(tableExam) {
      if (tableExam.status === 'terminated') {
        return false;
      }
      return !this.isAfterRegistrationDeadline(tableExam.datum) || (tableExam.status === 'open') || this.userHasAnyRole('pv_super_user', 'pv_admin');
    },

    processSingleSearchResult(exam) {
      const editExamUrl = this.eiUuid !== '' ? `/bearbeiten/${exam.uuid}` : `/bearbeiten/${exam.uuid}?institute=${exam.examinationInstitute.uuid}`;

      const examinationCenter = this.getExaminationCenter(exam.examinationCenter.uuid, exam.examinationInstitute.uuid);
      const address = examinationCenter?.addresses?.find((a) => a.isPrimary && a.isActive);

      return {
        auftragsnummer: exam.examOrderNumber,
        id: exam.examSubject.id > 0 ? exam.examSubject.id.toString() : '',
        fach: exam.examSubject.language.length > 4 ? exam.examSubject.language : this.courses.find((course) => course.id === exam.examSubject.id)?.title,
        pruefungszentrum: address ? `${examinationCenter.name}, ${address.zipCode} ${address.city}` : exam.examinationCenter.name,
        pruefungszentrumInaktiv: examinationCenter?.inactive,
        datum: exam.writingExamGroups[0] !== undefined ? exam.writingExamGroups[0].examDate : null,
        status: exam.status,
        telcConnect: exam.telcConnect,
        lateRegistration: !!exam.lateRegistrations,
        lateRegistrationEdited: !!exam.lateRegistrations?.lateRegistrationEdited,
        teilnehmerSchriftlich: exam.writingExamGroups[0] !== undefined ? this.sumParticipants(exam.writingExamGroups) : null,
        teilnehmerMuendlich: exam.oralExamGroups[0] !== undefined ? this.sumParticipants(exam.oralExamGroups) : null,
        actions: {
          editExam: editExamUrl,
          editParticipants: `/ptv/teilnehmerverwaltung/institut/${exam.examinationInstitute.uuid}/pruefung/${exam.uuid}`
        },
        uuid: exam.uuid,
        eiUuid: exam.examinationInstitute.uuid,
        bydAuftragsnummer: exam.extRef
      };
    }
  }
};
</script>

<style lang="scss">
.table-width {
  max-width: 100%;

  @media (min-width: 600px) {
    max-width: 600px;
  }

  @media (min-width: 960px) {
    max-width: 960px;
  }

  @media (min-width: 1264px) {
    max-width: 1800px
  }

  @media (min-width: 1904px) {
    max-width: 1800px
  }
}
</style>
