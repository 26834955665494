<template>
  <v-tooltip
    max-width="400"
    top
  >
    <template #activator="{ on, attrs }">
      <v-btn
        :id="buttonId"
        v-bind="attrs"
        class="float-sm-right"
        normal
        color="primary"
        outlined
        :loading="buttonIsLoading"
        v-on="on"
        @click="onButtonClicked"
      >
        {{ buttonText }}
        <v-icon
          right
          normal
        >
          $vuetify.icons.{{ vuetifyButtonIconCode }}
        </v-icon>
      </v-btn>
    </template>
    <span>{{ tooltipString }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    tooltipString: { type: String, required: true },
    buttonText: { type: String, required: true },
    vuetifyButtonIconCode: { type: String, required: true },
    buttonIsLoading: { type: Boolean, required: false },
    buttonId: { type: String, required: false, default: '' }
  },
  methods: {
    onButtonClicked() {
      this.$emit('buttonClicked');
    }
  }
};
</script>
