import Keycloak from 'keycloak-js';
import { getAuthURL, getKcRealm, getKcClientId } from '@/utils/general';

const keycloak = new Keycloak({
  url: getAuthURL(),
  realm: getKcRealm(),
  clientId: getKcClientId()
});

const requestInterceptor = (config) => new Promise((resolve) => {
  keycloak.updateToken(5).then(() => {
    config.headers.Authorization = `Bearer ${keycloak.token}`;
    config.headers.cacheControl = 'no-cache';
    config.headers.pragma = 'no-cache';
    resolve(config);
  }).catch(() => {
    keycloak.login();
  });
});

export {
  keycloak,
  requestInterceptor
};
