import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import VMicroFrontend from 'vue-micro-frontend';

import '@/plugins/axios';

import { authorizationMixin } from '@/mixins/authorization';
import { dateTimeMixin } from '@/mixins/date-time';

import { isProduction } from '@/utils/general';
import { keycloak } from '@/plugins/keycloak';

import App from '@/App';
import router from '@/plugins/router';
import vuetify from '@/plugins/vuetify';
import store from '@/plugins/vuex';
import i18n from '@/plugins/i18n';

import toast from '@/plugins/toastify';
import '@/scss/toastification.scss';

import { institutverwaltung } from '@/services/institutverwaltung';
import { pruefungsfachverwaltung } from '@/services/pruefungsfachverwaltung';
import { pruefungsverwaltung } from '@/services/pruefungsverwaltung';
import { teilnehmerverwaltung } from '@/services/teilnehmerverwaltung';
import { lizenzverwaltung } from '@/services/lizenzverwaltung';
import { limitsetverwaltung } from '@/services/limitsetverwaltung';
import { config } from '@/services/config';

Vue.use(VMicroFrontend);
toast.register(Vue);
Vue.config.productionTip = false;

// See https://github.com/vuetifyjs/vuetify/issues/9999
// eslint-disable-next-line
Vue.config.warnHandler = (msg, _vm, _trace) => {
  if (msg === 'The .native modifier for v-on is only valid on components but it was used on <div>.') {
    msg = null;
  }
};

const token = localStorage.getItem('token');
const refreshToken = localStorage.getItem('refresh-token');

keycloak.init({ onLoad: 'login-required', token, refreshToken }).then((auth) => {
  if (!auth) window.location.reload();

  localStorage.setItem('token', keycloak.token ?? '');
  localStorage.setItem('refresh-token', keycloak.refreshToken ?? '');
  localStorage.setItem('current-user', keycloak.tokenParsed.preferred_username ?? '');
  localStorage.setItem('current-roles', keycloak.tokenParsed.realm_access.roles ?? '');
  localStorage.setItem('current-name-family', keycloak.tokenParsed.family_name ?? '');
  localStorage.setItem('current-name-given', keycloak.tokenParsed.given_name ?? '');
  localStorage.setItem('current-name', keycloak.tokenParsed.name ?? '');
  localStorage.setItem('current-email', keycloak.tokenParsed.email ?? '');
  localStorage.setItem('logout-url', keycloak.createLogoutUrl() ?? '');
  localStorage.setItem('login-url', keycloak.createLoginUrl() ?? '');
  localStorage.setItem('eiUuid', keycloak.tokenParsed.EI_UUID ?? '');
  localStorage.setItem('ecUuid', keycloak.tokenParsed.EC_UUID ?? '');

  Vue.mixin({
    methods: {
      debug(...data) {
        if (!isProduction) console.log(data.length > 1 ? data.join('') : data[0]);
      }
    }
  });

  Vue.mixin(authorizationMixin);
  Vue.mixin(dateTimeMixin);

  new Vue({
    router,
    vuetify,
    store,
    i18n,
    provide: {
      institutverwaltung,
      pruefungsfachverwaltung,
      pruefungsverwaltung: pruefungsverwaltung(i18n, store),
      teilnehmerverwaltung,
      lizenzverwaltung,
      config,
      limitsetverwaltung
    },
    render: (h) => h(App)
  }).$mount('#pruefungsverwaltung');
}).catch(() => {
  console.error('Authentication Failed');
});
