/* global env */

const isProduction = (process.env.NODE_ENV || env.NODE_ENV) === 'production';

const capitalize = (string) => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const getCurrentLocale = (fallback = 'de') => localStorage.getItem('locale') ?? fallback;

const getRemoteURL = () => process.env.VUE_APP_REMOTE_URL || env.VUE_APP_REMOTE_URL;

const getAuthURL = () => process.env.VUE_APP_AUTH_BASE_URL || env.VUE_APP_AUTH_BASE_URL;

const getKcClientId = () => process.env.VUE_APP_KC_CLIENT_ID || env.VUE_APP_KC_CLIENT_ID;

const getKcRealm = () => process.env.VUE_APP_KC_REALM || env.VUE_APP_KC_REALM;

const getSystemIdentification = () => process.env.VUE_APP_SAP_SYSTEM_IDENTIFICATION || env.VUE_APP_SAP_SYSTEM_IDENTIFICATION;

const getDownloadportalURL = (platform) => {
  if (platform === 'win') {
    return process.env.VUE_APP_DOWNLOAD_PORTAL_CLIENT_WIN || env.VUE_APP_DOWNLOAD_PORTAL_CLIENT_WIN;
  }

  if (platform === 'mac') {
    return process.env.VUE_APP_DOWNLOAD_PORTAL_CLIENT_MAC || env.VUE_APP_DOWNLOAD_PORTAL_CLIENT_MAC;
  }

  return '';
};

const generateServiceBaseURL = (service) => {
  if (!service) throw new Error('Missing service identifier in format prefix/name');
  const baseURL = process.env.VUE_APP_SERVICE_BASE_URL || env.VUE_APP_SERVICE_BASE_URL;

  return `${baseURL}${service}`;
};

const getPublicContainer = () => {
  if (getRemoteURL().includes('dev')) return 'onlineweltpublicdev';
  if (getRemoteURL().includes('stage')) return 'onlineweltpublicstage';
  return 'onlineweltpublic';
};
const stripSpaces = (str) => str.replace(/\s/g, '');

const matchOrderNumberPattern = (orderNumber) => {
  const orderNumberPattern = /^\d{7}$/;
  return orderNumberPattern.test(stripSpaces(orderNumber || ''));
};

const chain = (...fs) => (item) => {
  fs.forEach((f) => {
    item = f(item);
  });
  return item;
};

export {
  isProduction,
  capitalize,
  getCurrentLocale,
  getRemoteURL,
  getAuthURL,
  getKcClientId,
  getKcRealm,
  getSystemIdentification,
  generateServiceBaseURL,
  getDownloadportalURL,
  getPublicContainer,
  matchOrderNumberPattern,
  stripSpaces,
  chain
};
