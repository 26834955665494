import moment from 'moment/moment';

export const isPassedRegistrationDeadline = (examDate, limits) => {
  const dateLate = moment(examDate).subtract(limits.registrationDeadline, 'd');
  return moment().isAfter(dateLate, 'day');
};

export const isPassedLateRegistrationDeadline = (examDate, limits) => {
  const dateLate = moment(examDate).subtract(limits.lateRegistrationDeadline, 'd');
  return moment().isAfter(dateLate, 'day');
};

export const isLateRegistrationPossible = (examDate, limits) => isPassedRegistrationDeadline(examDate, limits) && !isPassedLateRegistrationDeadline(examDate, limits);
