import store from '@/plugins/vuex';

const FILTERS_KEY = 'TelcUserList-filters';
const OPTIONS_KEY = 'TelcUserList-options';
const CUSTOMERLIST_OPTIONS_KEY = 'CustomerList-options';
const GET_PARAM = 'filters';

function saveToLocalStorage(key, object) {
  localStorage.setItem(key, JSON.stringify(object));
}

function parseFromLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}

function saveToUrl(rootUrl, filters) {
  return new URL(`${rootUrl}?${GET_PARAM}=${JSON.stringify(filters)}`, window.location.origin).href;
}

function parseFromQuery(query) {
  if (!query[GET_PARAM]) return null;
  try {
    return JSON.parse(query[GET_PARAM]);
  } catch {
    store.commit('notice/add', {
      message: 'Invalid dashboard link',
      type: 'error'
    });
    return {};
  }
}

export default {
  saveToLocalStorage,
  parseFromLocalStorage,
  saveToUrl,
  parseFromQuery,
  FILTERS_KEY,
  OPTIONS_KEY,
  CUSTOMERLIST_OPTIONS_KEY
};
