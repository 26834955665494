<template>
  <v-btn
    v-show="scrollToTopButton"
    v-scroll="onScroll"
    class="c-scrollToTop"
    fab
    small
    dark
    fixed
    color="secondary"
    @click="scrollToTop"
  >
    <v-icon small>
      $vuetify.icons.scrollToTop
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  data: () => ({
    scrollToTopButton: false
  }),

  methods: {
    onScroll({ target }) {
      const top = window.pageYOffset || target.scrollTop || 0;
      this.scrollToTopButton = top > 20;
    },

    scrollToTop() {
      this.$vuetify.goTo(0);
    }
  }
};
</script>

<style lang="scss">
.c-scrollToTop {
  bottom: 7rem;
  right: 16px;
  @include media-breakpoint-up("lg") {
    right: 3rem;
  }
}
</style>
