<template>
  <v-dialog
    v-model="dialogs.telcConnect"
    max-width="600px"
    @click:outside="hideDialog"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t(`page.telcConnect.title`) }}</span>
        <v-spacer />
        <v-btn
          v-if="isShowing"
          icon
          @click="hideDialog"
        >
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container
          fluid
          class="pa-0"
        >
          <p class="text-subtitle-1 m-0">
            {{ $t(`page.telcConnect.text`) }}
          </p>
          <v-divider />
          <v-form ref="form">
            <v-row>
              <v-col>
                <v-text-field
                  :value="examinationCenter"
                  readonly
                  :label="$t('page.telcConnect.fields.examinationCenter.label')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="telcConnect.contactPerson"
                  :items="contacts"
                  :label="$t('page.telcConnect.fields.contactPerson.label')"
                  :item-text="item => item.firstName +' '+ item.lastName"
                  item-value="uuid"
                  outlined
                  dense
                  hide-details
                  required
                  :rules="rules.isEmptyRuleBasic"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="licence-type-label">
                  <span>{{ $t('page.telcConnect.fields.licenseType.label') }}</span>
                </span>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="telcConnect.examinerWanted"
                  :label="$t('page.telcConnect.fields.examinerWanted.label')"
                />
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="telcConnect.reviewerWanted"
                  :label="$t('page.telcConnect.fields.reviewerWanted.label')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="telcConnect.remark"
                  :label="$t('page.telcConnect.fields.remark.label')"
                  name="input-7-1"
                  outlined
                  auto-grow
                  value=""
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="approvals.telcConnect.accepted"
                  :label="$t('page.telcConnect.fields.terms.label')"
                  class="mt-0"
                  required
                  :rules="rules.isEmptyRuleBasic"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="white"
          depressed
          @click="removeTelcConnect"
        >
          {{ $t(`page.telcConnect.actions.remove`) }}
        </v-btn>
        <v-btn
          color="white"
          depressed
          @click="hideDialog"
        >
          {{ $t(`page.telcConnect.actions.cancel`) }}
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="publishTelcConnect"
        >
          {{ $t(`page.telcConnect.actions.publish`) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import moment from 'moment';

export default {
  props: {
    exam: {
      type: Object,
      default: null
    },
    contacts: {
      type: Array,
      default: null
    },
    examinationCenter: {
      type: String,
      default: null
    }
  },

  data: (vm) => ({
    telcConnectExam: null,
    telcConnect: {
      published: false,
      examinerWanted: false,
      reviewerWanted: false,
      remark: '',
      contactPerson: ''
    },
    approvals: {
      telcConnect: {
        accepted: false,
        timestamp: moment().format()
      }
    },

    rules: {
      isEmptyRuleBasic: [
        (v) => !!v || vm.$t('page.create.messages.requiredField')
      ]
    }
  }),

  computed: {
    ...mapGetters('dialogs', {
      dialogs: 'get'
    })
  },

  watch: {
    /**
     * @param {boolean} visible
     * @returns {void}
     */
    /* eslint-disable-next-line */
    'dialogs.telcConnect': function (visible) {
      if (visible) {
        this.telcConnectExam = { ...this.exam };
        this.telcConnect = { ...this.telcConnectExam.telcConnect } || this.telcConnect;
        if (!this.telcConnectExam.termsAndConditions.telcConnect) this.telcConnectExam.termsAndConditions.telcConnect = this.approvals.telcConnect;
        this.approvals = this.telcConnectExam.termsAndConditions;
      }

      this.$emit(visible ? 'show' : 'hide', this.telcConnect);
    }
  },

  methods: {
    ...mapActions('dialogs', {
      hideTelcConnectDialog: 'HIDE_TELC_CONNECT'
    }),

    /**
    * Publish to telc Connect
    *
    * @returns {void}
    */
    publishTelcConnect() {
      if (this.validateForm()) {
        this.telcConnect.published = true;
        const exam = JSON.parse(JSON.stringify(this.telcConnectExam));
        exam.telcConnect = { ...this.telcConnect };
        this.$emit('update', exam);
        this.hideDialog();
      }
    },

    /**
    * Remove from telc Connect
    *
    * @returns {void}
    */
    removeTelcConnect() {
      this.telcConnect.published = false;
      const exam = JSON.parse(JSON.stringify(this.telcConnectExam));
      exam.telcConnect = { ...this.telcConnect };
      this.$emit('update', exam);
      this.hideDialog();
    },

    /**
     * Hide the dialog and reset the form.
     *
     * @returns {void}
     */
    hideDialog() {
      this.hideTelcConnectDialog();
      this.resetForm();
    },

    /**
     * Reset the form.
     *
     * @returns {void}
     */
    resetForm() {
      this.$refs.form.reset();
    },

    /**
     * Validate the form.
     *
     * @returns {boolean}
     */
    validateForm() {
      return this.$refs.form.validate();
    },

    /**
     * Transform the approvals to the terms and conditions format
     *
     * @param {Object} approvals
     * @returns {Object}
     */
    transformApprovalsToTerms(approvals) {
      return Object.keys(approvals).reduce((terms, approvalKey) => {
        if (typeof approvals[approvalKey] === 'boolean') {
          terms[approvalKey] = {
            timestamp: moment().format(),
            accepted: approvals[approvalKey]
          };
        } else {
          terms[approvalKey] = approvals[approvalKey];
        }

        return terms;
      }, {});
    }
  }
};
</script>

<style lang="scss" scoped>
.licence-type-label {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>
