import Toast from 'vue-toastification';

let vue;

function register(vueInstance, overrideOptions) {
  const options = {
    transition: 'Vue-Toastification__fade',
    maxToasts: 10,
    newestOnTop: true,
    filterBeforeCreate: (toast, toasts) => {
      if (toasts.filter(
        (t) => t.content === toast.content
      ).length !== 0) {
        return false; // duplicate toasts (by content, i.e. the displayed message) are not displayed
      }
      return toast;
    },
    ...overrideOptions
  };
  vue = vueInstance;
  vue.use(Toast, options);
}

function add({ message, type = 'info' }) {
  if (!message) return;
  if (!vue) console.error('The Toast plugin has not been registered in this Vue instance, please initialize the plugin using the \'register\' function');
  const toastInstance = vue.$toast;
  const toastTypeMap = {
    default: toastInstance.info,
    error: toastInstance.error,
    success: toastInstance.success
  };
  const toastFunc = toastTypeMap[type] || toastTypeMap.default;
  toastFunc(message, {
    position: 'bottom-left',
    timeout: 30000,
    closeOnClick: false,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    draggable: false,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: false,
    icon: false,
    rtl: false
  });
}

function clear() {
  if (!vue) console.error('The Toast plugin has not been registered in this Vue instance, please initialize the plugin using the \'register\' function');
  const toastInstance = vue.$toast;
  toastInstance.clear();
}

export default { register, add, clear };
