<template>
  <v-dialog
    v-model="dialogs.add"
    max-width="500px"
    @click:outside="hideDialog"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t(`page.contactPersons.${action}.title`) }}</span>
        <v-spacer />
        <v-btn
          icon
          @click="hideDialog"
        >
          <v-icon color="white">
            $vuetify.icons.close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container
          fluid
          class="pa-0"
        >
          <p class="text-subtitle-1 m-0">
            {{ $t(`page.contactPersons.${action}.text`) }}
          </p>
          <v-divider />
          <v-form ref="form">
            <v-radio-group
              v-model="fields.title"
              :label="$t('page.contactPersons.add.fields.title.label')"
              :rules="rules.title"
              required
              row
            >
              <template slot="label">
                {{ $t('page.contactPersons.add.fields.title.label') }} <span>*</span>
              </template>
              <v-radio
                :label="$t('page.contactPersons.add.fields.title.male')"
                value="male"
              />
              <v-radio
                :label="$t('page.contactPersons.add.fields.title.female')"
                value="female"
              />
              <v-radio
                :label="$t('page.contactPersons.add.fields.title.diverse')"
                value="diverse"
              />
            </v-radio-group>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="fields.firstName"
                  required
                  :rules="rules.firstName"
                  validate-on-blur
                  hide-details="auto"
                >
                  <template slot="label">
                    {{ $t('page.contactPersons.add.fields.firstName.label') }} <span>*</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="fields.lastName"
                  required
                  :rules="rules.lastName"
                  validate-on-blur
                  hide-details="auto"
                >
                  <template slot="label">
                    {{ $t('page.contactPersons.add.fields.lastName.label') }} <span>*</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="fields.email"
                  required
                  :rules="rules.email"
                  validate-on-blur
                  hide-details="auto"
                >
                  <template slot="label">
                    {{ $t('page.contactPersons.add.fields.email.label') }} <span>*</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="fields.phone"
                  validate-on-blur
                  hide-details="auto"
                >
                  <template slot="label">
                    {{ $t('page.contactPersons.add.fields.phone.label') }}
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  :value="organizationItem.name"
                  disabled
                >
                  <template slot="label">
                    {{ $t('page.contactPersons.add.fields.organization.label') }}
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          depressed
          @click="hideDialog"
        >
          {{ $t(`page.contactPersons.add.actions.cancel`) }}
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="addContact"
        >
          {{ $t(`page.contactPersons.add.actions.add`) }}
          <v-icon
            right
            x-small
          >
            $vuetify.icons.{{ 'addAction' }}
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { CONTACT_PERSON_FIELDS } from '@/utils/fields';

export default {
  props: {
    organization: {
      type: Object,
      default: null
    }
  },

  data: (vm) => ({
    action: 'add',

    fields: { ...CONTACT_PERSON_FIELDS },

    rules: {
      title: [
        (v) => !!v || vm.$t('page.contactPersons.add.fields.title.errors.required')
      ],
      firstName: [
        (v) => !!v || vm.$t('page.contactPersons.add.fields.firstName.errors.required')
      ],
      lastName: [
        (v) => !!v || vm.$t('page.contactPersons.add.fields.lastName.errors.required')
      ],
      email: [
        (v) => !!v || vm.$t('page.contactPersons.add.fields.email.errors.required')
      ]
    }
  }),

  computed: {
    ...mapGetters('dialogs', {
      dialogs: 'get'
    }),

    /**
     * The items used for the organization dropdown.
     *
     * @returns {Object[]}
     */
    organizationItem() {
      if (this.organization) return this.organization;
      return { name: '' };
    }
  },

  watch: {
    /**
     * @param {boolean} visible
     * @returns {void}
     */
    /* eslint-disable-next-line */
    'dialogs.add': async function (visible) {
      if (visible) {
        this.action = 'add';

        this.fields.added = true;
        this.fields.organizationUuid = this.organization.uuid;
      } else {
        this.fields = { ...CONTACT_PERSON_FIELDS };
      }

      this.$emit(visible ? 'show' : 'hide', this.fields);
    }
  },

  methods: {
    ...mapActions('dialogs', {
      hideAddDialog: 'HIDE_ADD'
    }),

    /**
     * Add the contact by emitting an 'add' event on the component tag.
     *
     * @returns {void}
     */
    addContact() {
      if (this.validateForm()) {
        const contact = { ...this.fields };

        this.$emit('add', contact);
        this.hideDialog();
      }
    },

    /**
     * Hide the dialog and reset the form.
     *
     * @returns {void}
     */
    hideDialog() {
      this.hideAddDialog();
      this.resetForm();
    },

    /**
     * Reset the form.
     *
     * @returns {void}
     */
    resetForm() {
      this.$refs.form.reset();
    },

    /**
     * Validate the form.
     *
     * @returns {boolean}
     */
    validateForm() {
      return this.$refs.form.validate();
    }
  }
};
</script>
