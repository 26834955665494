<template>
  <div>
    <v-container>
      <pv-hero
        ref="hero"
        :quick-navigation="ui.quickNavigation"
        :title="$t(`page.${$route.params.id ? 'edit' : 'create'}.title`)"
      >
        <template #sapLink="{ item }">
          <span
            class="c-hero__metaItemValue"
          >
            <span
              v-if="item.sapId === '-'"
            >
              {{ item.sapId }}
            </span>

            <link-to-blank-page
              v-else
              :link-title="item.sapId"
              :link-href="generateSapLink(item.systemIdentification, item.sapId)"
            />
          </span>
        </template>
      </pv-hero>
    </v-container>
    <v-main>
      <v-container>
        <pv-add-form />
      </v-container>
    </v-main>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import {
  getSystemIdentification as pvGetSystemIdentification
} from '@/utils/general';
import pvHero from '@/components/Hero';
import pvAddForm from '@/components/AddForm';
import LinkToBlankPage from '@/components/LinkToBlankPage';

export default {
  name: 'Add',

  components: {
    LinkToBlankPage,
    pvHero,
    pvAddForm
  },

  inject: [
    'pruefungsverwaltung'
  ],

  data: () => ({
    examData: []
  }),

  computed: {
    eiUuid() {
      return localStorage.getItem('eiUuid') || this.$route.query.institute;
    },
    systemIdentification() {
      return pvGetSystemIdentification();
    },
    ui() {
      return {
        quickNavigation: [
          {
            tooltipText: this.$t('metaItems.quickNavigation.editParticipants'),
            icon: '$vuetify.icons.action.editParticipantsAction',
            hidden: !this.isTelcUser,
            // eslint-disable-next-line no-restricted-globals
            action: () => location.replace(`/ptv/teilnehmerverwaltung/institut/${this.examData.examinationInstitute.uuid}/pruefung/${this.examData.uuid}`),
            outlined: false,
            iconColor: 'primary'
          },
          {
            tooltipText: this.$t('metaItems.quickNavigation.returnFlow'),
            icon: '$vuetify.icons.action.returnFlowAction',
            hidden: !this.isTelcUser,
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            action: () => this.$router.push({ name: 'returnFlow', params: { returnId: this.examData.uuid }, query: { institute: this.examData.examinationInstitute.uuid } }),
            outlined: false,
            iconColor: 'primary'

          }
        ]
      };
    }
  },

  created() {
    if (this.$route.params.id) {
      this.pruefungsverwaltung.getExam(this.eiUuid, this.$route.params.id).then(({ data: { exam } }) => {
        this.examData = exam;
        this.updateHero(exam);
      });
    }
  },

  methods: {
    ...mapMutations('notice', {
      addNotice: 'add'
    }),

    updateHero(exam = {}) {
      if (exam.examSubject?.id) {
        this.$refs.hero.addMetaItem({
          position: 1,
          identifier: 'pruefungsId',
          title: this.$t('page.hero.examId'),
          value: exam.examSubject.id
        });
      }

      if (exam.examOrderNumber) {
        this.$refs.hero.addMetaItem({
          position: 2,
          identifier: 'auftragsnummer',
          title: this.$t('page.hero.orderNumber'),
          value: exam.examOrderNumber
        });
      }

      if (exam.extRef && this.isTelcUser) {
        this.$refs.hero.addMetaItem({
          position: 3,
          identifier: 'sapLink',
          title: this.$t('page.hero.sapNumber'),
          sapId: exam.extRef,
          systemIdentification: this.systemIdentification
        });
      }
    },

    editParticipants() {
      this.$router.push({
        name: 'editParticipants',
        params: {
          id: this.$route.params.id
        }
      });
    },

    generateSapLink(systemIdentification, sapId) {
      return `https://${systemIdentification}.sapbydesign.com/sap/public/ap/ui/runtime?bo_ns=http://sap.com/xi/AP/CRM/Global&bo=SalesOrder&node=Root&operation=Open&object_key=${sapId}&key_type=APC_S_BTD_ID`;
    }
  }
};
</script>
