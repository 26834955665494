<template>
  <div>
    <v-container>
      <pv-hero
        ref="hero"
        :title="$t('page.list.title')"
      />
    </v-container>
    <v-main>
      <v-container
        class="table-width"
      >
        <v-data-table
          :headers="headers"
          :items="pruefungen"
          item-key="name"
          :search="searchOrderNumber"
          :custom-filter="filterExamOrderNumber"
          :loading="loadingExams"
          :options="options"
          @update:options="onDataTableOptionsUpdate"
        >
          <template #loading>
            <pv-loader type="table-tbody" />
          </template>
          <template #no-data>
            {{ $t('page.list.noData') }}
          </template>
          <template #no-results>
            {{ $t('page.list.noFilterResults') }}
          </template>
          <!-- Override default v-progress-loader -->
          <template #progress>
            <div />
          </template>

          <template #top>
            <v-container
              fluid
            >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="fachFilterValue"
                    :items="fachItems"
                    :label="$t('page.list.filters.examSubject.label')"
                    outlined
                    dense
                    hide-selected
                    :hide-details="true"
                    clearable
                  />
                </v-col>
                <v-col
                  id="status-filter"
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="statusFilterValue"
                    :items="status.statusList"
                    :label="$t('page.list.filters.state.label')"
                    outlined
                    dense
                    hide-selected
                    :hide-details="true"
                    clearable
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="idFilterValue"
                    :items="fachIdList"
                    :label="$t('page.list.filters.examId.label')"
                    outlined
                    dense
                    hide-selected
                    :hide-details="true"
                    clearable
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="pb-0"
                >
                  <v-text-field
                    id="searchOrderNumber"
                    :value="searchOrderNumber"
                    :label="$t('page.list.filters.searchOrderNumber.label')"
                    outlined
                    dense
                    :rules="searchOrderNumberRule"
                    clearable
                    @input="removeSpaces($event)"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col
                  v-if="!eiUuid"
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="instituteFilterValue"
                    :items="instituteList"
                    item-text="name"
                    item-value="uuid"
                    :label="$t('page.list.filters.institute.label')"
                    outlined
                    dense
                    :hide-details="true"
                    clearable
                  />
                </v-col>

                <v-col
                  v-if="(instituteFilterValue && examCenters.length > 1) || examCenters.length > 1"
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-show="ecUuid === ''"
                    v-model="zentrumFilterValue"
                    :items="zentrumList"
                    item-text="select"
                    item-value="select"
                    :label="$t('page.list.filters.examCenter.label')"
                    outlined
                    dense
                    :hide-details="true"
                    clearable
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="telcConnectFilterValue"
                    :items="status.telcConnectList"
                    :label="$t('page.list.filters.telcConnect.label')"
                    outlined
                    dense
                    hide-selected
                    :hide-details="true"
                    clearable
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <h3>{{ $t('page.list.filters.examDate.heading') }}</h3>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <v-menu
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedStartDate"
                        prepend-icon="$vuetify.icons.startDate"
                        readonly
                        clearable
                        :hide-details="true"
                        v-bind="attrs"
                        @click:clear="startDate = ''; removeLocalStorage('startDate')"
                        v-on="on"
                      >
                        <template slot="label">
                          {{ $t('page.list.filters.examDate.start') }}
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      color="primary"
                      first-day-of-week="1"
                      :max="endDate"
                      no-title
                      scrollable
                      @input="startDateMenu = false; setLocalStorage('startDate',startDate);"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="6"
                  md="3"
                >
                  <v-menu
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedEndDate"
                        prepend-icon="$vuetify.icons.endDate"
                        readonly
                        clearable
                        :hide-details="true"
                        v-bind="attrs"
                        @click:clear="endDate = ''; removeLocalStorage('endDate');"
                        v-on="on"
                      >
                        <template slot="label">
                          {{ $t('page.list.filters.examDate.end') }}
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      color="primary"
                      first-day-of-week="1"
                      :min="startDate"
                      no-title
                      scrollable
                      @input="endDateMenu = false; setLocalStorage('endDate',endDate);"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="mt-3 justify-md-space-between">
                <v-col>
                  <v-btn
                    small
                    outlined
                    color="primary"
                    @click="resetFilter"
                  >
                    {{ $t('page.list.filters.resetFilters') }}
                    <v-icon
                      right
                      x-small
                    >
                      $vuetify.icons.reset
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col class="text-md-right">
                  <v-btn
                    small
                    outlined
                    :disabled="pruefungen.length === 0"
                    color="primary"
                    @click="exportExamsList"
                  >
                    {{ $t('page.list.filters.exportExamsList.export') }}
                    <v-icon
                      right
                      x-small
                    >
                      $vuetify.icons.fileDownload
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template #header.fach="item">
            <span v-html="item.header.text" />
          </template>
          <template #item.auftragsnummer="{ item }">
            <span class="table-cell id">
              <span class="table-cell-inner">
                {{ item.auftragsnummer }}
              </span>
            </span>
          </template>
          <template #item.fach="{ item }">
            <span class="table-cell">
              <span class="table-cell-inner flex-column align-start">
                <span>
                  {{ item.fach }}
                </span>
                <span
                  v-if="item.id"
                >
                  {{ item.id }}
                </span>
              </span>
            </span>
          </template>
          <template #item.pruefungszentrum="{ item }">
            <span class="table-cell">
              <span class="table-cell-inner table-cell-min-width">
                <v-tooltip
                  max-width="400"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <span
                      class="table-cell-line-wrap"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.pruefungszentrum }}
                    </span>
                  </template>
                  <span>{{ item.pruefungszentrum }}</span>
                </v-tooltip>
              </span>
            </span>
          </template>
          <template #item.datum="{ item }">
            <span class="table-cell">
              <span class="table-cell-inner">
                {{ item.datum | formatDate }}
              </span>
            </span>
          </template>
          <template #item.status="{ item }">
            <span class="table-cell">
              <span class="table-cell-inner">
                <v-tooltip
                  v-if="getStatus(item.status).message"
                  max-width="400"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ getStatus(item.status).translation }}
                    </span>
                  </template>
                  <span>{{ getStatus(item.status).message }}</span>
                </v-tooltip>
                <span v-else>
                  {{ getStatus(item.status).translation }}
                </span>
              </span>
            </span>
          </template>
          <template #item.telcConnect="{ item }">
            <span class="table-cell">
              <span class="table-cell-inner">
                <v-tooltip
                  max-width="400"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ isPublished(item) }}
                      </v-icon>
                    </span>
                  </template>
                  <span>{{ $t(`page.tooltips.telcConnect.${isPublishedLabel(item)}`) }}</span>
                </v-tooltip>
              </span>
            </span>
          </template>
          <template #item.teilnehmerSchriftlich="{ item }">
            <span class="table-cell primary--text">
              <b class="table-cell-inner">
                <v-icon
                  color="primary"
                  small
                  class="mr-2 d-none d-md-block"
                >
                  $vuetify.icons.writingGroup
                </v-icon>
                {{ item.teilnehmerSchriftlich }}
              </b>
            </span>
          </template>
          <template #item.teilnehmerMuendlich="{ item }">
            <span class="table-cell primary--text">
              <b class="table-cell-inner">
                <v-icon
                  color="primary"
                  small
                  class="mr-2 d-none d-md-block"
                >
                  $vuetify.icons.oralGroup
                </v-icon>
                {{ item.teilnehmerMuendlich }}
              </b>
            </span>
          </template>
          <template #item.actions="{ item }">
            <span class="table-cell actions">
              <span
                class="table-cell-inner"
                :class="item.status === 'terminated' ? 'single-button' : ''"
              >
                <v-tooltip
                  v-if="!isReadonlyUser"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      @click="onTelcConnect(item)"
                    >
                      <v-icon
                        color="primary"
                        small
                      >
                        $vuetify.icons.telcConnectAction
                      </v-icon>
                    </a>
                  </template>
                  <span>{{ $t('page.tooltips.telcConnect.action') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="isReadOnlyItem(item)"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      @click="$router.push({ name: 'read', params: { readId: item.uuid }, query: queryParams(item.eiUuid)})"
                    >
                      <v-icon
                        color="primary"
                        small
                      >
                        $vuetify.icons.viewAction
                      </v-icon>
                    </a>
                  </template>
                  <span>{{ $t('page.tooltips.view') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-else-if="!isReadonlyUser"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      @click="$router.push(item.actions.editExam)"
                    >
                      <v-icon
                        color="primary"
                        small
                      >
                        $vuetify.icons.editAction
                      </v-icon>
                    </a>
                  </template>
                  <span>{{ $t('page.tooltips.edit') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      :href="item.actions.editParticipants"
                      v-on="on"
                    >
                      <v-icon
                        color="primary"
                        small
                      >
                        $vuetify.icons.editParticipantsAction
                      </v-icon>
                    </a>
                  </template>
                  <span>{{ $t('page.tooltips.register') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="isLateRegistrationButtonShown(item)"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      @click="$router.push({ name: 'lateRegistration', params: { lateId: item.uuid }, query: queryParams(item.eiUuid) })"
                    >
                      <v-icon
                        color="primary"
                        small
                      >
                        $vuetify.icons.lateRegistrationAction
                      </v-icon>
                    </a>
                  </template>
                  <span>{{ $t('page.tooltips.registerLater') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="(isHelperUser || !isReadonlyUser) && isTelcUser"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <a
                      v-bind="attrs"
                      v-on="on"
                      @click="$router.push({ name: 'returnFlow', params: { returnId: item.uuid }, query: { institute: item.eiUuid } })"
                    >
                      <v-icon
                        color="primary"
                        small
                      >
                        $vuetify.icons.returnFlowAction
                      </v-icon>
                    </a>
                  </template>
                  <span>{{ $t('page.tooltips.returnFlow') }}</span>
                </v-tooltip>
              </span>
            </span>
          </template>
        </v-data-table>
        <v-container>
          <pv-actionbar
            :primary-actions="ui"
            @add="onAdd"
          />
        </v-container>
      </v-container>
    </v-main>

    <!-- Add telcConnect dialog -->
    <pv-telc-connect-dialog
      :contacts="telcConnectContacts"
      :exam="telcConnectExam"
      :examination-center="telcConnectExaminationCenter"
      @update="updateTelcConnect"
    />
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { isProduction, matchOrderNumberPattern } from '@/utils/general';
import storage from '@/utils/storage';
import pvHero from '@/components/Hero';
import pvActionbar from '@/components/Actionbar';
import pvLoader from '@/components/Loader';
import pvTelcConnectDialog from '@/components/dialogs/TelcConnect';
import {
  isLateRegistrationPossible,
  isPassedRegistrationDeadline
} from '@/utils/registrationlimits';
import { endOfTheDay, formatDateForBackendCall, startOfTheDay } from '@/utils/dateutils';

export default {
  name: 'CustomerList',

  components: {
    pvHero,
    pvActionbar,
    pvLoader,
    pvTelcConnectDialog
  },

  filters: {
    formatDate(value) {
      if (!value) return '';
      return moment(value).format('DD.MM.YYYY');
    }
  },

  inject: [
    'institutverwaltung',
    'pruefungsfachverwaltung',
    'pruefungsverwaltung',
    'lizenzverwaltung',
    'limitsetverwaltung'
  ],

  data: (vm) => ({
    searchOrderNumber: localStorage.getItem('searchOrderNumber') ?? null,
    statusFilterValue: localStorage.getItem('statusFilterValue') ?? null,
    telcConnectFilterValue: localStorage.getItem('telcConnectFilterValue') ?? null,
    fachFilterValue: null,
    fachItems: [],
    idFilterValue: localStorage.getItem('idFilterValue') ?? null,
    fachIdList: [],
    courses: [],
    instituteFilterValue: null,
    instituteList: [],
    zentrumFilterValue: localStorage.getItem('zentrumFilterValue') ?? null,
    zentrumList: [],
    pruefungen: [],
    instituteData: null,
    examCenter: null,
    examinationCenterInfoDialog: false,
    exams: [],

    licences: [],
    limits: [],
    passRegistrationDeadline: false,
    passLateRegistrationDeadline: false,

    startDateMenu: false,
    startDate: localStorage.getItem('startDate') ?? moment().subtract(2, 'months').format('YYYY-MM-DD'),

    endDateMenu: false,
    endDate: localStorage.getItem('endDate') ?? moment().add(2, 'months').format('YYYY-MM-DD'),

    // data table options
    options: {
      sortBy: ['datum'],
      sortDesc: [true],
      itemsPerPage: 10,
      page: 1
    },

    loadingExams: true,

    telcConnectContacts: [],
    telcConnectExam: null,
    telcConnectExaminationCenter: '',

    // Validation
    searchOrderNumberRule: [
      (v) => {
        if (v) {
          return matchOrderNumberPattern(v) || vm.$t('page.list.filters.searchOrderNumber.matchPattern');
        }
        return true;
      }
    ]
  }),

  computed: {
    ui() {
      const primaryActions = [];

      const addAction = {
        title: this.$t('page.list.actions.add'),
        color: 'primary',
        icon: '$vuetify.icons.addAction',
        event: 'add',
        hidden: false,
        disabled: this.isBlockedInstitue
      };

      if (this.isTelcUser) addAction.event = 'setInstitute';

      if (!this.isReadonlyUser) primaryActions.push(addAction);

      return primaryActions;
    },
    headers() {
      const headers = [
        {
          text: this.$t('page.list.headers.orderNumber.label'),
          align: 'left',
          value: 'auftragsnummer'
        },
        {
          text: this.$t('page.list.headers.examSubjectWithId.label'),
          align: 'left',
          sortable: false,
          value: 'fach',
          filter: this.fachFilter
        },
        {
          text: this.$t('page.list.headers.date.label'),
          value: 'datum'
        },
        {
          text: this.$t('page.list.headers.status.label'),
          value: 'status',
          filter: this.statusFilter
        },
        {
          text: this.$t('page.list.headers.telcConnect.label'),
          value: 'telcConnect',
          filter: this.telcConnectFilter
        },
        {
          text: this.$t('page.list.headers.attendeesWritten.label'),
          value: 'teilnehmerSchriftlich'
        },
        {
          text: this.$t('page.list.headers.attendeesOral.label'),
          value: 'teilnehmerMuendlich'
        },
        {
          text: this.$t('page.list.headers.actions.label'),
          value: 'actions',
          sortable: false,
          width: '50px'
        }
      ];

      if (this.ecUuid === '') headers.splice(2, 0, { text: this.$t('page.create.fields.basicData.examCenter.label'), value: 'pruefungszentrum', filter: this.zentrumFilter });

      return headers;
    },
    status() {
      return {
        statusList: [
          { text: this.$t('page.list.states.all'), value: null },
          { text: this.$t('page.list.states.open.text'), value: 'open' },
          { text: this.$t('page.list.states.inSubmission.text'), value: 'in_submission' },
          { text: this.$t('page.list.states.submitted.text'), value: 'submitted' },
          { text: this.$t('page.list.states.submissionFailed.text'), value: 'submission_failed' },
          { text: this.$t('page.list.states.terminated.text'), value: 'terminated' },
          { text: this.$t('page.list.states.fulfilmentBlocked.text'), value: 'fulfilment_blocked' },
          { text: this.$t('page.list.states.approved.text'), value: 'approved' },
          { text: this.$t('page.list.states.order_in_process.text'), value: 'order_in_process' },
          { text: this.$t('page.list.states.evaluated.text'), value: 'evaluated' },
          { text: this.$t('page.list.states.certificate_in_dispatch.text'), value: 'certificate_in_dispatch' },
          { text: this.$t('page.list.states.exam_failed.text'), value: 'exam_failed' }
        ],
        telcConnectList: [
          { text: this.$t('page.list.telcConnect.all'), value: null },
          { text: this.$t('page.list.telcConnect.published'), value: true },
          { text: this.$t('page.list.telcConnect.notPublished'), value: false }
        ],
        statusTranslation: [
          {
            code: 'open',
            translation: this.$t('page.list.states.open.text'),
            message: this.$t('page.list.states.open.message')
          },
          {
            code: 'in_submission',
            translation: this.$t('page.list.states.inSubmission.text'),
            message: this.$t('page.list.states.inSubmission.message')
          },
          {
            code: 'submission_failed',
            translation: this.$t('page.list.states.submissionFailed.text'),
            message: this.$t('page.list.states.submissionFailed.message')
          },
          {
            code: 'submitted',
            translation: this.$t('page.list.states.submitted.text'),
            message: this.$t('page.list.states.submitted.message')
          },
          {
            code: 'terminated',
            translation: this.$t('page.list.states.terminated.text'),
            message: this.$t('page.list.states.terminated.message')
          },
          {
            code: 'fulfilment_blocked',
            translation: this.$t('page.list.states.fulfilmentBlocked.text'),
            message: this.$t('page.list.states.fulfilmentBlocked.message')
          },
          {
            code: 'approved',
            translation: this.$t('page.list.states.approved.text'),
            message: this.$t('page.list.states.approved.message')
          },
          {
            code: 'return_incomplete',
            translation: this.$t('page.list.states.return_incomplete.text'),
            message: this.$t('page.list.states.return_incomplete.message')
          },
          {
            code: 'return_completed',
            translation: this.$t('page.list.states.return_completed.text'),
            message: this.$t('page.list.states.return_completed.message')
          },
          {
            code: 'in_evaluation',
            translation: this.$t('page.list.states.in_evaluation.text'),
            message: this.$t('page.list.states.in_evaluation.message')
          },
          {
            code: 'evaluated',
            translation: this.$t('page.list.states.evaluated.text'),
            message: this.$t('page.list.states.evaluated.message')
          },
          {
            code: 'certificate_in_dispatch',
            translation: this.$t('page.list.states.certificate_in_dispatch.text'),
            message: this.$t('page.list.states.certificate_in_dispatch.message')
          },
          {
            code: 'evaluation_for_exam',
            translation: this.$t('page.list.states.evaluation_for_exam.text'),
            message: this.$t('page.list.states.evaluation_for_exam.message')
          },
          {
            code: 'exam_conducted',
            translation: this.$t('page.list.states.examConducted.text'),
            message: this.$t('page.list.states.examConducted.message')
          },
          {
            code: 'order_in_process',
            translation: this.$t('page.list.states.order_in_process.text'),
            message: this.$t('page.list.states.order_in_process.message')
          },
          {
            code: 'preliminary_evaluation',
            translation: this.$t('page.list.states.preliminary_evaluation.text'),
            message: this.$t('page.list.states.preliminary_evaluation.message')
          },
          {
            code: 'exam_failed',
            translation: this.$t('page.list.states.exam_failed.text'),
            message: this.$t('page.list.states.exam_failed.message')
          },
          {
            code: 'in_assessment',
            translation: this.$t('page.list.states.in_assessment.text'),
            message: this.$t('page.list.states.in_assessment.message')
          }
        ]
      };
    },

    /*
     * Returns the formatted version of the startDate.
     */
    formattedStartDate() {
      return this.formatDate(this.startDate) || '';
    },

    /*
     * Returns the formatted version of the endDate.
     */
    formattedEndDate() {
      return this.formatDate(this.endDate) || '';
    },

    /*
     * Returns the exam filter start date as an ISO string or the string encoding the 1st of January 1970 if the filter value is unset
     */
    queryStartDate() {
      return formatDateForBackendCall(startOfTheDay(this.startDate || '1970-01-01'));
    },

    /*
     * Returns the exam filter end date as an ISO string or the empty string if the filter value is unset
     */
    queryEndDate() {
      if (!this.endDate) {
        return '';
      }
      return formatDateForBackendCall(endOfTheDay(this.endDate));
    },

    isProduction() {
      return isProduction;
    },

    examCenters() {
      return this.zentrumList.filter((zentrum) => !zentrum.header);
    },

    isBlockedInstitue() {
      if (this.ecUuid !== '') {
        return !!this.examCenter?.isBlocked;
      }
      return this.instituteData?.isBlocked;
    }
  },

  watch: {
    fachFilterValue(val) {
      if (this.fachFilterValue) {
        this.idFilterValue = val;
        localStorage.setItem('idFilterValue', val);
      } else {
        this.idFilterValue = null;
        localStorage.removeItem('idFilterValue');
      }
    },

    idFilterValue(val) {
      if (this.idFilterValue) {
        this.fachFilterValue = val;
      } else {
        this.fachFilterValue = null;
      }
    },

    instituteFilterValue() {
      this.zentrumFilterValue = null;
      localStorage.removeItem('zentrumFilterValue');
      this.instituteSelector(this.instituteFilterValue);
      if (this.instituteFilterValue) this.createExamCenterList(this.instituteData, this.instituteFilterValue);
    },

    zentrumFilterValue(val) {
      if (val) {
        localStorage.setItem('zentrumFilterValue', val);
      } else {
        localStorage.removeItem('zentrumFilterValue');
      }
    },

    statusFilterValue(val) {
      if (val) {
        localStorage.setItem('statusFilterValue', val);
      } else {
        localStorage.removeItem('statusFilterValue');
      }
    },

    telcConnectFilterValue(val) {
      if (val) {
        localStorage.setItem('telcConnectFilterValue', val);
      } else {
        localStorage.removeItem('telcConnectFilterValue');
      }
    },

    async searchOrderNumber(val) {
      if (val && matchOrderNumberPattern(val)) {
        this.pruefungen = [];
        localStorage.setItem('searchOrderNumber', val);

        let uuid = this.eiUuid;
        if (this.eiUuid === '' && this.instituteFilterValue !== '') uuid = this.instituteFilterValue;
        await this.getExams(uuid, moment('1970-01-01').startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'), '');
      } else {
        localStorage.removeItem('searchOrderNumber');
        await this.getExams(this.eiUuid, this.queryStartDate, this.queryEndDate);
      }
    },

    startDate() {
      this.onDateFilterChange();
    },

    endDate() {
      this.onDateFilterChange();
    }

  },
  async created() {
    await this.getCourses();
    await this.instituteSelector(this.eiUuid);
  },

  methods: {
    ...mapMutations('notice', {
      addNotice: 'add'
    }),
    ...mapActions('dialogs', {
      showTelcConnectDialog: 'SHOW_TELC_CONNECT'
    }),

    removeSpaces(event) {
      if (event) {
        this.searchOrderNumber = event.replace(/\s/g, '');
      } else {
        this.searchOrderNumber = '';
      }
    },

    onDataTableOptionsUpdate(newOptions) {
      if (!this.loadingExams) {
        storage.saveToLocalStorage(storage.CUSTOMERLIST_OPTIONS_KEY, newOptions);
      }
    },

    onAdd() {
      this.$router.push({ name: 'add' });
    },

    /**
     * Called on the 'telcConnect' event from the add dialog.
     *
     * @returns {void}
     */
    onTelcConnect(exam) {
      this.telcConnectExam = { ...this.getExamByUuid(exam.uuid) };
      this.telcConnectContacts = this.getContactsFromExaminationCenter(this.telcConnectExam.examinationCenter.uuid);
      this.telcConnectExaminationCenter = this.telcConnectExam.examinationCenter.name;
      this.showTelcConnectDialog();
    },

    async instituteSelector(uuid = false) {
      if (uuid !== '' && uuid) {
        await this.pullInstitute(uuid);
      } else {
        await this.pullInstitutes();
      }
    },

    async pullInstitutes() {
      const { data: { institutes } } = await this.institutverwaltung.getInstitutes();

      this.instituteData = institutes;
      this.createInstituteList(this.instituteData);
      await this.pullLicence();
      await this.getExams(this.eiUuid, this.queryStartDate, this.queryEndDate);
    },

    async pullInstitute(uuid) {
      const { data: { institute } } = await this.institutverwaltung.getInstitute(uuid, true);
      this.instituteData = institute;
      if (!this.instituteData.instLicences) {
        this.addNotice({
          message: this.$t('page.list.errors.missingLicence'),
          type: 'error'
        });
      } else {
        this.createExamCenterList(this.instituteData);
        if (this.ecUuid !== '') this.examCenter = this.getExaminationCenter(this.ecUuid);
        this.updateHero(this.examCenter);
        await this.pullLicence();
        await this.getExams(uuid, this.queryStartDate, this.queryEndDate);
      }
    },

    async pullLicence() {
      const { data: { licences } } = await this.lizenzverwaltung.getLicences();
      this.licences = licences;

      const { data: { limitSets } } = await this.limitsetverwaltung.getLimitSets();
      this.limits = limitSets;
    },

    getLimitsForExamSubject(subjectId) {
      const licences = this.licences.filter((licence) => licence.examSubjects.includes(subjectId));
      if (licences.length < 1) {
        return null;
      }
      const licenceForSubject = licences[0];
      const limits = this.limits.filter((limit) => limit.id === licenceForSubject.limitSet);
      if (limits.length > 1) {
        return null;
      }
      return limits[0].limits;
    },

    isReadOnlyItem(item) {
      if (!item.id) {
        return true; // sometimes item is a string, do not know yet why!
      }
      const limits = this.getLimitsForExamSubject(parseInt(item.id, 10));
      if (!limits) {
        return false;
      }
      return (
        (isPassedRegistrationDeadline(item.datum, limits) || !this.isRegistrationSubject(item))
          && (item.status !== 'open') && this.userRole !== 'pv_admin'
      ) || item.status === 'terminated';
    },

    isLateRegistrationButtonShown(item) {
      if (!item.id) {
        return false; // sometimes item is a string, do not know yet why!
      }
      const limits = this.getLimitsForExamSubject(parseInt(item.id, 10));
      if (!limits) {
        return false;
      }
      return !this.isReadonlyUser
        && (isLateRegistrationPossible(item.datum, limits) && this.isRegistrationSubject(item))
        && (item.status === 'submitted' || item.status === 'in_submission' || item.status === 'approved');
    },

    setLocalStorage(key, value) {
      localStorage.setItem(key, value);
    },
    removeLocalStorage(key) {
      localStorage.removeItem(key);
    },

    resetFilter() {
      localStorage.removeItem('idFilterValue');
      localStorage.removeItem('zentrumFilterValue');
      localStorage.removeItem('telcConnectFilterValue');
      localStorage.removeItem('idFilterValueSearch');
      localStorage.removeItem('fachFilterValueSearch');
      localStorage.removeItem('statusFilterValue');
      localStorage.removeItem('searchOrderNumber');
      localStorage.removeItem('startDate');
      localStorage.removeItem('endDate');

      setTimeout(() => {
        this.fachFilterValue = null;
        this.statusFilterValue = null;
        this.idFilterValue = null;
        this.zentrumFilterValue = null;
        this.telcConnectFilterValue = null;
        this.searchOrderNumber = '';

        const getDate = (date) => {
          const format = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';
          return moment(date ?? undefined).startOf('day').format(format);
        };

        this.startDate = getDate(moment().subtract(2, 'months').format('YYYY-MM-DD'));
        this.endDate = moment(moment().add(2, 'months').subtract(1, 'day').format('YYYY-MM-DD')).endOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');

        this.options = {
          sortBy: ['datum'],
          sortDesc: [true],
          page: 1,
          itemsPerPage: 10
        };
      }, 300);
    },

    filterExamOrderNumber(value, search, item) {
      if (matchOrderNumberPattern(search)) {
        return item.auftragsnummer.toString().indexOf(search) !== -1;
      }
      return true;
    },

    buildSelectData(inputData) {
      let temp = [];
      inputData.forEach((data) => {
        temp = temp.concat([
          {
            text: data.extRef,
            value: data.extRef
          }
        ]);
      });
      return temp;
    },

    extractFachItems(courses) {
      let temp = [];
      courses.forEach((data) => {
        temp = temp.concat([
          {
            text: data.title,
            value: data.id.toString()
          }
        ]);
      });
      return temp;
    },

    extractFachIds(courses) {
      let temp = [];
      courses.forEach((data) => {
        temp = temp.concat([
          {
            text: data.id.toString(),
            value: data.id.toString()
          }
        ]);
      });
      return temp;
    },

    tableData(tableData) {
      const self = this;
      const tempArray = [];
      tableData.forEach((data, idx) => {
        const editExamUrl = this.eiUuid !== '' ? `/bearbeiten/${data.uuid}` : `/bearbeiten/${data.uuid}?institute=${data.examinationInstitute.uuid}`;
        const examinationCenter = this.getExaminationCenter(data.examinationCenter.uuid);
        tempArray[idx] = {
          auftragsnummer: data.examOrderNumber,
          id: data.examSubject.id > 0 ? data.examSubject.id.toString() : '',
          fach: data.examSubject.language.length > 4 ? data.examSubject.language : this.courses.find((course) => course.id === data.examSubject.id)?.title,
          // TODO: The examinationCenter can be undefined here. Is this correct? How do we need to handle this?
          pruefungszentrum: examinationCenter?.select || data.examinationCenter.name,
          datum: data.writingExamGroups[0] !== undefined ? data.writingExamGroups[0].examDate : null,
          status: data.status,
          telcConnect: data.telcConnect,
          teilnehmerSchriftlich: data.writingExamGroups[0] !== undefined ? self.sumParticipants(data.writingExamGroups) : null,
          teilnehmerMuendlich: data.oralExamGroups[0] !== undefined ? self.sumParticipants(data.oralExamGroups) : null,
          actions: {
            editExam: editExamUrl,
            editParticipants: `/ptv/teilnehmerverwaltung/institut/${data.examinationInstitute.uuid}/pruefung/${data.uuid}`
          },
          uuid: data.uuid,
          eiUuid: data.examinationInstitute.uuid
        };
      });

      this.pruefungen = tempArray;
      this.loadingExams = false;
      this.options = storage.parseFromLocalStorage(storage.CUSTOMERLIST_OPTIONS_KEY);
    },

    sumParticipants(arr) {
      return arr.reduce((a, b) => a + b.groupSize, 0);
    },

    createExamCenterList(instituteData, instituteFilterValue = null) {
      // Set examination centers
      if (instituteFilterValue && instituteData.length) instituteData = instituteData.find((institute) => institute.uuid === instituteFilterValue);
      const { examinationCenters } = instituteData;
      examinationCenters.sort((a, b) => {
        if (a.name === '') { return false; }
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });

      examinationCenters.filter((x) => {
        const address = x.addresses.find((y) => y.isPrimary);
        x.select = `${x.name},  ${address.zipCode} ${address.city}`;
        return true;
      });

      const headerInstitute = { header: this.$t('page.list.filters.institute.label') };
      const institute = { name: this.instituteData.name, select: this.instituteData.name, uuid: this.instituteData.uuid };
      const headerExaminationCenter = { header: this.$t('page.list.filters.examCenter.label') };
      this.zentrumList = [
        headerInstitute,
        institute,
        headerExaminationCenter,
        ...examinationCenters
      ];

      if (instituteFilterValue) this.zentrumList = examinationCenters;
    },

    createInstituteList(instituteData) {
      // Set examination centers
      instituteData.sort((a, b) => {
        if (a.name === '') { return false; }
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
      this.instituteList = [...instituteData];
    },

    getStatus(status) {
      return this.status.statusTranslation.find((obj) => obj.code === status) || {};
    },

    fachFilter(value, search, item) {
      if (this.searchOrderNumber && matchOrderNumberPattern(this.searchOrderNumber)) { // ignore other filters if searchOrderNumber is defined and valid
        return true;
      }
      let fach = this.fachFilterValue;
      let id = this.idFilterValue;

      if (id === undefined) id = null;
      if (fach === undefined) fach = null;
      if (fach === null && id === null) return true;
      if (fach === null) {
        return item.id === this.idFilterValue;
      } if (id === null) {
        return value.includes(fach.toString());
      }

      return item.id === this.idFilterValue;
    },

    statusFilter(value) {
      if (this.searchOrderNumber && matchOrderNumberPattern(this.searchOrderNumber)) { // ignore other filters if searchOrderNumber is defined and valid
        return true;
      }
      // If this filter has no value we just skip the entire filter.
      if (!this.statusFilterValue) return true;
      return value === this.statusFilterValue;
    },

    telcConnectFilter(value) {
      if (this.searchOrderNumber && matchOrderNumberPattern(this.searchOrderNumber)) { // ignore other filters if searchOrderNumber is defined and valid
        return true;
      }
      // If this filter has no value we just skip the entire filter.
      if (this.telcConnectFilterValue === undefined || this.telcConnectFilterValue === null) return true;

      if (this.telcConnectFilterValue === true && value?.published === true) return true;

      if (this.telcConnectFilterValue === false && value?.published === false) return true;

      if (this.telcConnectFilterValue === false && value === undefined) return true;

      return false;
    },

    zentrumFilter(value) {
      if (this.searchOrderNumber && matchOrderNumberPattern(this.searchOrderNumber)) { // ignore other filters if searchOrderNumber is defined and valid
        return true;
      }
      // If this filter has no value we just skip the entire filter.
      if (this.eiUuid === '' && !this.isTelcUser) {
        if (!this.instituteFilterValue) return true;
        const institute = this.findInstitute(this.instituteFilterValue);
        const { examinationCenters } = institute;
        const instituteDropdown = { name: institute.name, uuid: institute.uuid };
        const instituteAndCenters = [
          instituteDropdown,
          ...examinationCenters
        ];
        if (this.instituteFilterValue && !this.zentrumFilterValue) return instituteAndCenters.some((x) => x.name === value);
        return value === this.zentrumFilterValue;
      }
      if (!this.zentrumFilterValue) return true;
      return value === this.zentrumFilterValue;
    },

    findInstitute(uuid) {
      return this.instituteData.find((institute) => institute.uuid === uuid);
    },

    onDateFilterChange() {
      if (this.searchOrderNumber && matchOrderNumberPattern(this.searchOrderNumber)) { // ignore other filters if searchOrderNumber is defined and valid
        return;
      }
      let uuid = this.eiUuid;
      if (this.eiUuid === '' && this.instituteFilterValue !== '') uuid = this.instituteFilterValue;
      this.getExams(uuid, this.queryStartDate, this.queryEndDate);
    },

    deleteExam(item) {
      this.pruefungsverwaltung.deleteExam(this.eiUuid, item.uuid).then(() => {
        this.addNotice({
          message: 'Deleted'
        });
        this.getExams();
      });
    },

    async getExams(uuid = this.eiUuid, startDate = this.queryStartDate, endDate = false) {
      const instituteCount = this.instituteData?.length || 1;
      if (instituteCount === 1) {
        const { data: { exams } } = await this.pruefungsverwaltung.getExams(uuid, startDate, endDate);

        this.exams = exams;
        await this.getCourses();
        this.tableData(this.ecUuid === '' ? this.exams : this.getExamsByExaminationCenter(this.ecUuid));
      } else {
        this.instituteData.forEach((institute) => {
          this.exams = []; // NB! Question here
          this.pruefungsverwaltung.getExams(institute.uuid, startDate, endDate).then(({ data: { exams } }) => {
            this.exams = [...this.exams, ...exams];
            this.getCourses();
            this.tableData(this.exams);
          });
        });
      }
    },

    exportExamsList() {
      const filteredItems = {
        examDateFrom: this.queryStartDate,
        examDateTo: this.queryEndDate || '',
        status: this.statusFilterValue || '',
        extRef: this.extRef || '',
        examOrderNumber: this.searchOrderNumber || '',
        examSubjectId: this.fachFilterValue || this.idFilterValue || '',
        examinationCenterUuid: this.ecUuid || '',
        examinationInstituteId: this.eiUuid || '',
        telcConnectPublished: this.telcConnectFilterValue || ''
      };
      this.pruefungsverwaltung.getExamsExportList(this.eiUuid, filteredItems).then((res) => {
        if (res.status === 404) {
          this.addNotice({
            message: this.$t('page.list.filters.exportExamsList.noData')
          });
        } else {
          // Get the actual date
          const dateObj = new Date();
          const day = dateObj.getUTCDate();
          const month = dateObj.getUTCMonth() + 1;
          const year = dateObj.getUTCFullYear();
          const actualDate = `${day}-${month}-${year}`;

          fileDownload(res.data, `pruefungsliste-${actualDate}.xls`);
        }
      });
    },

    async getCourses() {
      const response = await this.pruefungsfachverwaltung.getCourses();

      this.courses = response.data.examSubjects;
      this.fachItems = this.extractFachItems(this.courses);
      this.fachIdList = this.extractFachIds(this.courses);
    },

    apiDateFormat(date, fallback = true) {
      if (!date && fallback) return moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      if (moment(date).format() === 'Invalid date') return false;
      return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    },

    updateHero(examCenter = {}) {
      if (examCenter) {
        this.$refs.hero.addMetaItem({
          position: 1,
          identifier: 'examinationCenter',
          title: this.$t('page.create.fields.basicData.examCenter.label'),
          value: examCenter.name
        });
      }
    },

    getExaminationCenter(examinationCenterUuid) {
      if (examinationCenterUuid === this.eiUuid) return this.instituteData;

      // TODO: instituteData can be a list here, so examinationCenters does not exist. This happens for our test data, not sure if it is possible in production.
      return (this.instituteData.examinationCenters || []).find((obj) => obj.uuid === examinationCenterUuid);
    },

    getExamByUuid(uuid) {
      return this.exams.find((obj) => obj.uuid === uuid);
    },

    getContactsFromExaminationCenter(examinationCenterUuid) {
      const examCenter = this.getExaminationCenter(examinationCenterUuid);
      if (examCenter !== undefined && examCenter.contacts !== undefined) return examCenter.contacts;
      return null;
    },

    getExamsByExaminationCenter(examinationCenterUuid) {
      const result = this.exams.filter((obj) => obj.examinationCenter.uuid === examinationCenterUuid);
      return result;
    },

    isRegistrationSubject(item) {
      const exam = this.getExamByUuid(item.uuid);
      const examCenter = this.getExaminationCenter(exam.examinationCenter.uuid);
      const id = parseInt(item.id, 10);
      return examCenter.registrationSubjects.find((s) => s.id === id);
    },

    queryParams(instituteUuid) {
      return !this.eiUuid ? { institute: instituteUuid } : {};
    },

    updateTelcConnect(exam) {
      this.debug(exam);
      this.pruefungsverwaltung.updateExam(exam.examinationInstitute.uuid, exam.uuid, exam).then((response) => {
        this.debug(response);
        if (response.status === 200) {
          this.setExamPublishedStatus(exam.uuid, exam.telcConnect);
          this.addNotice({
            message: this.$t('page.create.messages.success'),
            type: 'success'
          });
        }
      });
    },

    setExamPublishedStatus(uuid, telcConnect) {
      const exam = this.exams.find((obj) => obj.uuid === uuid);
      exam.telcConnect = telcConnect;
      this.tableData(this.exams);
    },

    isPublished(exam) {
      return exam.telcConnect?.published ? '$vuetify.icons.success' : '$vuetify.icons.clear';
    },

    isPublishedLabel(exam) {
      return exam.telcConnect?.published ? 'published' : 'notPublished';
    }
  }
};
</script>

<style lang="scss">
.table-width {
  max-width: 100%;

  @media (min-width: 600px) {
    max-width: 600px;
  }

  @media (min-width: 960px) {
    max-width: 960px;
  }

  @media (min-width: 1264px) {
    max-width: 1800px
  }

  @media (min-width: 1904px) {
    max-width: 1800px
  }
}
</style>
