import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import de from 'vuetify/src/locale/de.ts';
import en from 'vuetify/src/locale/en.ts';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faSearch as farSearch,
  faAddressCard as farAddressCard,
  faCertificate as farCertificate,
  faEnvelope as farEnvelope,
  faPhone as farPhone,
  faFileExcel as farFileExcel,
  faChevronUp as farChevronUp,
  faTimes as farTimes,
  faCalendarCheck as farCalendarCheck,
  faCalendarTimes as farCalendarTimes,
  faCalendarAlt as farCalendarAlt,
  faCalendarPlus as farCalendarPlus,
  faCalendar as farCalendar,
  faClock as farClock,
  faExternalLinkAlt as farExternalLinkAlt,
  faPlus as farPlus,
  faTimesCircle as farTimesCircle,
  faTrash as farTrash,
  faCheckCircle as farCheckCircle,
  faInfoCircle as farInfoCircle,
  faExclamation as farExclamation,
  faExclamationTriangle as farExclamationTriangle,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faCheckSquare as farCheckSquare,
  faSquare as farSquare,
  faMinusSquare as farMinusSquare,
  faCircle as farCircle,
  faSortUp as farSortUp,
  faChevronDown as farChevronDown,
  faBars as farBars,
  faDotCircle as farDotCircle,
  faPen as farPen,
  faSync as farSync,
  faStepBackward as farStepBackward,
  faStepForward as farStepForward,
  faArrowsAltV as farArrowsAltV,
  faPaperclip as farPaperclip,
  faMinus as farMinus,
  faFileSpreadsheet as farFileSpreadsheet,
  faAlarmClock as farAlarmClock,
  faCheck as farCheck
} from '@fortawesome/pro-regular-svg-icons';
import {
  faSave as fasSave,
  faPlus as fasPlus,
  faPaperPlane as fasPaperPlane,
  faPrint as fasPrint,
  faTrash as fasTrash,
  faUserFriends as fasUserFriends,
  faUserPlus as fasUserPlus,
  faEye as fasEye,
  faUndo as fasUndo,
  faBan as fasBan,
  faComments as fasComments,
  faPen as fasPen,
  faTimes as fasTimes,
  faLink as fasLink,
  faFileDownload as fasFileDownload,
  faFileImport as fasFileImport
} from '@fortawesome/free-solid-svg-icons';
import { getCurrentLocale } from '@/utils/general';

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

library.add(
  farSearch,
  farAddressCard,
  farCertificate,
  farEnvelope,
  farPhone,
  farFileExcel,
  farChevronUp,
  farTimes,
  farCalendarCheck,
  farCalendarTimes,
  farCalendarAlt,
  farCalendarPlus,
  farCalendar,
  farClock,
  farExternalLinkAlt,
  fasLink,
  farPlus,
  farTimesCircle,
  farTimes,
  fasFileImport,
  farTrash,
  farTimesCircle,
  farCheckCircle,
  farInfoCircle,
  farExclamation,
  farExclamationTriangle,
  farChevronLeft,
  farChevronRight,
  farCheckSquare,
  farSquare,
  farMinusSquare,
  farCircle,
  farSortUp,
  farChevronDown,
  farBars,
  farChevronDown,
  farDotCircle,
  farCircle,
  farPen,
  farSync,
  farStepBackward,
  farStepForward,
  farArrowsAltV,
  farPaperclip,
  farMinus,
  fasSave,
  fasPlus,
  fasPaperPlane,
  fasPrint,
  fasTrash,
  fasUserFriends,
  fasUserPlus,
  fasEye,
  fasUndo,
  fasBan,
  fasComments,
  fasPen,
  fasTimes,
  farFileSpreadsheet,
  farAlarmClock,
  fasFileDownload,
  farCheck
);

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { de, en },
    current: getCurrentLocale()
  },
  icons: {
    iconfont: 'fa',
    values: {
      search: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'search']
        }
      },
      addressCard: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'address-card']
        }
      },
      certificate: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'certificate']
        }
      },
      envelope: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'envelope']
        }
      },
      phone: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'phone']
        }
      },
      fileExcel: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'file-excel']
        }
      },
      fileDownload: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'file-download']
        }
      },
      fileDocket: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'file-spreadsheet']
        }
      },
      scrollToTop: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'chevron-up']
        }
      },
      importAction: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'file-import']
        }
      },
      reset: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'times']
        }
      },
      inboxDate: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'calendar-plus']
        }
      },
      startDate: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'calendar-check']
        }
      },
      endDate: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'calendar-times']
        }
      },
      calendar: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'calendar-alt']
        }
      },
      incompleteReturn: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'calendar']
        }
      },
      completeReturn: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'calendar']
        }
      },
      clock: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'clock']
        }
      },
      externalLink: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'external-link-alt']
        }
      },
      plus: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'plus']
        }
      },

      // Actions
      saveAction: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'save']
        }
      },
      addAction: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'plus']
        }
      },
      transmitAction: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'paper-plane']
        }
      },
      printAction: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'print']
        }
      },
      editAction: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'pen']
        }
      },
      deleteAction: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'trash']
        }
      },
      editParticipantsAction: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'user-friends']
        }
      },
      lateRegistrationAction: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'user-plus']
        }
      },
      lateRegistrationInfoAction: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'alarm-clock']
        }
      },
      viewAction: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'eye']
        }
      },
      returnFlowAction: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'undo']
        }
      },
      abortAction: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'ban']
        }
      },
      telcConnectAction: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'link']
        }
      },

      // Groups
      oralGroup: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'comments']
        }
      },
      writingGroup: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'pen']
        }
      },

      // Vuetify icon overrides
      complete: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'check']
        }
      },
      cancel: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'times-circle']
        }
      },
      close: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'times']
        }
      },
      delete: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'trash']
        }
      },
      clear: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'times-circle']
        }
      },
      success: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'check-circle']
        }
      },
      info: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'info-circle']
        }
      },
      warning: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'exclamation']
        }
      },
      error: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'exclamation-triangle']
        }
      },
      prev: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'chevron-left']
        }
      },
      next: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'chevron-right']
        }
      },
      checkboxOn: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'check-square']
        }
      },
      checkboxOff: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'square']
        }
      },
      checkboxIndeterminate: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'minus-square']
        }
      },
      delimiter: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'circle']
        }
      },
      sort: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'sort-up']
        }
      },
      expand: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'chevron-down']
        }
      },
      menu: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'bars']
        }
      },
      subgroup: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'chevron-down']
        }
      },
      dropdown: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'chevron-down']
        }
      },
      radioOn: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'dot-circle']
        }
      },
      radioOff: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'circle']
        }
      },
      edit: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'pen']
        }
      },
      loading: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'sync']
        }
      },
      first: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'step-backward']
        }
      },
      last: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'step-forward']
        }
      },
      unfold: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'arrows-alt-v']
        }
      },
      file: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'paperclip']
        }
      },
      minus: {
        component: FontAwesomeIcon,
        props: {
          icon: ['far', 'minus']
        }
      }
    }
  },
  theme: {
    options: {
      variations: false
    },
    themes: {
      light: {
        primary: '#a81411',
        success: '#58b51f',
        error: '#d43f4f'
      }
    }
  }
});
