<script>
export default {
  data() {
    return {
    /**
     * Default prefix for inactive exam centers.
     * Override in components to customize.
     */
      inactivePrefix: this.$t('institutes.inactive')
    };
  },

  methods: {
    maybeInactiveName(instituteOrExamCenter, inactivePrefix = this.inactivePrefix) {
      if (instituteOrExamCenter.inactive) {
        return `[${inactivePrefix}] ${instituteOrExamCenter.name}`;
      }
      return instituteOrExamCenter.name;
    },

    maybeInactiveSelectOption(instituteOrExamCenter, primaryAddress, inactivePrefix = this.inactivePrefix) {
      if (!primaryAddress) {
        primaryAddress = instituteOrExamCenter.addresses?.find((address) => address.isPrimary && address.isActive);
      }

      const addressString = primaryAddress ? `, ${primaryAddress.zipCode} ${primaryAddress.city}` : '';

      if (instituteOrExamCenter.inactive) {
        return `[${inactivePrefix}] ${instituteOrExamCenter.extRef} - ${instituteOrExamCenter.name}${addressString}`;
      }
      return `${instituteOrExamCenter.extRef} - ${instituteOrExamCenter.name}${addressString}`;
    }
  }
};
</script>
