<template>
  <v-micro-frontend :micro-frontend="onlineweltFrameUi" />
</template>

<script>
import { getRemoteURL } from '@/utils/general';

export default {
  data: () => ({
    onlineweltFrameUi: {
      name: 'onlinewelt-frame-ui',
      url: `${getRemoteURL()}frame/onlinewelt-frame-ui.umd.js`
    }
  })
};
</script>
