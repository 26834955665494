<template>
  <div>
    <v-container>
      <pv-hero
        ref="hero"
        :quick-navigation="ui.quickNavigation"
        :title="$t('page.return.title')"
      >
        <template #exam="{ item }">
          <span
            class="c-hero__metaItemValue"
          >
            <span
              v-if="item.examOrderNumber"
            >
              {{ item.examOrderNumber }}
            </span>
          </span>
        </template>
      </pv-hero>
    </v-container>
    <v-main>
      <v-container>
        <v-form ref="form">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-card
                class="mb-8"
                flat
              >
                <v-card-title>
                  <v-icon
                    color="white"
                    class="mr-3"
                  >
                    $vuetify.icons.success
                  </v-icon>
                  <span>{{ $t('page.return.status.title') }}</span>
                </v-card-title>
                <v-card-text class="bg-grey">
                  <v-row
                    justify-md="space-between"
                  >
                    <v-col
                      id="status-filter"
                      cols="12"
                      md="5"
                    >
                      <v-autocomplete
                        v-model="exam.status"
                        :label="$t('page.return.status.label')"
                        :items="statusItems"
                        item-text="text"
                        item-value="value"
                        autocomplete="off"
                        type="search"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <div
                        v-if="isTelcUser"
                      >
                        <v-textarea
                          v-model="internalNotes"
                          :label="$t('page.create.fields.internalNotes')"
                          :hint="$t('page.create.fields.internalNotesHint')"
                          name="input-7-1"
                          outlined
                          background-color="#fff"
                          clear-icon=""
                          persistent-hint
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-text class="bg-grey">
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <date-menu
                        v-model="inboxDate"
                        icon="$vuetify.icons.inboxDate"
                        :label="$t('page.return.dates.inbox')"
                        :tooltip="$t('page.return.dates.tooltip.inbox')"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <date-menu
                        v-model="startDate"
                        icon="$vuetify.icons.startDate"
                        :label="$t('page.return.dates.editStart')"
                        :tooltip="$t('page.return.dates.tooltip.editStart')"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <date-menu
                        v-model="incompleteReturnDate"
                        icon="$vuetify.icons.incompleteReturn"
                        :label="$t('page.return.dates.incompleteReturn')"
                        :tooltip="$t('page.return.dates.tooltip.incompleteReturn')"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <date-menu
                        v-model="completeReturnDate"
                        icon="$vuetify.icons.completeReturn"
                        :label="$t('page.return.dates.completeReturn')"
                        :tooltip="$t('page.return.dates.tooltip.completeReturn')"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <date-menu
                        v-model="endDate"
                        icon="$vuetify.icons.endDate"
                        :label="$t('page.return.dates.editEnd')"
                        :tooltip="$t('page.return.dates.tooltip.editEnd')"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-3">
                    <v-col
                      cols="12"
                      md="auto"
                    >
                      <v-chip
                        v-if="inboxDate && startDate"
                      >
                        {{ $t('page.return.dates.idle.label') }} {{ idleTime = idleTimeDays }} {{ idleTime === 1 ? $t('page.return.dates.idle.days.one') : $t('page.return.dates.idle.days.other') }}
                      </v-chip>
                    </v-col>
                    <v-col
                      cols="12"
                      md="auto"
                    >
                      <v-chip
                        v-if="endDate && startDate && inboxDate"
                      >
                        {{ $t('page.return.dates.processingTime.label') }} {{ totalProcessingTime = processingTime + idleTime }} {{ totalProcessingTime === 1 ? $t('page.return.dates.processingTime.days.one') : $t('page.return.dates.processingTime.days.other') }}
                      </v-chip>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="auto"
                      md="4"
                    >
                      <v-chip
                        v-if="endDate && startDate"
                      >
                        {{ $t('page.return.dates.processingTime.labelNet') }} {{ processingTime = processingTimeDays }} {{ processingTime === 1 ? $t('page.return.dates.processingTime.days.one') : $t('page.return.dates.processingTime.days.other') }}
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text
                  v-if="totalProcessingTime"
                  hidden
                  class="bg-grey"
                >
                  <span
                    class="subtitle-2 align-content-sm-center"
                  >
                    {{ $t('page.return.dates.weekend') }}
                  </span>
                  <v-row>
                    <v-col
                      cols="4"
                      md="auto"
                    >
                      <v-btn
                        color="primary"
                        icon
                        :disabled="idleTimeDays <= 0"
                        @click="weekendDays++"
                      >
                        <v-icon>
                          $vuetify.icons.plus
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="4"
                      md="auto"
                    >
                      <span class="subtitle-1">
                        {{ weekendDays }}
                      </span>
                    </v-col>
                    <v-col
                      cols="4"
                      md="auto"
                    >
                      <v-btn
                        color="primary"
                        icon
                        :disabled="weekendDays <= 0"
                        @click="weekendDays--"
                      >
                        <v-icon>
                          $vuetify.icons.minus
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-spacer />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    @click="showProcessingInformations"
                  >
                    {{ $t('page.return.dates.export') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-expansion-panels
                v-model="openedPanels"
                flat
                multiple
              >
                <!-- Groups Panel -->
                <v-expansion-panel
                  id="examiner-and-rater-panel"
                  flat
                >
                  <v-expansion-panel-header>
                    <h3>{{ $t('page.return.examinersAndEvaluators.title') }}</h3>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <!-- Examiners -->
                    <v-card id="examiners">
                      <v-card-title>
                        {{ $t('page.return.examinersAndEvaluators.examiners.subHeadline') }}
                      </v-card-title>
                      <v-card-text>
                        <!-- Examiners (legacy) -->
                        <v-card
                          v-if="showLegacyExaminers"
                          id="legacy-examiners"
                          class="mb-4"
                          outlined
                        >
                          <v-card-title>
                            {{ $t('page.return.examinersAndEvaluators.examiners.legacy.subHeadline') }}
                          </v-card-title>
                          <v-card-text>
                            <p>
                              {{ $t('page.return.examinersAndEvaluators.examiners.legacy.warning') }}
                            </p>
                            <v-row>
                              <v-col
                                v-for="e in examiners"
                                :key="e.id"
                                class="shrink"
                              >
                                <v-responsive
                                  :width="`${Math.max(e.id.length, 3)}em`"
                                >
                                  <v-text-field
                                    draggable="true"
                                    class="text-field-center"
                                    :value="e.id"
                                    dense
                                    outlined
                                    readonly
                                    hide-details
                                    @dragstart="storeValue"
                                  />
                                </v-responsive>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <!-- Examiner committees -->
                        <examiner-committee
                          v-for="(ec, idx) in examinerCommittees"
                          :key="ec.committeeId"
                          v-model="examinerCommittees[idx]"
                          :index="idx"
                          :validation-errors="examinerCommitteeValidationErrors[ec.committeeId] || []"
                          :validated="examinerCommitteeValidationState !== 'dirty'"
                          @delete="deleteExaminerCommittee(idx)"
                          @show-licences="fetchAndShowLicences"
                        />
                        <v-btn
                          v-if="!examinerCommitteesLoaded && !examinerCommitteesCreated"
                          id="default-examiner-committees-btn"
                          color="primary"
                          depressed
                          @click="createDefaultExaminerCommittees()"
                        >
                          {{ $t('page.return.examinersAndEvaluators.examinerCommittees.createDefault') }}
                          <v-icon
                            right
                            x-small
                          >
                            $vuetify.icons.addAction
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-else
                          id="add-examiner-committee-btn"
                          color="primary"
                          depressed
                          @click="addExaminerCommittee()"
                        >
                          {{ $t('page.return.examinersAndEvaluators.examinerCommittees.add') }}
                          <v-icon
                            right
                            x-small
                          >
                            $vuetify.icons.addAction
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="examinerCommittees && examinerCommittees.length > 0"
                          id="validate-examiner-committees-btn"
                          class="ml-3"
                          :color="validateExaminerCommitteesButtonColor"
                          :outlined="validateExaminerCommitteesButtonOutlined"
                          :loading="isValidatingExaminers"
                          depressed
                          @click="validateExaminerCommittees"
                        >
                          {{ $t('page.return.examinersAndEvaluators.examinerCommittees.validate') }}
                        </v-btn>
                      </v-card-text>
                    </v-card>
                    <!-- Evaluators -->
                    <v-card id="evaluators">
                      <v-card-title>
                        {{ $t('page.return.examinersAndEvaluators.evaluators.subHeadline') }}
                      </v-card-title>
                      <v-card-text>
                        <v-card
                          v-for="(evaluator, index) in evaluators"
                          :key="index"
                          outlined
                          class="mb-4"
                        >
                          <v-card-title>
                            <v-icon
                              color="white"
                              class="mr-3"
                              small
                            >
                              $vuetify.icons.oralGroup
                            </v-icon>
                            <span>{{ $t('page.return.examinersAndEvaluators.evaluators.subHeadline') }} {{ index + 1 }}</span>
                            <template>
                              <!-- TODO: evaluate why this template has no directive -->
                              <v-spacer />
                              <v-btn
                                depressed
                                light
                                fab
                                x-small
                                @click="removeEvaluator(index)"
                              >
                                <v-icon x-small>
                                  $vuetify.icons.delete
                                </v-icon>
                              </v-btn>
                            </template>
                          </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col
                                cols="12"
                                md="6"
                              >
                                <v-text-field
                                  v-model="evaluator.id"
                                  dense
                                  type="number"
                                  counter="7"
                                  :success="evaluator.validationResult.status === 'OK'"
                                  :label="$t('page.return.examinersAndEvaluators.evaluators.id')"
                                  :error-messages="evaluator.validationResult.messages"
                                  error-count="3"
                                  @input="evaluator.validationResult = {}"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                md="6"
                              >
                                <v-btn
                                  class="mb-8 ml-3"
                                  small
                                  dark
                                  depressed
                                  @click="fetchAndShowLicences(evaluator.id)"
                                >
                                  {{ $t('page.return.examinersAndEvaluators.evaluators.showLicence') }}
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <v-btn
                          id="add-evaluator-btn"
                          color="primary"
                          depressed
                          @click="addEvaluator()"
                        >
                          {{ $t('page.return.examinersAndEvaluators.evaluators.add') }}
                          <v-icon
                            right
                            x-small
                          >
                            $vuetify.icons.addAction
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="evaluators.length"
                          id="validate-evaluators-btn"
                          class="ml-3"
                          color="success"
                          :loading="isValidatingEvaluators"
                          depressed
                          @click="() => {
                            isValidatingEvaluators = true;
                            validateEvaluators().then(() => isValidatingEvaluators = false);
                          }"
                        >
                          {{ $t('page.return.examinersAndEvaluators.evaluators.validate') }}
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-form>
        <pv-actionbar
          :secondary-actions="ui.secondaryActions"
          :primary-actions="ui.primaryActions"
          @cancel="onCancel"
          @save="onSave"
        />
      </v-container>
    </v-main>
    <!-- Add examinerLicences dialog -->
    <tuq-examiner-licences-dialog
      :licences="examinerLicences"
    />
    <!-- Add returnInfo dialog -->
    <tuq-return-info-dialog
      :infos="returnInfos"
    />
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';
import { timePickerToCivilTime, datePickerToCivilDate, civilTimeToTimePicker } from '@/utils/dateutils';

import pvActionbar from '@/components/Actionbar';
import pvHero from '@/components/Hero';
import dateMenu from '@/components/DateMenu.vue';

import tuqExaminerLicencesDialog from '@/components/dialogs/ExaminerLicences';
import tuqReturnInfoDialog from '@/components/dialogs/ReturnInfo';
import examinerCommittee from '@/components/ExaminerCommittee.vue';

const EXAMINER_TYPE = {
  Examiner: 'examiner',
  Evaluator: 'evaluator'
};

export default {
  name: 'ReturnFlow',

  components: {
    pvActionbar,
    pvHero,
    tuqExaminerLicencesDialog,
    tuqReturnInfoDialog,
    dateMenu,
    examinerCommittee
  },

  inject: [
    'lizenzverwaltung',
    'pruefungsverwaltung',
    'teilnehmerverwaltung'
  ],

  data: () => ({
    exam: { status: '' },
    examiners: [], // legacy list from ptv-teilnehmerverwaltung
    evaluators: [],
    examinerLicences: [],
    returnInfos: [],
    examinerCommittees: [],
    examinerCommitteeValidationErrors: {},
    examinerCommitteeValidationState: 'dirty',
    examinerCommitteesLoaded: false,
    examinerCommitteesCreated: false,

    openedPanels: [0], // only one (first) panel is present
    examHasAttendees: false,
    isValidatingExaminers: false,
    isValidatingEvaluators: false,
    isSaving: false,

    internalNotes: '',
    inboxDate: '',
    startDate: '',
    endDate: '',
    incompleteReturnDate: '',
    completeReturnDate: '',

    idleTime: 0,
    processingTime: 0,
    totalProcessingTime: 0,
    weekendDays: 0 // currently not used but could be used to calculate the weekend days for the idle time
  }),

  computed: {
    /**
     * The examination institute uuid passed as query or of the currently logged in user.
     *
     * @returns {string}
     */
    eiUuid() {
      return this.$route.query.institute || localStorage.getItem('eiUuid');
    },

    /**
     * The configuration of the user interface.
     * Used by components in this view.
     *
     * @returns {Object}
     */
    ui() {
      return {
        secondaryActions: [
          {
            title: this.$t('page.return.cancel'),
            color: 'white',
            outlined: true,
            event: 'cancel'
          }
        ],
        primaryActions: [
          {
            title: this.$t('page.return.save'),
            icon: '$vuetify.icons.saveAction',
            color: 'primary',
            event: 'save',
            loading: this.isSaving
          }
        ],
        quickNavigation: [ // set the quick navigation actions
          {
            tooltipText: this.$t('metaItems.quickNavigation.edit'),
            icon: '$vuetify.icons.action.editAction',
            hidden: !this.isTelcUser,
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            action: () => this.$router.push({ name: 'edit', params: { id: this.exam.uuid }, query: { institute: this.exam.examinationInstitute.uuid } }),
            outlined: false,
            iconColor: 'primary'
          },
          {
            tooltipText: this.$t('metaItems.quickNavigation.editParticipants'),
            icon: '$vuetify.icons.action.editParticipantsAction',
            hidden: !this.isTelcUser,
            // eslint-disable-next-line no-restricted-globals
            action: () => location.replace(`/ptv/teilnehmerverwaltung/institut/${this.exam.examinationInstitute.uuid}/pruefung/${this.exam.uuid}`),
            outlined: false,
            iconColor: 'primary'
          }
        ]
      };
    },

    /**
     * List of available status types.
     *
     * @returns {Object[]}
     */
    statusItems() {
      return [
        { text: this.$t('page.list.states.open.text'), value: 'open' },
        { text: this.$t('page.list.states.inSubmission.text'), value: 'in_submission' },
        { text: this.$t('page.list.states.submitted.text'), value: 'submitted' },
        { text: this.$t('page.list.states.submissionFailed.text'), value: 'submission_failed' },
        { text: this.$t('page.list.states.terminated.text'), value: 'terminated' },
        { text: this.$t('page.list.states.fulfilmentBlocked.text'), value: 'fulfilment_blocked' },
        { text: this.$t('page.list.states.approved.text'), value: 'approved' },
        { text: this.$t('page.list.states.examConducted.text'), value: 'exam_conducted' },
        { text: this.$t('page.list.states.return_incomplete.text'), value: 'return_incomplete' },
        { text: this.$t('page.list.states.return_completed.text'), value: 'return_completed' },
        { text: this.$t('page.list.states.in_assessment.text'), value: 'in_assessment' },
        { text: this.$t('page.list.states.in_evaluation.text'), value: 'in_evaluation' },
        { text: this.$t('page.list.states.preliminary_evaluation.text'), value: 'preliminary_evaluation' },
        { text: this.$t('page.list.states.evaluation_for_exam.text'), value: 'evaluation_for_exam' },
        { text: this.$t('page.list.states.evaluated.text'), value: 'evaluated' },
        { text: this.$t('page.list.states.certificate_in_dispatch.text'), value: 'certificate_in_dispatch' },
        { text: this.$t('page.list.states.exam_failed.text'), value: 'exam_failed' }
      ];
    },

    /**
     * @returns {string}
     */
    returnId() {
      return this.$route.params.returnId;
    },

    /*
     * Returns the formatted version of the inboxDate.
     */
    formattedInboxDate() {
      return this.formatDate(this.inboxDate) || '';
    },

    /*
     * Returns the formatted version of the startDate.
     */
    formattedStartDate() {
      return this.formatDate(this.startDate) || '';
    },

    /*
     * Returns the formatted version of the endDate.
     */
    formattedEndDate() {
      return this.formatDate(this.endDate) || '';
    },

    /*
     * Returns inboxDate ISO string.
     */
    computedInboxDate() {
      if (!this.inboxDate) return '';
      return moment(this.inboxDate).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    },

    /*
     * Returns startDate ISO string.
     */
    computedStartDate() {
      if (!this.startDate) return '';
      return moment(this.startDate).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    },

    /*
     * Returns endDate ISO string.
     */
    computedEndDate() {
      if (!this.endDate) return '';
      return moment(this.endDate).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    },

    computedExamStatus() {
      return this.exam.status;
    },

    /**
     * returns the idleTime in days
     * idleTime is the difference between the startDate and the inboxDate
     * withouth saturdays and sundays
     *
     * @returns {number}
     */
    idleTimeDays() {
      if (!this.startDate || !this.inboxDate) return 0;
      return moment(this.startDate).diff(moment(this.inboxDate), 'days') - this.weekendDays;
    },

    /**
     * returns the processingTime in days
     * ProcessingTime is the difference between the endDate and the startDate
     *
     * @returns {number}
     */
    processingTimeDays() {
      if (!this.startDate || !this.endDate) return 0;
      return moment(this.endDate).diff(moment(this.startDate), 'days');
    },

    /**
     * returns the items for the info dialog
     *
     * @returns {Object[]}
     */
    returnInfoItems() {
      return [
        { text: this.$t('page.return.returnInfos.examOrderNumber'), value: this.exam.examOrderNumber },
        { text: this.$t('page.return.returnInfos.examSubject.id'), value: this.exam.examSubject.id },
        { text: this.$t('page.return.returnInfos.examDate'), value: this.formatDate(this.exam.examDate) },
        { text: this.$t('page.return.returnInfos.inboxDate'), value: this.formattedInboxDate ? this.formattedInboxDate : '' },
        { text: this.$t('page.return.returnInfos.startDate'), value: this.formattedStartDate ? this.formattedStartDate : '' },
        { text: this.$t('page.return.returnInfos.endDate'), value: this.formattedEndDate ? this.formattedEndDate : '' },
        { text: this.$t('page.return.returnInfos.idleTime'), value: this.idleTimeDays ? this.idleTimeDays : '' },
        { text: this.$t('page.return.returnInfos.processingTime'), value: this.processingTimeDays ? this.processingTimeDays : '' },
        { text: this.$t('page.return.returnInfos.totalProcessingTime'), value: this.totalProcessingTime ? this.totalProcessingTime : '' }
      ];
    },

    validateExaminerCommitteesButtonColor() {
      if (this.examinerCommitteeValidationState === 'success') {
        return 'success';
      }
      if (this.examinerCommitteeValidationState === 'error') {
        return 'error';
      }
      return '';
    },

    validateExaminerCommitteesButtonOutlined() {
      return this.examinerCommitteeValidationState === 'dirty';
    },

    showLegacyExaminers() {
      return this.examiners?.length > 0 && !this.examinerCommitteesLoaded;
    },

    examinerCommitteesInBackendFormat: {
      get() {
        return this.examinerCommittees.map((ec) => ({
          committeeId: ec.committeeId,
          date: ec.date ? datePickerToCivilDate(ec.date) : null,
          time: ec.time ? timePickerToCivilTime(ec.time) : null,
          groupSize: ec.groupSize,
          examinerTuqRefs: ec.examinerTuqRefs
        }));
      },
      set(committees) {
        this.examinerCommittees = committees.map((ec) => ({
          committeeId: ec.committeeId,
          date: ec.date || null,
          time: ec.time ? civilTimeToTimePicker(ec.time) : null,
          groupSize: ec.groupSize,
          examinerTuqRefs: ec.examinerTuqRefs
        }));
      }
    }
  },

  watch: {
    /**
     * watches the exam status and updates the endDate
     * if the exam status is 'certificate_in_dispatch'
     */
    computedExamStatus() {
      if (this.exam.status === 'certificate_in_dispatch') {
        this.endDate = moment().toISOString();
      }
    },
    // it hurts a bit, but this is the easiest solution by far
    examinerCommittees: {
      handler() {
        this.examinerCommitteeValidationState = 'dirty';
      },
      deep: true
    }
  },

  async created() {
    const [exam, allExaminers] = await Promise.all([
      this.pullExam(this.eiUuid, this.returnId),
      this.pullExaminers(this.eiUuid, this.returnId)
    ]);

    if (exam.examCommittees?.length > 0) {
      this.examinerCommitteesInBackendFormat = exam.examCommittees;
      this.examinerCommitteesLoaded = true;
    }

    this.updateHero(exam);

    // if the return flow has already been started and processed,
    // we need to update the return processing informations with the latest data from the exam
    if (this.hasReturnInfo(exam)) {
      const { returnInfo } = exam;

      if (returnInfo?.inbox?.length) {
        this.inboxDate = returnInfo.inbox;
      }
      if (returnInfo?.startProcessing?.length) {
        this.startDate = returnInfo.startProcessing;
      }
      if (returnInfo?.incompleteReturn?.length) {
        this.incompleteReturnDate = returnInfo.incompleteReturn;
      }
      if (returnInfo?.completeReturn?.length) {
        this.completeReturnDate = returnInfo.completeReturn;
      }
      if (returnInfo?.endProcessing?.length) {
        this.endDate = returnInfo.endProcessing;
      } else if (this.exam.status === 'certificate_in_dispatch') {
        this.endDate = moment(this.exam.updated).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
      }
      if (returnInfo?.internalNotes?.length) {
        this.internalNotes = returnInfo.internalNotes;
      }

      // if the exam is in the certificate_in_dispatch status, we need to set the endDate to the date the status was updated
    } else if (this.exam.status === 'certificate_in_dispatch') {
      this.endDate = moment(this.exam.updated).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    }

    if (allExaminers) {
      const { examiners, evaluators } = this.splitExaminersByType(allExaminers);

      this.examiners = examiners;
      this.evaluators = evaluators;

      // Validate already added evaluators on page load
      this.isValidatingEvaluators = true;
      await Promise.all([
        this.validateEvaluators(),
        this.examinerCommitteesLoaded ? this.validateExaminerCommittees() : Promise.resolve()
      ]);
      this.isValidatingEvaluators = false;
    }
  },

  methods: {
    ...mapMutations('notice', {
      addNotice: 'add'
    }),

    ...mapActions('dialogs', {
      showExaminerLicencesDialog: 'SHOW_EXAMINER_LICENCES',
      showReturnInfoDialog: 'SHOW_RETURN_INFO'
    }),

    async validateEvaluators() {
      const tuqrefs = this.evaluators.map((evaluator) => (evaluator.id));

      if (!tuqrefs.length) return;

      const { data } = await this.pruefungsverwaltung.validateEvaluators(this.exam.examinationInstitute.uuid, this.exam.uuid, tuqrefs);
      this.evaluators.forEach((evaluator) => {
        const errorData = data.find((d) => d.tuqRef === Number(evaluator.id));

        if (errorData) {
          // Get the validation messages
          const messages = this.getValidationMessages(errorData.errors);

          // Add the validation result to the evaluators
          evaluator.validationResult.messages = messages;
          evaluator.validationResult.status = '';
        } else {
          evaluator.validationResult.messages = [];
          evaluator.validationResult.status = 'OK';
        }
      });
    },

    getValidationMessages(validationErrors) {
      return validationErrors.map((error) => {
        switch (error.type) {
          case 'InvalidEvaluatorTuqRef':
            return (this.$t('page.return.examinersAndEvaluators.evaluators.validationError.InvalidEvaluatorTuqRef'));
          case 'EvaluatorNotFound':
            return (this.$t('page.return.examinersAndEvaluators.evaluators.validationError.EvaluatorNotFound'));
          case 'DuplicateEvaluators':
            return (this.$t('page.return.examinersAndEvaluators.evaluators.validationError.DuplicateEvaluators'));
          default:
            return (this.$t('page.return.examinersAndEvaluators.examinerCommittees.validationError.UnknownError', { errorType: error.type }));
        }
      });
    },

    /**
     * Pull an exam from the remote.
     *
     * @param {string} examinationInstituteUuid
     * @param {string} returnId
     * @returns {Promise}
     */
    async pullExam(examinationInstituteUuid, returnId) {
      return this.pruefungsverwaltung.getExam(examinationInstituteUuid, returnId).then(({ data }) => {
        if (data?.exam) this.exam = data.exam;
        return data?.exam;
      });
    },

    /**
     * Pull examiners from the remote.
     *
     * @param {string} examinationInstituteUuid
     * @param {string} examId
     * @returns {Promise}
     */
    async pullExaminers(examinationInstituteUuid, examId) {
      return this.teilnehmerverwaltung.getExaminer(examinationInstituteUuid, examId).then(({ data }) => data?.examiners);
    },

    /**
     * Update an exam.
     *
     * @param {string} examinationInstituteUuid
     * @param {string} examId
     * @param {Object} data
     * @returns {Promise}
     */
    updateExam(examinationInstituteUuid, examId, data) {
      return this.pruefungsverwaltung.updateExam(examinationInstituteUuid, examId, data);
    },

    /**
     * Set the examiners.
     * Overrides all existing ones.
     *
     * @param {string} examinationInstituteUuid
     * @param {string} examId
     * @param {Object} data
     * @returns {Promise}
     */
    setExaminers(examinationInstituteUuid, examId, data) {
      return this.teilnehmerverwaltung.setExaminer(examinationInstituteUuid, examId, data);
    },

    /**
     * Splits an array of examiners by type, either evaluator or examiner.
     *
     * @param {Object[]} examiners
     * @returns {Object}
     */
    splitExaminersByType(examiners) {
      return examiners?.reduce((acc, examiner) => {
        if (examiner.type === EXAMINER_TYPE.Examiner) {
          acc.examiners.push({ ...examiner, validationResult: {} });
        } else if (examiner.type === EXAMINER_TYPE.Evaluator) {
          acc.evaluators.push({ ...examiner, validationResult: {} });
        } else {
          throw new Error(`Detected examiner "${examiner.uuid}" with invalid type "${examiner.type}"`);
        }

        return acc;
      }, { examiners: [], evaluators: [] });
    },

    /**
     * Add an evaluator.
     *
     * @param {string} type
     * @returns {void}
     */
    addEvaluator() {
      this.evaluators.push({ type: 'evaluator', id: '', validationResult: {} });
    },

    /**
     * Remove an evaluator by index.
     *
     * @param {string} type
     * @param {number} index
     * @returns {void}
     */
    removeEvaluator(index) {
      this.evaluators.splice(index, 1);
    },

    /**
     * Get all active licences for examiner.
     *
     * @param {number} userId
     * @returns {void}
     */
    async fetchAndShowLicences(userId) {
      if (!userId) {
        return;
      }
      const result = await this.lizenzverwaltung.getUserLicences(userId);
      if (!(result?.data?.licences?.length > 0)) {
        this.addNotice({
          message: this.$t('page.return.examinersAndEvaluators.messages.noLicences'),
          type: 'error'
        });
        return;
      }
      this.examinerLicences = result.data?.licences;
      this.showExaminerLicencesDialog();
    },

    /**
     * add examOrderNumber to page Hero
     *
     * @param {string} examOrderNumber
     * @returns {void}
     */
    updateHero(exam = {}) {
      if (exam.examOrderNumber) {
        this.$refs.hero.addMetaItem({
          position: 1,
          identifier: 'auftragsnummer',
          title: this.$t('page.hero.orderNumber'),
          value: exam.examOrderNumber
        });
      }
    },

    /**
     * Set local storage
     *
     * @param {string} key
     * @param {string} value
     * @returns {void}
     */
    setLocalStorage(key, value) {
      localStorage.setItem(key, value);
    },

    /**
     * remove local storage
     *
     * @param {string} key
     * @returns {string}
     */
    removeLocalStorage(key) {
      localStorage.removeItem(key);
    },

    /**
     * Called on the 'cancel' event from the actionbar.
     *
     * @returns {void}
     */
    onCancel() {
      this.$router.push({ name: 'list' });
    },

    storeValue(dragEvent) {
      dragEvent.dataTransfer.setData('text', dragEvent.target.value);
    },

    hasReturnInfo(exam) {
      if (!exam) {
        return false;
      }
      return ![null, undefined].includes(exam.returnInfo);
    },

    async saveProcessingInformations() {
      const processedInformations = {
        inbox: moment(this.inboxDate).toISOString(),
        incompleteReturn: moment(this.incompleteReturnDate).toISOString(),
        completeReturn: moment(this.completeReturnDate).toISOString(),
        startProcessing: moment(this.startDate).toISOString(),
        endProcessing: moment(this.endDate).toISOString(),
        internalNotes: this.internalNotes
      };

      const response = await this.pruefungsverwaltung.returnInformation(this.exam.examinationInstitute.uuid, this.exam.uuid, processedInformations);
      this.exam.returnInfo = response.data.exam.returnInfo;
      await this.updateExam(this.eiUuid, this.exam.uuid, this.exam);
    },

    showProcessingInformations() {
      this.returnInfos = this.returnInfoItems;
      this.showReturnInfoDialog();
    },

    /**
     * Called on the 'save' event from the actionbar.
     *
     * @returns {void}
     */
    async onSave() {
      this.isSaving = true;
      try {
        await this.saveProcessingInformations();
        await this.setExaminers(this.eiUuid, this.exam.uuid, [...this.examiners, ...this.evaluators]);
        await this.saveExaminerCommittees();
        this.addNotice({
          message: this.$t('page.create.messages.success'),
          type: 'success'
        });
        this.$router.push({ name: 'list' });
      } finally {
        this.isSaving = false;
      }
    },

    newCommitteeId: uuidV4,

    addExaminerCommittee() {
      const newCommittee = {
        committeeId: this.newCommitteeId(),
        date: null,
        time: null,
        groupSize: null,
        examinerTuqRefs: [null]
      };
      this.examinerCommittees.push(newCommittee);
    },

    deleteExaminerCommittee(index) {
      this.examinerCommittees.splice(index, 1);
    },

    createDefaultExaminerCommittees() {
      this.examinerCommittees = this.exam.oralExamGroups?.map(({ groupSize, examDate }) => {
        const time = moment(examDate).format('HH:mm');
        const date = moment(examDate).format('YYYY-MM-DD');
        return {
          committeeId: this.newCommitteeId(),
          date,
          time,
          groupSize,
          examinerTuqRefs: [null]
        };
      });
      this.examinerCommitteesCreated = true;
    },

    async validateExaminerCommittees() {
      this.isValidatingExaminers = true;
      let errorsOccurred = false;
      const examinerCommitteeValidationErrors = {};
      try {
        const { data } = await this.pruefungsverwaltung.validateExaminerCommittees(this.exam.examinationInstitute.uuid, this.exam.uuid, this.examinerCommitteesInBackendFormat);
        data.forEach(({ committeeId, errors }) => {
          errorsOccurred = true;
          examinerCommitteeValidationErrors[committeeId] = errors;
        });
        this.examinerCommitteeValidationErrors = examinerCommitteeValidationErrors;
        this.examinerCommitteeValidationState = errorsOccurred
          ? 'error'
          : 'success';
        this.$forceUpdate();
      } finally {
        this.isValidatingExaminers = false;
      }
    },

    async saveExaminerCommittees() {
      return this.pruefungsverwaltung.setExaminerCommittees(this.exam.examinationInstitute.uuid, this.exam.uuid, this.examinerCommitteesInBackendFormat);
    }
  }
};
</script>

<style lang="scss">
.text-field-center input {
  text-align: center
}
</style>
