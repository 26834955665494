<!-- The form used to create a new exam or edit an existing one.
  Available via Pruefungsliste -> Neue Pruefung Anmelden
  or Pruefungsliste -> Pen icon (on an existing exam).

  This component only directly communicates with the pruefungsverwaltung
  and limitsetverwaltung (a sub-service of the pfv-pruefungsfachverwaltung)
  in order to create, load and update exams and load limitsets.

  Communication with the institutverwaltung, pruefungsfachverwaltung and
  lizenzverwaltung is completely delegated to the BasicData subcomponent
  which also handles the entire 'Basisdaten' section of the form, i.e.
  (oral and written) versions, exam center, contact person, exam subject
  and KTR-ID.
 -->

<template>
  <div>
    <v-form
      ref="form"
    >
      <v-row>
        <v-col
          cols="12"
          md="8"
        >
          <basic-data
            v-model="basicDataModel"
            :show-errors="showBasicDataErrors"
            :original-exam-subject-id="originalExamSubjectId"
          />
          <!-- Expansion Panels -->
          <v-row class="mb-2">
            <v-col>
              <div class="float-right">
                <v-btn
                  v-show="panel.length === items"
                  small
                  dark
                  depressed
                  @click="none"
                >
                  {{ $t('page.create.fields.showAllFieldsBtn.hide') }}
                </v-btn>
                <v-btn
                  v-show="panel.length !== items"
                  small
                  dark
                  depressed
                  @click="all"
                >
                  {{ $t('page.create.fields.showAllFieldsBtn.show') }}
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <div class="mb-8">
            <v-expansion-panels
              v-model="panel"
              multiple
              flat
            >
              <!-- Groups Panel -->
              <v-expansion-panel flat>
                <v-expansion-panel-header>
                  <h3>{{ $t('page.create.fields.attendeesAndDates.headline') }}</h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                  <v-row>
                    <v-col
                      cols="10"
                      md="6"
                    >
                      <v-text-field
                        id="totalNumberAttendee"
                        ref="totalNumberAttendee"
                        v-model.number="formFields.totalNumberAttendees"
                        :label="$t('page.create.fields.attendeesAndDates.individualAttendees')"
                        type="number"
                        dense
                        :rules="isTotalNumberAttendeesValid"
                        :disabled="isTotalNumberAttendeesReadonly"
                      />
                    </v-col>
                    <v-col
                      cols="2"
                      align-self="center"
                    >
                      <v-tooltip
                        top
                        max-width="400px"
                      >
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                          >
                            <v-icon
                              color="primary"
                            >
                              $vuetify.icons.info
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('page.create.fields.attendeesAndDates.individualAttendeesInfo') }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>

                  <v-divider class="my-5" />

                  <!-- Written Groups -->
                  <h4 class="mb-6">
                    {{ $t('page.create.fields.attendeesAndDates.writtenGroups.headline') }}
                  </h4>
                  <v-row>
                    <v-col
                      cols="6"
                      md="9"
                    >
                      <v-menu
                        v-model="formFields.dateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            :value="formatDate(writtenExamDateInput)"
                            :error-messages="dateValidationError"
                            :label="$t('page.create.fields.attendeesAndDates.writtenGroups.dateExam')"
                            prepend-icon="$vuetify.icons.calendar"
                            readonly
                            required
                            light
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="writtenExamDateInput"
                          color="primary"
                          first-day-of-week="1"
                          no-title
                          scrollable
                          :allowed-dates="allowedDatesWrittenExam"
                          @click:date="compareOralDate"
                          @input="formFields.dateMenu = false"
                          @change="onWrittenExamDateChanged"
                        />
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="6"
                      md="3"
                    >
                      <v-menu
                        v-model="formFields.timeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            :value="writtenExamTimeInput"
                            :error-messages="validTime"
                            :error="basicValidation"
                            :rules="isValidTime"
                            prepend-icon="$vuetify.icons.clock"
                            required
                            readonly
                            light
                            v-bind="attrs"
                            v-on="on"
                          >
                            <template slot="label">
                              {{ $t('page.create.fields.attendeesAndDates.time') }}
                            </template>
                          </v-text-field>
                        </template>
                        <v-time-picker
                          v-model="writtenExamTimeInput"
                          format="24hr"
                          @click:hour="onWrittenExamTimeChanged"
                          @change="onWrittenExamTimeChanged"
                          @click:minute="formFields.timeMenu = false"
                        />
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-card
                    v-for="(group,i) in formFields.writingExamGroups"
                    :key="i + 'written'"
                    outlined
                    class="mb-4"
                  >
                    <v-card-title>
                      <v-icon
                        color="white"
                        class="mr-3"
                        small
                      >
                        $vuetify.icons.writingGroup
                      </v-icon>
                      <span>{{ $t('page.create.fields.attendeesAndDates.writtenGroups.groupSubheadline')
                      }} {{ i + 1 }}</span>

                      <template v-if="i > 0">
                        <v-spacer />
                        <v-btn
                          depressed
                          light
                          fab
                          x-small
                          @click="removeGroup(formFields.writingExamGroups,i)"
                        >
                          <v-icon x-small>
                            $vuetify.icons.delete
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            :id="`writingGroupSize-${i}`"
                            v-model.number="group.groupSize"
                            dense
                            type="number"
                            :label="`${$t('page.create.fields.attendeesAndDates.groupSize')} ${i+1}`"
                            :rules="isWritingGroupSizeValid"
                            @wheel="$event.target.blur()"
                            @change="$refs.totalNumberAttendee.validate()"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-btn
                    id="addWrittenExamBtn"
                    class="mb-8"
                    small
                    dark
                    depressed
                    @click="addGroup(formFields.writingExamGroups)"
                  >
                    {{ $t('page.create.fields.attendeesAndDates.writtenGroups.addGroup') }}
                    <v-icon
                      right
                      x-small
                    >
                      $vuetify.icons.addAction
                    </v-icon>
                  </v-btn>

                  <v-row class="mb-5">
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        v-model="allWrittenParticipants"
                        :label="$t('page.create.fields.attendeesAndDates.writtenGroups.totalAttendees')"
                        dense
                        type="number"
                        hide-details="auto"
                        outlined
                        readonly
                      />
                    </v-col>
                    <v-col
                      cols="2"
                      align-self="center"
                    >
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                          >
                            <v-icon
                              color="primary"
                            >
                              $vuetify.icons.info
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('page.create.fields.attendeesAndDates.calculationInfo')
                        }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>

                  <v-divider class="my-5" />

                  <!-- Oral Groups -->
                  <h4 class="mb-6">
                    {{ $t('page.create.fields.attendeesAndDates.oralGroups.headline') }}
                  </h4>
                  <v-card
                    v-for="(group,i) in formFields.oralExamGroups"
                    :key="i + 'oral'"
                    outlined
                    class="mb-4"
                  >
                    <v-card-title>
                      <v-icon
                        color="white"
                        class="mr-3"
                        small
                      >
                        $vuetify.icons.oralGroup
                      </v-icon>
                      <span>{{ $t('page.create.fields.attendeesAndDates.oralGroups.groupSubheadline')
                      }} {{ i + 1 }}</span>
                      <template v-if="i > 0">
                        <v-spacer />
                        <v-btn
                          depressed
                          light
                          fab
                          x-small
                          @click="removeGroup(formFields.oralExamGroups,i, 'oral')"
                        >
                          <v-icon x-small>
                            $vuetify.icons.delete
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            :id="`oralGroupSize-${i}`"
                            v-model.number="group.groupSize"
                            dense
                            type="number"
                            :label="`${$t('page.create.fields.attendeesAndDates.groupSize')} ${i+1}`"
                            :rules="isOralGroupSizeValid"
                            @wheel="$event.target.blur()"
                            @change="$refs.totalNumberAttendee.validate()"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="6"
                          md="9"
                        >
                          <v-menu
                            v-model="group.groupMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template #activator="{ on, attrs }">
                              <v-text-field
                                :value="formatDate(oralExamInput[i].examDateInput)"
                                :error-messages="dateValidationError"
                                :label="$t('page.create.fields.attendeesAndDates.oralGroups.dateExam')"
                                prepend-icon="$vuetify.icons.calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              />
                            </template>

                            <v-date-picker
                              v-model="oralExamInput[i].examDateInput"
                              color="primary"
                              first-day-of-week="1"
                              no-title
                              scrollable
                              :allowed-dates="allowedDatesOralExam"
                              @input="group.groupMenu = false"
                              @change="onOralExamDateChanged(oralExamInput[i].examDateInput, i)"
                            />
                          </v-menu>
                        </v-col>
                        <v-col
                          cols="6"
                          md="3"
                        >
                          <v-menu
                            v-model="group.timeMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template #activator="{ on, attrs }">
                              <v-text-field
                                :value="oralExamInput[i].examTimeInput"
                                :error-messages="validTime"
                                :error="basicValidation"
                                :rules="isValidTime"
                                :label="$t('page.create.fields.attendeesAndDates.time')"
                                prepend-icon="$vuetify.icons.clock"
                                required
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              />
                            </template>
                            <v-time-picker
                              v-model="oralExamInput[i].examTimeInput"
                              full-width
                              format="24hr"
                              @click:minute="group.timeMenu = false"
                              @click:hour="onOralExamTimeChanged(oralExamInput[i].examTimeInput, i)"
                              @change="onOralExamTimeChanged(oralExamInput[i].examTimeInput, i)"
                            />
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-btn
                    id="addOralExamBtn"
                    class="mb-8"
                    small
                    dark
                    depressed
                    @click="addGroup(formFields.oralExamGroups, 'oral')"
                  >
                    {{ $t('page.create.fields.attendeesAndDates.oralGroups.addGroup') }}
                    <v-icon
                      right
                      x-small
                    >
                      $vuetify.icons.addAction
                    </v-icon>
                  </v-btn>

                  <v-row>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        v-model="allOralParticipants"
                        :label="$t('page.create.fields.attendeesAndDates.oralGroups.totalAttendees')"
                        dense
                        type="number"
                        hide-details="auto"
                        outlined
                        readonly
                      />
                    </v-col>
                    <v-col
                      cols="2"
                      align-self="center"
                    >
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                          >
                            <v-icon
                              color="primary"
                            >
                              $vuetify.icons.info
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('page.create.fields.attendeesAndDates.calculationInfo')
                        }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Addresses Panel -->
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h3>{{ $t('page.create.fields.addresses.headline') }}</h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="formFields.invoiceAddress"
                        :items="addressBillingItems"
                        :item-text="address => `${address.street} ${address.houseId}, ${address.zipCode} ${address.city}`"
                        :label="$t('page.create.fields.addresses.fields.billingAddress')"
                        return-object
                        :no-data-text="ui.errors.selectExamCenter"
                        hide-selected
                        required
                        autocomplete="off"
                        type="search"
                        :rules="isEmptyRule"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="formFields.deliveryAddress"
                        :items="addressDeliverItems"
                        :item-text="address => address.text || `${address.street} ${address.houseId}, ${address.zipCode} ${address.city}`"
                        :label="$t('page.create.fields.addresses.fields.deliveryAddress.label')"
                        return-object
                        hide-selected
                        required
                        :no-data-text="ui.errors.selectExamCenter"
                        autocomplete="off"
                        type="search"
                        :rules="isEmptyRule"
                      />
                      <span
                        v-if="isBamf"
                        class="caption"
                      >
                        {{ $t('page.create.fields.addresses.fields.deliveryAddress.caption') }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="formFields.deliveryCertificatesAddress"
                        :items="addressDeliverCertItems"
                        :item-text="address => `${address.street} ${address.houseId}, ${address.zipCode} ${address.city}`"
                        :label="$t('page.create.fields.addresses.fields.deliveryAddressCertificates.label')"
                        return-object
                        hide-selected
                        required
                        :no-data-text="ui.errors.selectExamCenter"
                        autocomplete="off"
                        type="search"
                        :rules="isEmptyRule"
                      />
                      <span
                        v-if="isBamf"
                        class="caption"
                      >
                        {{ $t('page.create.fields.addresses.fields.deliveryAddressCertificates.caption')
                        }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="formFields.examinationLocation"
                        :items="addressExamItems"
                        :item-text="address => address.text || `${address.street} ${address.houseId}, ${address.zipCode} ${address.city}${address.addressType === 'DTZ_LOC' ? ` (STO: ${address.extRef})` : ''}`"
                        :label="$t('page.create.fields.addresses.fields.examLocation')"
                        item-value="uuid"
                        return-object
                        hide-selected
                        required
                        :no-data-text="ui.errors.selectExamCenter"
                        autocomplete="off"
                        type="search"
                        :error-messages="locationError"
                        :rules="isEmptyRule"
                      />
                    </v-col>
                  </v-row>

                  <template v-if="formFields.examinationLocation.extRef == '9999'">
                    <v-row>
                      <v-col
                        cols="6"
                        md="2"
                      >
                        <v-text-field
                          v-model="formFields.examinationLocation.sto"
                          :label="$t('page.create.fields.addresses.fields.altAddressFields.sto')"
                          type="text"
                          :rules="isEmptyRuleExamLocation"
                          @input="resetLocationError()"
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        md="8"
                      >
                        <v-text-field
                          v-model="formFields.examinationLocation.street"
                          :label="$t('page.create.fields.addresses.fields.altAddressFields.street')"
                          type="text"
                          :rules="isEmptyRuleExamLocation"
                          @input="resetLocationError()"
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        md="2"
                      >
                        <v-text-field
                          v-model="formFields.examinationLocation.houseId"
                          :label="$t('page.create.fields.addresses.fields.altAddressFields.houseNumber')"
                          type="text"
                          @input="resetLocationError()"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="6"
                        md="3"
                      >
                        <v-text-field
                          v-model="formFields.examinationLocation.zipCode"
                          :label="$t('page.create.fields.addresses.fields.altAddressFields.postalCode')"
                          type="text"
                          :rules="[...isEmptyRuleDeliveryCert, ...isValidPlz]"
                          validate-on-blur
                          @input="resetLocationError()"
                        />
                      </v-col>
                      <v-col
                        cols="6"
                        md="5"
                      >
                        <v-text-field
                          v-model="formFields.examinationLocation.city"
                          :label="$t('page.create.fields.addresses.fields.altAddressFields.city')"
                          type="text"
                          :rules="isEmptyRuleExamLocation"
                          @input="resetLocationError()"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-select
                          v-model="formFields.examinationLocation.country"
                          :items="countries"
                          item-text="label"
                          item-value="code"
                          :rules="isEmptyRuleExamLocation"
                        >
                          <template slot="label">
                            {{ $t('page.create.fields.addresses.fields.altAddressFields.country') }}
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row
                      align="center"
                      class="flex-nowrap"
                    >
                      <v-col cols="auto">
                        <v-checkbox
                          v-model="formFields.termsAndConditions.agbDifferentExamPlace.accepted"
                          :rules="isEmptyRuleExamLocation"
                        />
                      </v-col>
                      <v-col>
                        <p
                          class="mb-0"
                          v-html="$t('page.create.fields.addresses.fields.terms&ConditionsAgreement')"
                        />
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Misc Panel -->
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h3>{{ $t('page.create.fields.other.heading') }}</h3>
                  <small class="d-none d-md-block text-right pr-4">
                    {{ $t('page.create.fields.other.hospitation') }}
                  </small>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>{{ $t('page.create.fields.other.text') }}</p>
                  <v-btn
                    outlined
                    condensed
                    :href="`mailto:${telcEmail}?subject=${emailSubject}`"
                  >
                    {{ $t('page.create.fields.other.email') }}
                  </v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>

          <!-- telc interner Hinweis -->
          <div
            v-if="isTelcUser"
            class="mb-8"
          >
            <v-textarea
              v-model="formFields.internalNotes"
              :label="$t('page.create.fields.internalNotes')"
              :hint="$t('page.create.fields.internalNotesHint')"
              name="input-7-1"
              outlined
              auto-grow
              value=""
              persistent-hint
            />
          </div>

          <!-- Notes Field -->
          <div class="mb-8">
            <v-textarea
              v-model="formFields.additionalNotes"
              :label="$t('page.create.fields.additionalNotes')"
              :hint="$t('page.create.fields.additionalNotesHint')"
              name="input-7-1"
              outlined
              auto-grow
              value=""
              persistent-hint
            />
          </div>

          <!-- Checkboxes -->
          <div>
            <v-checkbox
              v-model="formFields.termsAndConditions.telcLicence.accepted"
              :label="$t('page.create.fields.agreementExaminerLicence')"
              class="mt-0"
              required
              :rules="isEmptyRuleApproval"
            />
            <v-checkbox
              v-model="formFields.termsAndConditions.bamf.accepted"
              :label="$t('page.create.fields.agreementInstitutionLicence')"
              class="mt-0"
              required
              :rules="isEmptyRuleApproval"
            />
            <v-checkbox
              v-model="formFields.termsAndConditions.telcAgb.accepted"
              :label="$t('page.create.fields.agreementTerms&Conditions')"
              class="mt-0"
              required
              :rules="isEmptyRuleApproval"
            />
            <v-checkbox
              v-if="isDtz"
              v-model="formFields.termsAndConditions.dtz.accepted"
              :label="$t('page.create.fields.agreementDtz')"
              class="mt-0"
              required
              :rules="isEmptyRuleApprovalDtz"
            />
          </div>
        </v-col>
      </v-row>
    </v-form>

    <!-- Before Transmit Dialog -->
    <v-dialog
      v-model="beforeTransmitDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title>
          {{ $t('page.create.beforeTransmitDialog.dataCorrectness') }}
        </v-card-title>
        <v-card-text>
          <p class="text-subtitle-1 mb-0">
            {{ $t('page.create.beforeTransmitDialog.checkDataRequest') }} <b class="error--text">
              {{ $t('page.create.beforeTransmitDialog.noChangesInfo') }}
            </b>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            outlined
            depressed
            @click="beforeTransmitDialog = false"
          >
            {{ $t('page.create.beforeTransmitDialog.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="transmit"
          >
            {{ $t('page.create.beforeTransmitDialog.allCorrect') }}
            <v-icon
              right
              x-small
            >
              $vuetify.icons.transmitAction
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Before Abort Dialog -->
    <v-dialog
      v-model="beforeAbortDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title>
          {{ $t('page.create.beforeAbortDialog.dataCorrectness') }}
        </v-card-title>
        <v-card-text>
          <p class="text-subtitle-1 mb-0">
            {{ $t('page.create.beforeAbortDialog.checkDataRequest') }} <b class="error--text">
              {{ $t('page.create.beforeAbortDialog.noChangesInfo') }}
            </b>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            outlined
            depressed
            @click="beforeAbortDialog = false"
          >
            {{ $t('page.create.beforeAbortDialog.cancel') }}
          </v-btn>
          <v-btn
            color="error"
            depressed
            @click="abort"
          >
            {{ $t('page.create.beforeAbortDialog.allCorrect') }}
            <v-icon
              right
              x-small
            >
              $vuetify.icons.abortAction
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <pv-actionbar
      :primary-actions="ui.primaryActions"
      :secondary-actions="ui.secondaryActions"
      @cancel="onCancel"
      @save="onSave"
      @transmit="onTransmit"
      @abort="onAbort"
    />
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import { getCountries } from '@/data/';
import { totalNumberAttendeesMixin } from '@/mixins/totalNumberAttendees.js';
import pvActionbar from './Actionbar';
import BasicData from './BasicData.vue';

export default {
  components: {
    pvActionbar,
    BasicData
  },

  mixins: [totalNumberAttendeesMixin],

  inject: [
    'pruefungsverwaltung',
    'limitsetverwaltung'
  ],

  props: {
    /* override the exam uuid (for testing purposes ONLY)
     * this is much easier than mocking the $route
     */
    examUuidOverride: {
      type: String,
      default: null
    }
  },

  data: (vm) => ({
    // Panels
    panel: [],
    items: 3,
    config: {},
    /**
     * The model used for back-and-forth communication with the BasicData
     * child component.
     *
     * @member fields contains all values that are represented in the form
     * @member additionalData contains meta-information
     * @member isValid is true iff all required fields are valid
     */
    basicDataModel: {
      fields: {
        version: '',
        oral_versions: [],
        examinationInstitute: null,
        examinationCenter: null,
        contactPerson: null,
        examSubject: {
          level: '',
          id: '',
          examType: '',
          title: '',
          language: '',
          allowedDates: []
        },
        overrideKtrID: ''
      },
      additionalData: {
        editKtrID: false,
        maxOralAttendees: 100,
        licence: null
      },
      isValid: false
    },
    showBasicDataErrors: false,
    receivedExam: null,

    formFields: {
      status: null,
      invoiceAddress: {},
      deliveryAddress: {},
      deliveryCertificatesAddress: {},
      examinationLocation: {
        name: null,
        street: null,
        houseId: null,
        zipCode: null,
        city: null,
        country: null,
        sto: null
      },
      oral_versions: [],
      oralExamGroups: [
        {
          groupSize: 0,
          numberPartialExams: 0,
          dateMenu: false,
          timeMenu: false,
          time: '', // never used in this component
          examDate: '' // date in ISO format, e.g. '2022-07-21T15:10:00Z'
        }
      ],
      writingExamGroups: [
        {
          examDate: '', // date in ISO format, e.g. '2022-07-21T15:10:00Z'
          groupSize: 0,
          numberPartialExams: 0
        }
      ],
      examDate: '', // date in ISO format, e.g. '2022-07-21T15:10:00Z'
      time: '', // never used in this component
      dateMenu: false,
      timeMenu: false,
      additionalNotes: '',
      internalNotes: '',
      examOrderNumber: null,
      termsAndConditions: {
        telcLicence: {
          accepted: false
        },
        bamf: {
          accepted: false
        },
        telcAgb: {
          accepted: false
        },
        agbDifferentExamPlace: {
          accepted: false
        },
        dtz: {
          accepted: false
        }
      },
      totalNumberAttendees: 0
    },

    // Date- and time-picker values
    oralExamInput: [
      {
        examDateInput: moment().format('YYYY-MM-DD'), // oralExamDate from datepicker
        examTimeInput: '' // oralExamTime from timepicker
      }
    ],
    writtenExamDateInput: moment().format('YYYY-MM-DD'), // writtenExamDate from datepicker, e.g. '2022-07-13'
    writtenExamTimeInput: '', // writtenExamTime from timepicker, e.g. '20:05'

    // Dialogs
    beforeTransmitDialog: false,
    beforeAbortDialog: false,

    // Instititute & Exam center
    centerModel: null,
    instituteData: null,
    examinationCenters: null,

    // Dropdown data
    centerItems: [],
    apItems: [],
    niveauItems: [],
    fachItems: [],
    kursartItems: [],

    // Validation
    dateValidationError: null,
    validTime: null,
    maxOralAttendees: 100,
    basicValidation: false,
    locationError: '',
    examDateError: '',
    isEmptyRuleBasic: [(v) => !!v || vm.$t('page.create.messages.requiredFieldBasic')],
    isEmptyRuleBasicDtz: [(v) => {
      if (vm.isDtz) return !!v || vm.$t('page.create.messages.requiredFieldBasic');

      return true;
    }],
    isEmptyRule: [
      (v) => !!v || vm.$t('page.create.messages.requiredField'),
      (v) => v !== ' ' || vm.$t('page.create.messages.requiredField')
    ],
    isEmptyRuleApproval: [(v) => !!v || vm.$t('page.create.messages.requiredField')],
    isEmptyRuleApprovalDtz: [(v) => {
      if (vm.isDtz) return !!v || vm.$t('page.create.messages.requiredField');
      return true;
    }],
    isWritingGroupSizeValid: [
      (v) => v <= 100 || vm.$t('page.create.messages.groupSizeExceeded', { max: 100 }),
      (v) => !!v || vm.$t('page.create.messages.requiredField'),
      (v) => v !== '0' || vm.$t('page.create.messages.requiredField')
    ],
    isOralGroupSizeValid: [
      (v) => v <= vm.basicDataModel.additionalData.maxOralAttendees || vm.$t('page.create.messages.groupSizeExceeded', { max: vm.basicDataModel.additionalData.maxOralAttendees }),
      (v) => !!v || vm.$t('page.create.messages.requiredField'),
      (v) => v !== '0' || vm.$t('page.create.messages.requiredField')
    ],
    isTotalNumberAttendeesValid: [
      () => (vm.isTotalNumberAttendeesInRange) || vm.$t('page.create.messages.totalNumberAttendeesOutOfRange', { min: vm.minNumberOfAllParticipants, max: vm.maxNumberOfAllParticipants })
    ],
    isEmptyRuleDelivery: [
      (v) => (vm.addressDeliver === '9999' ? !!v : true)
    ],
    isEmptyRuleDeliveryCert: [
      (v) => (vm.addressDeliverCert === '9999' ? !!v : true)
    ],
    isEmptyRuleExamLocation: [
      (v) => (vm.addressExam === '9999' ? !!v : true)
    ],
    isValidPlz: [
      // (v) => (v?.length === 5 && parseInt(v, 10)) || vm.$t('page.create.messages.plzValidationError')
    ],
    isValidTime: [
      (v) => (v === '0:00' || v === '00:00' ? vm.$t('page.create.messages.changeData') : true)
    ],

    // Meta Header
    metaAuftragsnummer: '-',
    pruefungsId: '-',
    metaSAPnummer: '-',
    prevStatus: null,

    // Licences and Limit Sets
    limitSetLimits: {},
    ignoreErrors: [
      'termsAndConditions.telcLicence.username',
      'termsAndConditions.bamf.username',
      'termsAndConditions.telcAgb.username',
      'termsAndConditions.agbDifferentExamPlace.username'
    ],

    telcEmail: 'service@telc.net',
    emailSubject: vm.$t('page.create.fields.other.emailSubject'),

    disableOnAction: false,

    originalExamSubjectId: null
  }),

  computed: {

    eiUuid() {
      return localStorage.getItem('eiUuid') || this.$route.query.institute;
    },

    examCenterIsInstitute() {
      const { examCenter, institute } = this.basicDataModel.fields;
      return !!examCenter && !!institute && examCenter.uuid === institute.uuid;
    },

    isDigital() {
      return this.basicDataModel.fields.examSubject.format === 'digital';
    },

    /**
     * @returns {boolean}
     */
    isDtz() {
      return this.basicDataModel.fields.examSubject?.id === 1010;
    },

    /**
     * @returns {boolean}
     */
    isBamf() {
      const bamfCodes = [1010, 1013, 1014, 1015, 1016];
      return bamfCodes.includes(this.basicDataModel.fields.examSubject?.id);
    },

    /**
     * @returns {Object[]}
     */
    countries() {
      return getCountries();
    },

    ui() {
      return {
        secondaryActions: [
          {
            title: this.$t('page.create.abort'),
            color: 'error',
            event: 'abort',
            hidden: !this.showTerminateButton
          },
          {
            title: this.$t('page.create.cancel'),
            outlined: true,
            color: 'white',
            event: 'cancel'
          },
          {
            title: this.$t('page.create.save'),
            dark: true,
            color: 'primary',
            event: 'save',
            hidden: this.hideSaveButton,
            disabled: this.disableOnAction
          }
        ],
        primaryActions: [
          {
            title: this.$t('page.create.transmit'),
            color: 'primary',
            dark: true,
            hidden: this.hideTransmitButton,
            icon: '$vuetify.icons.transmitAction',
            event: 'transmit',
            disabled: this.disableOnAction
          }
        ],
        errors: {
          selectExamCenter: this.$t('page.create.messages.selectExamCenter')
        }
      };
    },

    /**
     * true if the form is in edit mode, i.e. an existing exam is being edited
     */
    editMode() {
      return !!this.examUuid;
    },

    /**
     * uuid of the existing exam being edited, if applicable
     */
    examUuid() {
      return this.examUuidOverride || this.$route.params.id;
    },

    /**
     * Computes all applicable billing addresses.
     */
    addressBillingItems() {
      if (!this.basicDataModel.fields.examinationCenter) return [];

      const type = 'BILL_TO';
      const { examinationCenter, examinationInstitute } = this.basicDataModel.fields;
      const { examCenterAddresses, instituteAddresses } = this.getInstituteAndCenterAddressesByType(examinationCenter, examinationInstitute, type);

      let result;

      if (this.basicDataModel.fields.examinationInstitute?.exceptionalInvoice?.includes(parseInt(this.basicDataModel.fields.examSubject.id, 10))) { // Different invoice addresses if exception
        result = instituteAddresses;
      } else {
        result = examCenterAddresses;
      }

      if (this.examCenterIsInstitute && this.ecUuid === '') {
        result = instituteAddresses;
      }

      if (!this.addressAlreadyInList(this.formFields.invoiceAddress, result)) {
        result.push(this.formFields.invoiceAddress);
      }

      return result;
    },

    /**
     * Computes all applicable certificate delivery addresses.
     */
    addressDeliverCertItems() {
      const result = this.addressDeliverItems;

      if (!this.addressAlreadyInList(this.formFields.deliveryCertificatesAddress, result)) {
        result.push(this.formFields.invoiceAddress);
      }

      return result;
    },

    /**
     * Computes all applicable exam material delivery addresses.
     */
    addressDeliverItems() {
      if (!this.basicDataModel.fields.examinationCenter) return [];
      const type = 'SHIP_TO';
      const { examinationCenter, examinationInstitute } = this.basicDataModel.fields;
      const { examCenterAddresses, instituteAddresses } = this.getInstituteAndCenterAddressesByType(examinationCenter, examinationInstitute, type);

      let result;

      if (this.ecUuid === '') { // EC users get EC addresses, institute users get institute addressses
        result = instituteAddresses;
      } else {
        result = examCenterAddresses;
      }

      if (!this.examCenterIsInstitute && this.ecUuid === '') {
        result = examCenterAddresses;
      }

      if (!this.addressAlreadyInList(this.formFields.deliveryAddress, result)) {
        result.push(this.formFields.deliveryAddress);
      }

      return result;
    },

    /**
     * Computes all applicable exam addresses ('durchfuehrungsort').
     */
    addressExamItems() {
      if (!this.basicDataModel.fields.examinationCenter) {
        return [];
      }

      let result = [];

      if (!this.isDigital) {
        const type = this.isDtz ? 'DTZ_LOC' : 'EXAM_LOC';
        const { examinationCenter, examinationInstitute } = this.basicDataModel.fields;
        const { examCenterAddresses, instituteAddresses } = this.getInstituteAndCenterAddressesByType(examinationCenter, examinationInstitute, type);

        if (this.ecUuid === '') { // EC users get EC addresses, institute users get institute addressses
          result = instituteAddresses;
        } else {
          result = examCenterAddresses;
        }

        if (!this.examCenterIsInstitute && this.ecUuid === '') {
          result = instituteAddresses.concat(examCenterAddresses);
        }
      }

      if (!this.addressAlreadyInList(this.formFields.examinationLocation, result)) {
        result.push(this.formFields.examinationLocation);
      }

      if (this.isDigital) {
        result = [...result, {
          text: this.$t('page.create.fields.addresses.fields.digitalExamLocation'),
          extRef: '9999'
        }];
      } else if (this.isDtz) {
        result = [...result, {
          text: this.$t('page.create.fields.addresses.fields.newExamLocation'),
          extRef: '9999'
        }];
      } else {
        result = [...result, {
          text: this.$t('page.create.fields.addresses.fields.differentExamLocation'),
          extRef: '9999'
        }];
      }

      return result;
    },

    /**
     * Computes the exam sent to the backend on saving/transmitting, but also on
     * validation.
     *
     * All values are deliberately copied, so that output-related formatting does
     * not impact the data of the form in any way.
     */
    outputExam() {
      /*
       * If editing an existing exam, copy all unchangeable data (i.e. creation
       * date, uuid, extref, etc.) from the existing exam.
       */
      const exam = this.editMode
        ? { ...this.existingExamInfo }
        : {};

      exam.examinationInstitute = {
        name: this.basicDataModel.fields.examinationInstitute.name,
        uuid: this.basicDataModel.fields.examinationInstitute.uuid,
        extRef: this.basicDataModel.fields.examinationInstitute.extRef
      };

      exam.oral_versions = this.basicDataModel.fields.oral_versions;
      exam.version = this.basicDataModel.fields.version;
      exam.examinationCenter = {
        name: this.basicDataModel.fields.examinationCenter.name,
        uuid: this.basicDataModel.fields.examinationCenter.uuid,
        extRef: this.basicDataModel.fields.examinationCenter.extRef
      };
      exam.contactPerson = this.basicDataModel.fields.contactPerson;
      exam.examSubject = this.basicDataModel.fields.examSubject;
      exam.examDate = this.outputExamDate;
      if (this.isDtz) {
        exam.overrideKtrID = this.outputOverrideKtrID;
      }

      exam.oralExamGroups = this.outputOralExamGroups;
      exam.writingExamGroups = this.outputWrittenExamGroups;
      exam.totalNumberAttendees = this.formFields.totalNumberAttendees;

      exam.examinationLocation = this.formFields.examinationLocation;
      exam.invoiceAddress = this.formFields.invoiceAddress;
      exam.deliveryAddress = this.formFields.deliveryAddress;
      exam.deliveryCertificatesAddress = this.formFields.deliveryCertificatesAddress;

      exam.additionalNotes = this.formFields.additionalNotes;
      exam.internalNotes = this.formFields.internalNotes;

      exam.termsAndConditions = this.outputTermsAndConditions;

      exam.status = this.formFields.status;

      return exam;
    },

    existingExamInfo() {
      // These keys define which data is copied from the existing exam upon transmitting
      const info = [
        { key: 'uuid', defaultValue: '' },
        { key: 'extRef', defaultValue: '' },
        { key: 'examOrderNumber', defaultValue: null },
        { key: 'audioPortalAccess', defaultValue: null },
        { key: 'bamfMessage', defaultValue: '' },
        { key: 'bamfMessageDescription', defaultValue: '' },
        { key: 'bamfTransactionId', defaultValue: '' },
        { key: 'desiredAppointment', defaultValue: '' },
        { key: 'eventsProcessed', defaultValue: null },
        { key: 'created', defaultValue: '' },
        { key: 'updated', defaultValue: '' },
        { key: 'statusInfo', defaultValue: '' },
        { key: 'overrideKtrID', defaultValue: '' },
        { key: 'actualNumberAttendees', defaultValue: 0 },
        { key: 'trackingInfos', defaultValue: null }
      ];
      const examInfo = {};
      info.forEach(({ key, defaultValue }) => {
        examInfo[key] = this.receivedExam[key] || defaultValue;
      });
      return examInfo;
    },

    outputOverrideKtrID() {
      return this.basicDataModel.fields.overrideKtrID || '';
    },

    outputOralExamGroups() {
      return this.formatGroups(this.formFields.oralExamGroups);
    },

    outputWrittenExamGroups() {
      return this.formatGroups(this.formFields.writingExamGroups);
    },

    outputExamDate() {
      const { examDate, time } = this.formFields;
      const dateWithTime = moment(`${examDate} ${time}`).format();
      if (dateWithTime !== 'Invalid date') {
        return moment.utc(dateWithTime).format();
      }
      return moment.utc(examDate).format();
    },

    outputTermsAndConditions() {
      const termsAndConditions = { ...this.formFields.termsAndConditions };
      if (!this.isDtz) termsAndConditions.dtz = null;
      termsAndConditions.lateRegistrationCosts = null;
      return termsAndConditions;
    },

    hideSaveButton() {
      if (this.formFields.examinationCenter?.inactive) return true;
      if (this.isTelcUser || this.isPassedRegistrationDeadline(this.formFields.examDate)) return false;
      if (this.formFields.status !== 'open') return true;
      return false;
    },

    hideTransmitButton() {
      if (this.formFields.examinationCenter?.inactive) return true;
      return this.isPassedRegistrationDeadline(this.formFields.examDate);
    },

    showTerminateButton() {
      return this.editMode
        && this.formFields.status === 'submitted'
        && this.limitSetLimits
        && moment().isBefore(moment(this.formFields.examDate).subtract(this.limitSetLimits.cancellationDeadline, 'd'));
    },

    limitSetId() {
      const { licence } = this.basicDataModel.additionalData;
      return licence?.limitSet;
    },

    isAllDataRecievedForEditMode() {
      if (this.editMode) {
        const { allowedDates } = this.basicDataModel.fields.examSubject;
        const { licence } = this.basicDataModel.additionalData;
        if (this.isBamf) {
          return [allowedDates, licence].every((x) => !!x);
        }
        return !!licence;
      }
      return false;
    }

  },

  watch: {
    /* eslint-disable-next-line */
    'basicDataModel.fields.examSubject.id': function (val) {
      if (val) {
        this.setLimits();
      }
    },
    /* eslint-disable-next-line */
    'isAllDataRecievedForEditMode': function (val) {
      if (val) {
        this.setLimits();
      }
    }
  },

  created() {
    this.pullExam();
  },

  mounted() {
    this.buildApprovalObject();
  },

  methods: {
    ...mapMutations('notice', {
      addNotice: 'add',
      clearNotices: 'clear'
    }),
    ...mapActions('dialogs', {
      showAddDialog: 'SHOW_ADD'
    }),

    pullExam() {
      if (this.editMode) {
        this.pruefungsverwaltung.getExam(this.eiUuid, this.examUuid).then(({ data: { exam } }) => {
          const {
            // eslint-disable-next-line camelcase
            oral_versions,
            version,
            examinationInstitute,
            examinationCenter,
            contactPerson,
            examSubject,
            overrideKtrID
          } = exam;

          this.basicDataModel.fields = {
            // eslint-disable-next-line camelcase
            version, examinationCenter, contactPerson, examinationInstitute, examSubject, overrideKtrID, oral_versions
          };

          this.formFields = this.modifyInput(exam);
          this.receivedExam = { ...exam };

          this.originalExamSubjectId = examSubject.id;
        });
      } else {
        this.formFields.status = 'open';
      }
    },

    formatDateTime(date, time) {
      if (time) {
        return moment.utc(moment(`${date}T${time}`).format()).format();
      }
      return moment.utc(moment(`${date}T00:00`).format()).format();
    },

    setWrittenExamDateTime(date, time) {
      this.formFields.writingExamGroups.forEach((writtenExamGroup) => {
        writtenExamGroup.examDate = this.formatDateTime(date, time);
      });
      this.formFields.examDate = this.formatDateTime(date, time);
    },

    setOralExamDateTime(date, time, index) {
      const group = this.formFields.oralExamGroups[index];
      if (group) group.examDate = this.formatDateTime(date, time);
    },

    onWrittenExamDateChanged(newDate) {
      this.setWrittenExamDateTime(newDate, this.writtenExamTimeInput);
    },

    onWrittenExamTimeChanged() {
      this.setWrittenExamDateTime(this.writtenExamDateInput, this.writtenExamTimeInput);
    },

    onOralExamDateChanged(newDate, i) {
      this.setOralExamDateTime(newDate, this.oralExamInput[i].examTimeInput, i);
    },

    onOralExamTimeChanged(newTime, i) {
      this.setOralExamDateTime(this.oralExamInput[i].examDateInput, newTime, i);
    },

    pullLimitSet() {
      this.limitsetverwaltung.getLimitSet(this.limitSetId).then(({ data: { limitSet } }) => {
        this.limitSetLimits = limitSet.limits;
        if (moment(this.writtenExamDateInput).isBefore(moment().add(this.limitSetLimits.registrationDeadline, 'd').format('YYYY-MM-DD')) && !this.editMode) {
          this.writtenExamDateInput = moment().add(this.limitSetLimits.registrationDeadline, 'd').format('YYYY-MM-DD');
          this.oralExamInput.forEach((oralExamGroup) => {
            oralExamGroup.examDateInput = moment().add(this.limitSetLimits.registrationDeadline, 'd').format('YYYY-MM-DD');
          });
        }
        if (!this.editMode && this.isBamf) {
          this.basicDataModel.fields.examSubject.allowedDates.find((date) => {
            if (moment(date).isSameOrAfter(moment().add(this.limitSetLimits.registrationDeadline, 'd').format('YYYY-MM-DD'))) {
              this.writtenExamDateInput = date;
              this.oralExamInput.forEach((oralExamGroup) => {
                oralExamGroup.examDateInput = date;
              });
              return true;
            }
            return false;
          });
        }
        this.oralExamInput.forEach((oralExamGroup, i) => {
          this.setOralExamDateTime(oralExamGroup.examDateInput, oralExamGroup.examTimeInput, i);
        });
        this.setWrittenExamDateTime(this.writtenExamDateInput, this.writtenExamTimeInput);
      });
    },

    setLimits() {
      if (this.limitSetId) this.pullLimitSet();
    },

    isPassedRegistrationDeadline(date = false) {
      if (this.isTelcUser) return false;
      const examDate = moment(date).format() || moment().format();
      const dateLate = moment(examDate).subtract(this.limitSetLimits.registrationDeadline, 'd');
      return moment().isAfter(dateLate, 'day');
    },

    /**
     * Modify/Add fields of/to Exam
     *
     * @param {Object[]} fields
     * @returns {Object[]}
     */
    modifyInput(fields) {
      fields.oralExamGroups.forEach((oralExamGroup, i) => {
        const dateTimeInput = i === 0 ? this.oralExamInput[0] : this.newOralExamInput();
        oralExamGroup.dateMenu = false;
        oralExamGroup.timeMenu = false;
        dateTimeInput.examDateInput = moment(oralExamGroup.examDate).format('YYYY-MM-DD');
        dateTimeInput.examTimeInput = moment(oralExamGroup.examDate).format('HH:mm');
        this.setOralExamDateTime(dateTimeInput.examDateInput, dateTimeInput.examTimeInput, i);
      });

      fields.dateMenu = false;
      fields.timeMenu = false;
      this.writtenExamDateInput = fields.examDate ? moment(fields.examDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      this.writtenExamTimeInput = fields.examDate ? moment(fields.examDate).format('HH:mm') : '0:00';
      this.setWrittenExamDateTime(this.writtenExamDateInput, this.writtenExamTimeInput);

      if (fields.examinationLocation.extRef === '' && fields.examinationLocation.name !== '' && fields.examSubject.id !== 1010) {
        fields.examinationLocation.extRef = '9999';
      }

      // set default termsAndConditions fields if backend returns null values
      // hotfix TODO refactor
      const { termsAndConditions } = fields;

      ['telcLicence', 'bamf', 'telcAgb', 'agbDifferentExamPlace', 'dtz'].forEach((key) => {
        if (termsAndConditions[key]) {
          fields.termsAndConditions[key] = termsAndConditions[key];
        } else {
          fields.termsAndConditions[key] = {
            accepted: false
          };
        }
      });
      if (fields.oral_versions === null) {
        fields.oral_versions = [];
      }

      return fields;
    },

    resetLocationError() {
      this.locationError = '';
    },

    allowedDatesWrittenExam(targetDate) {
      if (this.isTelcUser) return true;
      const registrationLeadTimeDate = moment().add(this.limitSetLimits.registrationDeadline, 'd').format('YYYY-MM-DD');
      const examIsWithinLeadTime = moment(targetDate).isSameOrAfter(registrationLeadTimeDate);
      if (!examIsWithinLeadTime) {
        return false;
      }

      // for DTZ and DTB exams use only allowed dates
      const allowedDates = this.basicDataModel.fields.examSubject?.allowedDates;
      if (this.isBamf && allowedDates?.length) {
        return allowedDates.includes(moment(targetDate).format('YYYY-MM-DD'));
      }

      return true;
    },

    allowedDatesOralExam(val) {
      if (this.isTelcUser) return true;

      const examDate = moment(this.formFields.examDate);

      let startIntervalDate = examDate.clone().subtract(this.limitSetLimits.intervalExamDates + 1, 'days');

      if (this.isDtz || this.limitSetId === 'dtb') {
        // Oral exams for DTZ or DTB exams can only be at the same date as the written exam or later, but not before
        startIntervalDate = examDate.clone().subtract(1, 'day');
      }

      const endIntervalDate = examDate.clone().add(this.limitSetLimits.intervalExamDates + 1, 'days');

      return moment(val).isBetween(startIntervalDate, endIntervalDate);
    },

    all() {
      this.panel = [...Array(this.items).keys()].map((k, i) => i);
    },

    none() {
      this.panel = [];
    },

    addGroup(groups, type = 'written') {
      const oralExamDate = groups.length > 1 ? moment(groups[groups.length - 1].examDate).format('YYYY-MM-DD') : this.oralExamInput[0].examDateInput;

      groups.push({
        examDate: '',
        groupMenu: false,
        groupSize: 1,
        numberPartialExams: 0,
        time: '',
        timeMenu: false
      });

      if (type === 'oral') {
        this.oralExamInput.push({
          examDateInput: oralExamDate,
          examTimeInput: ''
        });
        this.oralExamInput.forEach((oralExamGroup, i) => {
          this.setOralExamDateTime(oralExamGroup.examDateInput, oralExamGroup.examTimeInput, i);
        });
      } else {
        this.setWrittenExamDateTime(this.writtenExamDateInput, this.writtenExamTimeInput);
      }
      this.$refs.totalNumberAttendee.validate();
    },

    removeGroup(groups, idx, type = 'written') {
      groups.splice(idx, 1);
      if (type === 'oral') {
        this.oralExamInput.splice(idx, 1);
      }
      this.$refs.totalNumberAttendee.validate();
    },

    newOralExamInput() {
      const newInput = {
        examDateInput: this.oralExamInput[0]?.examDateInput,
        examTimeInput: ''
      };
      this.oralExamInput.push(newInput);
      return newInput;
    },

    getAddressesByType(instance, type, center) {
      const activeAddresses = instance.filter((obj) => obj.isActive);

      const result = activeAddresses.filter((obj) => obj.addressType === type).map((obj) => {
        if (this.examCenterIsInstitute) obj.ownerUuid = this.basicDataModel.fields.examinationInstitute.uuid;
        if (!this.examCenterIsInstitute) obj.ownerUuid = center.uuid;
        obj.name = center.name;
        return obj;
      });

      return result.sort((a, b) => {
        if (a.street === '') {
          return false;
        }
        if (a.street < b.street) {
          return -1;
        }
        if (a.street > b.street) {
          return 1;
        }
        return 0;
      });
    },

    addressAlreadyInList(address, listOfAddresses) {
      if (!address || !address.uuid) {
        return true; // though not technically correct, the empty address is presumend to be in every list
      }
      return listOfAddresses.find((item) => item.uuid === address.uuid);
    },

    getInstituteAndCenterAddressesByType(examinationCenter, examinationInstitute, type) {
      let examCenterAddresses;
      let instituteAddresses;
      if (examinationCenter.addresses) {
        examCenterAddresses = this.getAddressesByType(examinationCenter.addresses, type, examinationCenter);
      } else {
        examCenterAddresses = [];
      }
      if (examinationInstitute.addresses) {
        instituteAddresses = this.getAddressesByType(examinationInstitute.addresses, type, examinationInstitute);
      } else {
        instituteAddresses = [];
      }
      return { examCenterAddresses, instituteAddresses };
    },

    buildApprovalObject() {
      const out = {};
      const self = this;
      Object.keys(self.formFields.termsAndConditions).forEach((prop) => {
        if (!this.isDtz && prop === 'dtz') return;
        if (prop) {
          out[prop] = {
            accepted: self.formFields.termsAndConditions[prop]
          };
        }
      });
      return out;
    },

    onCancel() {
      this.$router.push({ name: 'list' });
    },

    onSave() {
      this.prevStatus = this.formFields.status;
      this.save(this.editMode ? 'save' : 'create');
    },

    onTransmit() {
      this.beforeTransmitDialog = true;
    },

    transmit() {
      this.beforeTransmitDialog = false;
      this.save('transmit');
      this.disableOnAction = false;
    },

    onAbort() {
      this.beforeAbortDialog = true;
    },

    abort() {
      this.beforeTransmitDialog = false;
      this.save('terminate');
    },

    async save(type) {
      this.disableOnAction = true;
      this.showBasicDataErrors = true;
      if (this.validateFormBasic() && this.validateTimesSet() && this.validateTotalNumberAttendees()) {
        const prevStatus = this.formFields.status;
        this.debug(this.outputExam);
        switch (type) {
          case 'save':
            this.clearNotices();
            // removed Validation due to HYP-677
            this.formFields.uuid = this.examUuid;
            this.updateExam(this.basicDataModel.fields.examinationInstitute.uuid, this.examUuid, this.outputExam);
            break;

          case 'transmit':
          case 'create':
            this.clearNotices();
            this.pruefungsverwaltung.validateExam(this.eiUuid, this.outputExam).then(({ data: { validations } }) => {
              const returnedErrors = validations.filter((validation) => !this.ignoreErrors.includes(validation.field));
              if (returnedErrors.length === 0) {
                if (type === 'transmit') this.formFields.status = 'in_submission';
                if (this.formFields.examinationLocation.extRef === '9999') {
                  this.formFields.examinationLocation.extRef = '';
                }
                if (this.editMode) { // TODO find out how a uuid can be set in case 'create'
                  this.updateExam(this.basicDataModel.fields.examinationInstitute.uuid, this.examUuid, this.outputExam);
                } else {
                  this.createExam(this.basicDataModel.fields.examinationInstitute.uuid, this.outputExam);
                }
              } else {
                this.showValidationErrors(returnedErrors);
                this.disableOnAction = false;
                this.formFields.status = prevStatus;
              }
            });
            break;

          case 'terminate':
            this.clearNotices();
            this.formFields.status = 'terminated';
            this.formFields.uuid = this.examUuid;
            // eslint-disable-next-line no-case-declarations
            this.updateExam(this.formFields.examinationInstitute.uuid, this.examUuid, this.outputExam);
            break;

          default:
            this.addNotice({
              message: this.$t('page.create.messages.fillFieldsRequest'),
              type: 'error'
            });
            break;
        }
        this.formFields.status = prevStatus;
      } else {
        this.disableOnAction = false;
        this.basicValidation = true;
        this.addNotice({
          message: this.$t('page.create.messages.fillBasicDateRequest'),
          type: 'error'
        });
        this.formFields.status = this.prevStatus;
      }

      return this;
    },

    showValidationErrors(errors) {
      errors.forEach((error) => {
        switch (error.messageId) {
          case 'distance_limit_exceeded':
            this.locationError = this.$t('page.create.fields.addresses.fields.errors.distance', { radius: error.expected });
            this.addNotice({
              message: this.$t('page.create.messages.validationErrors.distance_limit_exceeded'),
              type: 'error'
            });
            break;
          case 'distance_check_error':
            this.addNotice({
              message: this.$t('page.create.messages.validationErrors.distance_check_error'),
              type: 'error'
            });
            break;
          case 'registration_deadline':
            this.dateValidationError = this.$t('page.create.fields.attendeesAndDates.errors.registration_deadline');
            this.validTime = ' ';
            this.addNotice({
              message: this.$t('page.create.messages.validationErrors.registration_deadline'),
              type: 'error'
            });
            break;
          case 'oral_before_written':
            this.dateValidationError = this.$t('page.create.fields.attendeesAndDates.errors.oral_before_written');
            this.validTime = ' ';
            this.addNotice({
              message: this.$t('page.create.messages.validationErrors.oral_before_written'),
              type: 'error'
            });
            break;
          case 'interval_exam_dates':
            this.addNotice({
              message: this.$t('page.create.messages.validationErrors.interval_exam_dates', { days: error.expected }),
              type: 'error'
            });
            break;
          case 'processing_error':
            this.addNotice({
              message: this.$t('page.create.messages.validationErrors.processing_error'),
              type: 'error'
            });
            break;
          case 'licence_error':
            this.addNotice({
              message: this.$t('page.create.messages.validationErrors.licence_error'),
              type: 'error'
            });
            break;
          case 'required':
            this.addNotice({
              message: this.$t('page.create.messages.validationErrors.required'),
              type: 'error'
            });
            break;
          default:
            this.debug('Unexpected validation error', error.messageId);
        }
      });
    },

    updateExam(instituteUuid, examId, data) {
      this.pruefungsverwaltung.updateExam(instituteUuid, examId, data).then((response) => {
        this.debug(response);
        if (response.status === 200) {
          this.$router.push({ name: 'list' });
          this.disableOnAction = false;
          this.addNotice({
            message: this.$t('page.create.messages.success'),
            type: 'success'
          });
        } else {
          this.disableOnAction = false;
        }
      });
    },

    createExam(instituteUuid, data) {
      this.pruefungsverwaltung.createExam(instituteUuid, data).then((response) => {
        this.debug(response);
        if (response.status === 201) {
          this.$router.push({ name: 'list' });
          this.disableOnAction = false;
          this.addNotice({
            message: this.$t('page.create.messages.success'),
            type: 'success'
          });
        } else {
          this.disableOnAction = false;
        }
      });
    },

    validateFormBasic() {
      return this.basicDataModel.isValid;
    },

    validateTimesSet() {
      // eslint-disable-next-line no-restricted-syntax
      for (const input of this.oralExamInput) {
        if (!input.examTimeInput) {
          this.addNotice({
            message: this.$t('page.create.messages.validationErrors.empty_time_error'),
            type: 'error'
          });

          return false;
        }
      }
      if (!this.writtenExamTimeInput) { // check the time of the writtenExamGroup
        this.addNotice({
          message: this.$t('page.create.messages.validationErrors.empty_time_error'),
          type: 'error'
        });

        return false;
      }
      return true;
    },

    validateTotalNumberAttendees() {
      if (!this.isTotalNumberAttendeesInRange) {
        this.addNotice({
          message: this.$t('page.create.messages.validationErrors.totalNumberAttendees', { min: this.minNumberOfAllParticipants, max: this.maxNumberOfAllParticipants }),
          type: 'error'
        });

        return false;
      }
      return true;
    },

    compareOralDate(writtenExamDateInput) {
      this.setWrittenExamDateTime(this.writtenExamDateInput, this.writtenExamTimeInput);
      this.oralExamInput.forEach((oralExamGroup, i) => {
        if (moment(oralExamGroup.examDateInput).isBefore(moment(writtenExamDateInput))
        || moment(oralExamGroup.examDateInput).isAfter(moment(writtenExamDateInput).add(this.limitSetLimits.intervalExamDates, 'd'))) {
          oralExamGroup.examDateInput = writtenExamDateInput;
          this.setOralExamDateTime(oralExamGroup.examDateInput, oralExamGroup.examTimeInput, i);
          this.addNotice({
            message: this.$t('page.create.fields.attendeesAndDates.errors.oralDateChanged'),
            type: 'info'
          });
        }
      });
    },

    formatGroups(groups) {
      const result = [];
      groups.forEach((group) => {
        const outputGroup = {
          groupSize: group.groupSize,
          numberPartialExams: group.numberPartialExams
        };
        if (moment(`${group.examDate} ${group.time}`).format() !== 'Invalid date') {
          outputGroup.examDate = moment.utc(moment(`${group.examDate} ${group.time}`).format()).format();
        } else {
          outputGroup.examDate = moment.utc(group.examDate).format();
        }
        result.push(outputGroup);
      });
      return result;
    }
  }
};
</script>
