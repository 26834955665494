const add = (a, b) => a + b;
const groupSize = (group) => group.groupSize;

export const totalNumberAttendeesMixin = {
  computed: {
    isTotalNumberAttendeesInRange() {
      if (!this.minNumberOfAllParticipants || !this.maxNumberOfAllParticipants) {
        return true;
      }
      return this.formFields.totalNumberAttendees >= this.minNumberOfAllParticipants && this.formFields.totalNumberAttendees <= this.maxNumberOfAllParticipants;
    },
    allWrittenParticipants() {
      return this.formFields.writingExamGroups.map(groupSize).reduce(add, 0);
    },
    allOralParticipants() {
      return this.formFields.oralExamGroups.map(groupSize).reduce(add, 0);
    },
    minNumberOfAllParticipants() {
      return Math.max(this.allWrittenParticipants, this.allOralParticipants);
    },
    maxNumberOfAllParticipants() {
      return this.allOralParticipants + this.allWrittenParticipants;
    },

    isTotalNumberAttendeesReadonly() {
      return !this.isTelcUser && !['open', 'submitted', 'in_submission'].includes(this.formFields.status);
    }
  }
};
