import Vue from 'vue';
import Vuex from 'vuex';
import toast from '@/plugins/toastify';

Vue.use(Vuex);

function createStore() {
  const notice = {
    namespaced: true,
    state: {},
    mutations: {
      clear() {
        toast.clear();
      },
      /**
       * Views can display a toast by calling this store action
       */
      add(state, notification) {
        toast.add(notification);
      }
    }
  };

  const dialogs = {
    namespaced: true,
    state: {
      setInstitute: false,
      telcConnect: false,
      lateRegistration: false,
      examinerLicences: false,
      returnInfo: false,
      add: false,
      importSurpass: false
    },
    getters: {
      get: (state) => state
    },
    mutations: {
      setSetInstituteVisibility: (state, value) => {
        state.setInstitute = !!value;
      },
      telcConnectVisibility: (state, value) => {
        state.telcConnect = !!value;
      },
      lateRegistrationVisibility: (state, value) => {
        state.lateRegistration = !!value;
      },
      examinerLicencesVisibility: (state, value) => {
        state.examinerLicences = !!value;
      },
      returnInfoVisibility: (state, value) => {
        state.returnInfo = !!value;
      },
      setAddVisibility: (state, value) => {
        state.add = !!value;
      },
      setImportSurpassVisibility: (state, value) => {
        state.importSurpass = !!value;
      }
    },
    actions: {
      SHOW_SET_INSTITUTE: ({ commit }) => {
        commit('setSetInstituteVisibility', true);
      },
      HIDE_SET_INSTITUTE: ({ commit }) => {
        commit('setSetInstituteVisibility', false);
      },
      SHOW_TELC_CONNECT: ({ commit }) => {
        commit('telcConnectVisibility', true);
      },
      HIDE_TELC_CONNECT: ({ commit }) => {
        commit('telcConnectVisibility', false);
      },
      SHOW_EXAMINER_LICENCES: ({ commit }) => {
        commit('examinerLicencesVisibility', true);
      },
      HIDE_EXAMINER_LICENCES: ({ commit }) => {
        commit('examinerLicencesVisibility', false);
      },
      SHOW_RETURN_INFO: ({ commit }) => {
        commit('returnInfoVisibility', true);
      },
      HIDE_RETURN_INFO: ({ commit }) => {
        commit('returnInfoVisibility', false);
      },
      SHOW_LATE_REGISTRATION: ({ commit }) => {
        commit('lateRegistrationVisibility', true);
      },
      HIDE_LATE_REGISTRATION: ({ commit }) => {
        commit('lateRegistrationVisibility', false);
      },
      SHOW_ADD: ({ commit }) => {
        commit('setAddVisibility', true);
      },
      HIDE_ADD: ({ commit }) => {
        commit('setAddVisibility', false);
      },
      SHOW_SURPASS_IMPORT: ({ commit }) => {
        commit('setImportSurpassVisibility', true);
      },
      HIDE_SURPASS_IMPORT: ({ commit }) => {
        commit('setImportSurpassVisibility', false);
      }
    }
  };

  const store = new Vuex.Store({
    modules: {
      notice,
      dialogs
    }
  });

  return store;
}

const store = createStore();

export default store;
export { createStore };
