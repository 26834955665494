<template>
  <v-skeleton-loader :type="type" />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  }
};
</script>
