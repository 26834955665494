<template>
  <v-dialog
    v-model="dialogs.examinerLicences"
    max-width="400px"
    @click:outside="hideDialog"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t(`page.examinerLicences.title`) }}</span>
        <v-spacer />
        <v-btn
          icon
          color="white"
          @click="hideDialog"
        >
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container
          fluid
          class="pa-0"
        >
          <template
            v-for="examinerLicence in examinerLicences"
          >
            <p
              :key="examinerLicence.code"
            >
              <strong>{{ $t(`page.examinerLicences.headers.name`) }}</strong>: {{ examinerLicence.name }}<br>
              <strong>{{ $t(`page.examinerLicences.headers.licenceType`) }}</strong>: {{ licenceTypeMapping(examinerLicence.licenceType) }} ({{ examinerLicence.licenceType }})<br>
              <strong>{{ $t(`page.examinerLicences.headers.executionType`) }}</strong>: {{ executionTypeMapping(examinerLicence.executionType) }}<br>
              <strong>{{ $t(`page.examinerLicences.headers.validFrom`) }}</strong>: {{ formatDate(examinerLicence.validFrom) }}<br>
              <strong>{{ $t(`page.examinerLicences.headers.validUntil`) }}</strong>: {{ formatDate(examinerLicence.validUntil) }}
            </p>
          </template>
          <v-divider />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="white"
          depressed
          @click="hideDialog"
        >
          {{ $t(`page.examinerLicences.actions.cancel`) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    licences: {
      type: Array,
      default: null
    }
  },

  data: () => ({
    examinerLicences: []
  }),

  computed: {
    ...mapGetters('dialogs', {
      dialogs: 'get'
    })
  },

  watch: {
    /**
     * @param {boolean} visible
     * @returns {void}
     */
    /* eslint-disable-next-line */
    'dialogs.examinerLicences': function (visible) {
      if (visible) {
        this.examinerLicences = this.licences;
      }

      this.$emit(visible ? 'show' : 'hide', this.examinerLicences);
    }
  },

  methods: {
    ...mapActions('dialogs', {
      hideExaminerLicencesDialog: 'HIDE_EXAMINER_LICENCES'
    }),

    /**
     * Hide the dialog and reset the form.
     *
     * @returns {void}
     */
    hideDialog() {
      this.hideExaminerLicencesDialog();
    },

    licenceTypeMapping(licenceType) {
      const licenceTypeMapping = {
        PRF: this.$t('page.examinerLicences.licenceTypes.prf'),
        BFR: this.$t('page.examinerLicences.licenceTypes.bfr'),
        FSP: this.$t('page.examinerLicences.licenceTypes.fsp')
      };

      return licenceTypeMapping[licenceType] || licenceType;
    },

    executionTypeMapping(executionType) {
      const executionTypeMapping = {
        online: this.$t('page.examinerLicences.executionTypes.online'),
        onsite: this.$t('page.examinerLicences.executionTypes.onsite'),
        onsite_virtual: this.$t('page.examinerLicences.executionTypes.onsiteVirtual')
      };

      return executionTypeMapping[executionType] || executionType;
    }
  }
};
</script>

<style lang="scss" scoped>
.licence-type-label {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>
