<template>
  <div class="c-hero">
    <div class="c-hero__content">
      <h1
        v-if="title"
        class="c-hero__title"
      >
        {{ title }}
      </h1>
    </div>
    <div
      v-if="sortedMetaItems.length || visibleActions.length"
      class="c-hero__meta"
    >
      <div
        v-for="(item, index) in sortedMetaItems"
        :key="index"
        class="c-hero__metaItem"
      >
        <span
          v-if="item.title"
          class="c-hero__metaItemTitle"
        >
          {{ item.title }}
        </span>

        <slot
          v-if="hasSlot(item.identifier)"
          :item="item"
          :name="item.identifier"
        />
        <span
          v-else
          class="c-hero__metaItemValue"
        >
          {{ item.value }}
        </span>
      </div>
      <div
        v-if="sortedMetaItems.length && visibleQuickNavigation.length"
        class="c-hero__metaSeparator"
      />
      <div
        v-if="visibleQuickNavigation.length"
        class="c-hero__metaItem c-hero__metaItem--actions"
      >
        <span class="c-hero__metaItemTitle">
          {{ $t('metaItems.quickNavigation.title') }}
        </span>
        <span class="c-hero__metaItemValue c-hero__metaItemValue--actions">
          <span
            v-for="(quickNav, index) in visibleQuickNavigation"
            :key="index"
          >
            <v-tooltip
              v-if="quickNav.tooltipText"
              bottom
            >
              <template #activator="{ on, attrs }">
                <div
                  class="d-inline-block"
                  v-on="on"
                >
                  <v-btn
                    v-bind="attrs"
                    depressed
                    :outlined="quickNav.outlined"
                    :dark="isDark(quickNav)"
                    :color="quickNav.color"
                    class="mb-2 mb-lg-0 mr-4"
                    :disabled="isDisabled(quickNav)"
                    @click.stop="quickNav.action"
                  >
                    <span v-if="quickNav.title">
                      {{ quickNav.title }}
                    </span>
                    <v-icon
                      v-if="quickNav.icon"
                      :right="!!quickNav.title"
                      :color="quickNav.iconColor"
                      small
                    >
                      {{ quickNav.icon }}
                    </v-icon>
                  </v-btn>
                </div>
              </template>
              <span>{{ quickNav.tooltipText }}</span>
            </v-tooltip>
          </span>
        </span>
      </div>
      <div
        v-if="sortedMetaItems.length && visibleActions.length"
        class="c-hero__metaSeparator"
      />
      <div
        v-if="visibleActions.length"
        class="c-hero__metaItem c-hero__metaItem--actions"
      >
        <span class="c-hero__metaItemTitle">
          {{ $t('metaItems.actions.title') }}
        </span>
        <span class="c-hero__metaItemValue c-hero__metaItemValue--action">
          <v-btn
            v-for="(action, index) in visibleActions"
            :key="index"
            depressed
            :outlined="action.outlined"
            :dark="isDark(action)"
            :color="action.color"
            class="mb-2 mb-lg-0 mr-4"
            @click.stop="action.action"
          >
            <span v-if="action.title">
              {{ action.title }}
            </span>
            <v-icon
              v-if="action.icon"
              :right="!!action.title"
              small
              :color="action.iconColor"
            >
              {{ action.icon }}
            </v-icon>
          </v-btn>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    actions: {
      type: Array,
      default: () => []
    },
    quickNavigation: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    metaItems: []
  }),

  computed: {
    /**
     * @returns {Object[]}
     */
    sortedMetaItems() {
      return this.metaItems.sort((a, b) => ((a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))); // eslint-disable-line
    },

    /**
     * @returns {Object[]}
     */
    visibleActions() {
      return this.actions.filter((item) => !item.hidden);
    },

    /**
     * @returns {Object[]}
     */
    visibleQuickNavigation() {
      return this.quickNavigation.filter((item) => {
        if (typeof item.hidden === 'function') {
          return !item.hidden();
        }
        return !item.hidden;
      });
    }
  },

  methods: {
    /**
     * Add a meta item to the hero.
     * Commonly called from the parent component.
     *
     * @param {Object} metaItem
     * @returns {void}
     */
    addMetaItem(metaItem) {
      if (!metaItem?.identifier) throw new Error('A meta item has to have an unique identifier');

      const metaItemIndex = this.metaItems.findIndex(({ identifier }) => identifier === metaItem.identifier);

      if (metaItemIndex !== -1) { // Meta item already exists
        const mergedMetaItem = { ...this.metaItems[metaItemIndex], ...metaItem };
        this.$set(this.metaItems, metaItemIndex, mergedMetaItem); // We have to tell Vue about this update manually
      } else {
        this.metaItems.push(metaItem);
      }
    },

    /**
     * @param {string} name
     * @returns {boolean}
     */
    hasSlot(name = 'default') {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },

    /**
     * @param {string} event
     * @param {mixed} payload
     * @returns {void}
     */
    emit(event, payload) {
      if (event) this.$emit(event, payload);
    },

    /**
     * @param {Object} action
     * @returns {boolean}
     */
    isDark(action) {
      return !!(action.dark && !action.color);
    },

    /**
     * @param {Object} action
     * @returns {boolean}
     */
    isDisabled(action) {
      return !!(action.disabled);
    }
  }
};
</script>

<style lang="scss">
.c-hero {
  &__content {
    padding: 3rem 0;
  }

  &__title {
    font-size: 2.5rem;
    position: relative;
    padding-left: 1.5rem;

    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      width: 6px;
      height: 40px;
      background-color: map-get($custom-colors, "red");
    }
  }

  &__meta {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 2rem 0;
    margin: 0 -1.5rem;
  }

  &__metaItem {
    padding: 0 1.5rem;
    @include media-breakpoint-down("lg") {
      margin-bottom: 1.5rem;
    }

    &:last-child {
      padding-right: 0;
    }

    &--actions {
      @include media-breakpoint-down("lg") {
        min-width: 100%;
      }
    }
  }

  &__metaItemTitle {
    @include media-breakpoint-up("lg") {
      max-width: 100px;
      min-height: 40px; // 2 lines at 0.85rem
    }
    font-size: 0.75rem;
    display: block;
    margin-bottom: 0.75rem;
  }

  &__metaItemValue {
    font-size: 1rem;
    display: block;
    font-weight: bold;

    &:not(.c-hero__metaItemValue--action) {
      @include media-breakpoint-up("xl") {
        max-width: 300px;
      }
      @include media-breakpoint-up("lg") {
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  &__metaSeparator {
    @include media-breakpoint-down("lg") {
      display: none;
    }
    width: $border-width-root;
    background-color: map-get($custom-colors, "grey");
    margin: 0 0.5rem;
  }
}
</style>
