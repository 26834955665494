<!-- A subcomponent of AddForm that handles the 'Basisdaten'
  The fields managed by this component are (oral and written) versions,
  exam center, contact person, exam subject and KTR-ID.

  It handles all communication with the institutverwaltung, pruefungsfachverwaltung
  and lizenzverwaltung, loading institue and exam center data, exam subjects and
  relevant licences as needed.

  BasicData implements v-model and uses this model for nearly all communication
  with its parent component. This decouples the two components and allows them to
  be tested independently
 -->

<template>
  <div>
    <v-card
      class="mb-8"
      flat
    >
      <v-card-title>
        <span>{{ $t('page.create.fields.basicData.headline') }}</span>
      </v-card-title>
      <v-card-text class="bg-grey">
        <v-row>
          <v-col
            v-if="isTelcUser"
            cols="12"
          >
            <v-text-field
              v-model="basicDataFields.fields.version"
              :label="$t('page.create.fields.basicData.version.label')"
              @change="emitValues"
            />
          </v-col>
          <v-col
            v-if="isTelcUser"
            cols="12"
          >
            <div
              v-for="(_ ,i) in basicDataFields.fields.oral_versions"
              :key="i"
            >
              <v-text-field
                v-model="basicDataFields.fields.oral_versions[i]"
                :label="$t('page.create.fields.basicData.oralVersion.label', {orderNumber: i + 1})"
              />
            </div>
          </v-col>
          <v-col
            v-if="eiUuid === ''"
            cols="12"
          >
            <v-autocomplete
              v-model="basicDataFields.fields.examinationInstitute"
              :items="instituteItems"
              :label="$t('page.create.fields.basicData.institute.label')"
              item-text="name"
              return-object
              :error-messages="isEmptyRuleBasic(basicDataFields.fields.examinationInstitute)"
              required
              @change="emitValues"
            />
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="basicDataFields.fields.examinationCenter"
              :items="centerItems"
              :readonly="ecUuid !== ''"
              :label="$t('page.create.fields.basicData.examCenter.label')"
              item-text="select"
              item-value="extRef"
              return-object
              :error-messages="isEmptyRuleBasic(basicDataFields.fields.examinationCenter)"
              required
              @change="emitValues"
            />
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="basicDataFields.fields.contactPerson"
              :items="allContacts"
              item-text="label"
              item-value="uuid"
              return-object
              class="apModel"
              :label="$t('page.create.fields.basicData.examContactPerson.label')"
              hide-selected
              :error-messages="isEmptyRuleBasic(basicDataFields.fields.contactPerson)"
              required
              @change="onContactPersonChange"
            >
              <template #append>
                <v-tooltip
                  top
                  max-width="400px"
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      $vuetify.icons.info
                    </v-icon>
                  </template>
                  <span
                    v-html="$t('page.create.fields.basicData.examContactPerson.tooltip')"
                  />
                </v-tooltip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="basicDataFields.fields.examSubject.level"
              :items="examLevels"
              :label="$t('page.create.fields.basicData.examLevel.label')"
              hide-selected
              :error-messages="isEmptyRuleBasic(basicDataFields.fields.examSubject.level)"
              required
              @change="onLevelChange"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="basicDataFields.fields.examSubject.id"
              :items="fachItems"
              :label="$t('page.create.fields.basicData.examSubject.label')"
              class="fachModel"
              :error-messages="isEmptyRuleBasic(basicDataFields.fields.examSubject.id)"
              hide-selected
              @change="onExamSubjectIdChange"
            >
              <template #append>
                <v-tooltip
                  top
                  max-width="400px"
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      $vuetify.icons.info
                    </v-icon>
                  </template>
                  <span v-html="$t('page.create.fields.basicData.examSubject.tooltip')" />
                </v-tooltip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-if="isDtz"
              v-model="basicDataFields.fields.examSubject.examType"
              :items="examTypeItems"
              :label="$t('page.create.fields.basicData.courseType.label')"
              :error-messages="isEmptyRuleBasicDtz(basicDataFields.fields.examSubject.examType)"
              hide-selected
              @change="emitValues"
            />
          </v-col>
          <v-col
            id="ktr-id-field-col"
            cols="12"
            md="6"
          >
            <v-text-field
              v-if="isDtz"
              id="ktr-id-field"
              v-model="actualKtrID"
              type="text"
              :disabled="!basicDataFields.additionalData.editKtrID"
              :outlined="!basicDataFields.additionalData.editKtrID"
              :label="$t('page.create.fields.basicData.courseProviderNumber.label')"
              @blur="onKtrIDFocusLoss"
              @change="emitValues"
            >
              <!-- ktrID edit button -->
              <template #append>
                <v-tooltip
                  v-if="isDtz && !basicDataFields.additionalData.editKtrID && userHasAnyRole('pv_admin')"
                  top
                  max-width="400px"
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      id="ktr-id-edit-button"
                      color="primary"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="makeKtrIDEditable"
                    >
                      $vuetify.icons.editAction
                    </v-icon>
                  </template>
                  <span
                    v-html="$t('page.create.fields.basicData.courseProviderNumber.edit')"
                  />
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          />

          <v-col
            v-if="showCheckboxDigitalExam"
            md="1"
          >
            <v-checkbox
              id="digital-exam-checkbox"
              v-model="confirmationDigitalExam"
              required
              :error="isEmptyRuleCheckbox(confirmationDigitalExam)"
              @change="emitValues"
            />
          </v-col>
          <v-col
            v-if="showCheckboxDigitalExam"
          >
            <p
              md="11"
              class="mb-0"
              v-html="$t('page.create.fields.basicData.confirmationDigitalExam.label')"
            />
            <p
              v-if=" isEmptyRuleCheckbox(confirmationDigitalExam)"
              md="11"
              class="mb-0 checkbox-error-message"
            >
              {{ $t('page.create.messages.requiredFieldBasic') }}
            </p>
          </v-col>
          <v-col
            v-if="userHasAnyRole('pv_admin')"
            cols="12"
          >
            <v-divider />
            <v-switch
              id="ignore-licences-switch"
              v-model="ignoreLicences"
              :label="$t('page.create.fields.basicData.ignoreLicences.label')"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Add Contact Person Dialog -->
    <pv-add-contact-person-dialog
      :organization="basicDataFields.fields.examinationCenter"
      @add="onAdd"
      @hide="basicDataFields.fields.contactPerson = ''"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import pvAddContactPersonDialog from '@/components/dialogs/AddContactPerson';
import MarkInactiveMixin from '@/mixins/MarkInactive.vue';
import licenceTypes from '../data/licenceTypes';
import { isDigitalSubject } from '@/utils/exam';

export default {
  components: {
    pvAddContactPersonDialog
  },

  mixins: [MarkInactiveMixin],

  inject: [
    'institutverwaltung',
    'pruefungsfachverwaltung',
    'lizenzverwaltung',
    'config'
  ],

  props: {
    showErrors: {
      default: false,
      type: Boolean
    },
    value: {
      type: Object,
      required: true
    },
    originalExamSubjectId: {
      type: Number || null,
      default: null
    }
  },

  data() {
    return {
      basicDataFields: this.value,

      licences: null,

      // Dropdown data
      instituteItems: [],
      examTypeItems: [],
      centerItems: [],

      ignoreLicencesValue: false,
      licencesInfo: [],
      courses: [],
      institutePulled: false, // used to only pull the institute data once

      confirmationDigitalExam: false
    };
  },

  computed: {
    eiUuid() {
      return localStorage.getItem('eiUuid') || this.$route.query.institute;
    },

    editMode() {
      return !!this.$route.params.id;
    },

    examinationCenters() {
      return this.basicDataFields.fields.examinationInstitute ? this.basicDataFields.fields.examinationInstitute.examinationCenters : [];
    },

    isDtz() {
      return this.basicDataFields.fields.examSubject.id === 1010;
    },

    isDigitalExam() {
      return this.format === 'digital';
    },

    format() {
      return this.courses ? this.courses.find((item) => item.id === this.basicDataFields.fields.examSubject.id)?.format : '';
    },

    isValid() {
      const {
        examinationInstitute, examinationCenter, contactPerson
      } = this.basicDataFields.fields;

      return [examinationInstitute, examinationCenter, contactPerson].every((x) => !!x) && !!this.examSubjectValid;
    },

    examSubjectValid() {
      const { level, id, examType } = this.basicDataFields.fields.examSubject;
      if (!level || !id) return false;
      if (this.isDigitalExam && this.showCheckboxDigitalExam && !this.confirmationDigitalExam) return false;
      return !this.isDtz || !!examType;
    },

    licence() {
      const subjectId = this.basicDataFields.fields.examSubject.id;
      const licence = subjectId
        ? this.fachItems.find((item) => item.value === subjectId)
        : null;
      return licence;
    },

    /**
     * Computes the effective KTR-ID of the exam, i.e. the one that will be associated
     * with the exam if it is transmitted.
     *
     * In order to use this as a v-model, a setter is implemented, such that when the
     * user types in an override, the overrideKtrID changes.
     */
    actualKtrID: {
      get() {
        /* If no exam center is set, we cannot calculate a correct ktrID */
        if (!this.basicDataFields.fields.examinationCenter) {
          return '';
        }

        /* If an override ID is set (or we are about to set one), use the override ID */
        if (this.basicDataFields.fields.overrideKtrID || this.basicDataFields.additionalData.editKtrID) {
          return this.basicDataFields.fields.overrideKtrID;
        }
        /* If the exam center is the same as the institute, use the institute's ID */
        if (this.basicDataFields.fields.examinationCenter.uuid === this.basicDataFields.fields.examinationInstitute.uuid) {
          return this.basicDataFields.fields.examinationInstitute.ktrId;
        }
        /* If the exam center has a ktr ID, use its ID */
        const { examinationCenter } = this.basicDataFields.fields;
        if (examinationCenter && examinationCenter.ktrId) {
          return examinationCenter.ktrId;
        }
        /* As a fallback, use the institute's ID anyway */
        return this.basicDataFields.fields.examinationInstitute.ktrId;
      },

      set(val) {
        if (this.basicDataFields.additionalData.editKtrID) {
          this.basicDataFields.fields.overrideKtrID = val;
        }
      }
    },

    fachItems() {
      const allExamSubjects = this.addLicenceUuid(this.extractFachItems(this.courses));
      if (!this.basicDataFields.fields.examSubject.level) {
        return allExamSubjects;
      }
      return allExamSubjects.filter((examSubject) => examSubject.level === this.basicDataFields.fields.examSubject.level);
    },

    allContacts() {
      if (!this.basicDataFields.fields.examinationCenter) return null;
      const examCenter = this.basicDataFields.fields.examinationCenter;
      let allContactsArr = [];
      if (examCenter.contacts) {
        allContactsArr = examCenter.contacts;
      }
      if (!allContactsArr.some((contact) => contact.create)) {
        allContactsArr.push({
          label: this.$t('page.contactPersons.list.openDialog'),
          create: true
        });
      }

      if (allContactsArr.length > 0) {
        allContactsArr = allContactsArr.map((contact) => {
          if (!contact.label) contact.label = `${contact.firstName} ${contact.lastName}`;
          return contact;
        });
      }
      return allContactsArr;
    },

    registrationSubjects() {
      return this.basicDataFields.fields.examinationCenter ? this.basicDataFields.fields.examinationCenter.registrationSubjects : [];
    },

    examLevels() {
      const allExamSubjects = this.addLicenceUuid(this.extractFachItems(this.courses));
      const map = new Map();
      allExamSubjects.forEach((examSubject) => map.set(examSubject.level, examSubject.level));
      if ([...map.keys()].length === 1) return [...map.keys()];
      return [...map.keys()].sort((a, b) => {
        if (a === '') {
          return false;
        }
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
    },

    ignoreLicences: {
      get() {
        return this.ignoreLicencesValue;
      },
      async set(val) {
        this.ignoreLicencesValue = val;
        if (val) {
          this.getAllLicences();
        } else if (this.basicDataFields.fields.examinationCenter?.uuid) {
          this.basicDataFields.fields.examSubject.level = null;
          this.basicDataFields.fields.examSubject.id = null;
          this.courses = this.registrationSubjects;
          await this.pullLicence(this.basicDataFields.fields.examinationCenter.uuid);
        } else {
          this.courses = [];
          this.basicDataFields.fields.examSubject.level = null;
          this.basicDataFields.fields.examSubject.id = null;
        }
      }
    },

    showCheckboxDigitalExam() {
      return this.isDigitalExam && this.userHasAnyRole('pv_customer_user', 'pv_customer_admin') && !this.alreadyCheckedDigitalExam;
    },

    originalExamSubjectFormat() {
      if (this.originalExamSubjectId) {
        return isDigitalSubject(this.originalExamSubjectId) ? 'digital' : 'physical';
      }
      return '';
    },

    alreadyCheckedDigitalExam() {
      return this.editMode && (this.originalExamSubjectFormat === 'digital');
    }
  },

  watch: {
    /* eslint-disable-next-line */
    'basicDataFields.fields.examinationCenter': async function (val) {
      await this.pullInstitute();
      this.setConfigData();
      if (val) {
        if (!(this.basicDataFields.fields.examinationCenter.addresses && this.basicDataFields.fields.examinationCenter.contacts)) {
          this.basicDataFields.fields.examinationCenter = this.centerItems.find((x) => x.uuid === val.uuid);
        }
        if (this.ignoreLicences) {
          await this.getAllLicences();
        } else {
          this.courses = this.registrationSubjects;
          await this.pullLicence(val.uuid);
        }
        if (this.editMode && this.userHasAnyRole('pv_admin')) {
          let hasLicence = false;
          this.licencesInfo?.forEach((licenceInfo) => {
            if (licenceInfo.examSubjects.includes(this.basicDataFields.fields.examSubject.id)) {
              hasLicence = true;
            }
          });
          if (!hasLicence) {
            this.ignoreLicences = true;
          }
        }
      }
    }
  },

  async created() {
    await this.pullInstitute();
    if (this.ecUuid !== '') {
      this.basicDataFields.fields.examinationCenter = this.examinationCenters.find((x) => x.uuid === this.ecUuid);
    }
  },

  methods: {
    ...mapMutations('notice', {
      addNotice: 'add',
      clearNotices: 'clear'
    }),

    ...mapActions('dialogs', {
      showAddDialog: 'SHOW_ADD'
    }),

    async pullInstitute() {
      // only pull the institute once
      if (this.institutePulled) {
        return Promise.resolve();
      }
      return this.institutverwaltung.getInstitute(this.eiUuid, true).then((response) => {
        this.basicDataFields.fields.examinationInstitute = response.data.institute;
        this.setExaminationCentersDropdown();
        this.buildLicenceObject();
        this.institutePulled = true;
      });
    },

    setConfigData() {
      this.config.getConfigs().then(({ data }) => {
        this.examTypeItems = data.dtz;
      });
    },

    buildLicenceObject() {
      const licences = {};

      const validInstituteLicences = this.basicDataFields.fields.examinationInstitute.instLicences.filter((x) => moment().isBetween(x.dateStart, x.dateEnd));
      const validGroupInstituteLicences = validInstituteLicences?.filter((licence) => licence.quantityType === 'GRP');

      // map instituteUUID with all valid institute licences
      licences[this.basicDataFields.fields.examinationInstitute.uuid] = validInstituteLicences;

      // deals with inherited licences
      this.basicDataFields.fields.examinationInstitute.examinationCenters.forEach((examCenter) => {
        const validExamCenterLicences = examCenter.instLicences?.length ? examCenter.instLicences.filter((x) => moment().isBetween(x.dateStart, x.dateEnd)) : [];

        // if valid group licence(s) exist(s)
        if (validGroupInstituteLicences.length) {
          if (!examCenter.instLicences || examCenter.instLicences.length === 0) {
            /*
             * if exam center does not have its own licences, then
             * map examCenterUUID with all valid GROUP institute licences
             */
            licences[examCenter.uuid] = validGroupInstituteLicences;
          } else {
            /*
             * if exam center has its own licences, then
             * map examCenterUUID with
             *  - all valid exam center licences AND
             *  - valid group institute licences (only if exam center does not have its own valid single licence with the same id)
             */
            const uniqueValidGroupInstituteLicences = validGroupInstituteLicences.filter((x) => !validExamCenterLicences.some((y) => y.id === x.id));
            licences[examCenter.uuid] = [...validExamCenterLicences, ...uniqueValidGroupInstituteLicences];
          }
        /*
         * if there is no valid group licences
         * map examCenterUUID with all valid exam center licences
         */
        } else if (examCenter.instLicences) {
          licences[examCenter.uuid] = validExamCenterLicences;
        }
      });

      this.licences = licences;
    },

    async pullLicence(uuid) {
      this.licencesInfo = [];
      const licenceCallbacks = [];

      this.licences[uuid]?.forEach((id) => {
        const licenceId = id.uuid || id.id; // Smooth switch from id to uuid

        licenceCallbacks.push(this.lizenzverwaltung.getLicence(licenceId).then(({ data: { licence } }) => {
          this.licencesInfo.push(licence);
        }));
      });

      await Promise.all(licenceCallbacks);
      this.emitValues();
    },

    async getAllLicences() {
      this.licencesInfo = [];
      const licenceCallbacks = [];

      licenceTypes.forEach((licenceType) => {
        licenceCallbacks.push(this.lizenzverwaltung.getLicence(licenceType).then(({ data: { licence } }) => {
          this.licencesInfo.push(licence);
        }));
      });

      await Promise.all(licenceCallbacks);
      await this.pullCourses();
      this.emitValues();
    },

    async pullCourses() {
      return this.pruefungsfachverwaltung.getCourses().then((response) => {
        this.courses = response.data.examSubjects;
      });
    },

    extractFachItems(courses) {
      let temp = [];
      courses?.forEach((data) => {
        temp = temp.concat([
          {
            text: data.title,
            value: data.id,
            level: data.level,
            language: data.language,
            title: data.title
          }
        ]);
      });
      return temp;
    },

    addLicenceUuid(fachItems) {
      const out = [];
      this.licencesInfo.forEach((licence) => {
        // fachItems.filter((fachItem) => licence.examSubjects.includes(fachItem));
        out.push(fachItems.filter((fachItem) => licence.examSubjects.some((x) => x === fachItem.value)).map((fachItem) => (
          {
            text: fachItem.text,
            value: fachItem.value,
            level: fachItem.level,
            licence: licence.id,
            limitSet: licence.limitSet
          }
        )));
      });
      return out.flat();
    },

    currentDateIsInBetween(from, until) {
      if (!from) return true;
      return until ? moment().isBetween(from, until) : moment(from).isBefore();
    },

    setExaminationCentersDropdown() {
      this.sortArray(this.examinationCenters, 'name');

      this.examinationCenters.forEach((examinationCenter) => {
        examinationCenter.select = this.maybeInactiveSelectOption(examinationCenter);
      });
      if (this.ecUuid === '') { // Institute
        const headerInstitute = { header: this.$t('page.list.filters.institute.label') };
        const institute = {
          name: this.basicDataFields.fields.examinationInstitute.name,
          select: this.basicDataFields.fields.examinationInstitute.name,
          uuid: this.basicDataFields.fields.examinationInstitute.uuid,
          extRef: this.basicDataFields.fields.examinationInstitute.extRef,
          addresses: this.basicDataFields.fields.examinationInstitute.addresses,
          contacts: this.basicDataFields.fields.examinationInstitute.contacts,
          registrationSubjects: this.basicDataFields.fields.examinationInstitute.registrationSubjects
        };
        const headerExaminationCenter = { header: this.$t('page.list.filters.examCenter.label') };
        if (this.examinationCenters.length > 0) {
          this.centerItems = [
            headerInstitute,
            institute,
            headerExaminationCenter,
            ...this.examinationCenters
          ];
        } else {
          this.centerItems = [
            headerInstitute,
            institute
          ];
        }
      } else { // ExamCenter
        this.centerItems = this.examinationCenters;
      }
    },

    sortArray(arr, sortBy = 'name') {
      arr.sort((a, b) => {
        if (a[sortBy] === '') {
          return false;
        }
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        return 0;
      });
    },

    addContact(contact) {
      const successCallback = (result) => {
        this.addNotice({
          message: this.$t('page.contactPersons.list.notices.addSuccess'),
          type: 'success'
        });
        let addedContact;
        if (this.basicDataFields.fields.examinationCenter.uuid === this.basicDataFields.fields.examinationInstitute.uuid) {
          addedContact = result.data.institute.contacts.find((other) => this.contactsEqual(contact, other));
        } else {
          const examinationCenter = result.data.institute.examinationCenters.find((obj) => obj.uuid === this.basicDataFields.fields.examinationCenter.uuid);
          addedContact = examinationCenter.contacts.find((other) => this.contactsEqual(contact, other));
        }
        const examCenter = this.basicDataFields.fields.examinationCenter;
        examCenter.contacts.unshift(addedContact);
        this.basicDataFields.fields.contactPerson = addedContact;
      };
      if (this.basicDataFields.fields.examinationCenter.uuid !== this.basicDataFields.fields.examinationInstitute.uuid) {
        // Examination center contact
        this.institutverwaltung.createExaminationCenterContact(
          this.basicDataFields.fields.examinationInstitute.uuid,
          this.basicDataFields.fields.examinationCenter.uuid,
          contact
        ).then(successCallback);
      } else if (this.basicDataFields.fields.examinationInstitute.uuid) {
        // Examination institute contact
        this.institutverwaltung.createInstituteContact(
          this.basicDataFields.fields.examinationInstitute.uuid,
          contact
        ).then(successCallback);
      } else {
        this.addNotice({
          message: this.$t('page.contactPersons.list.notices.transferError'),
          type: 'error'
        });
      }
    },

    contactsEqual(c1, c2) {
      return ['email', 'firstName', 'lastName'].every((key) => c1[key] === c2[key]);
    },

    checkCreateContact(selection) {
      if (selection.create) {
        this.showAddDialog();
      }
    },

    onContactPersonChange(event) {
      this.checkCreateContact(event);
      this.emitValues();
    },

    makeKtrIDEditable() {
      this.basicDataFields.fields.overrideKtrID = this.actualKtrID;
      this.basicDataFields.additionalData.editKtrID = true;
    },

    /** Upon leaving the KtrId field empty, restore the original state */
    onKtrIDFocusLoss() {
      if (!this.basicDataFields.fields.overrideKtrID) {
        this.basicDataFields.additionalData.editKtrID = false;
      }
    },

    onAdd(contact) {
      this.addContact(contact);
    },

    isEmptyRuleBasic(model) {
      return (model || !this.showErrors) ? [] : [this.$t('page.create.messages.requiredFieldBasic')];
    },

    isEmptyRuleBasicDtz(model) {
      if (this.isDtz) {
        return (model || !this.showErrors) ? [] : this.$t('page.create.messages.requiredFieldBasic');
      }

      return [];
    },

    isEmptyRuleCheckbox(model) {
      return (!model && this.showErrors);
    },

    emitValues() {
      if (this.editMode && this.basicDataFields.fields.examSubject.id) {
        const subject = this.courses.find((x) => x.id === this.basicDataFields.fields.examSubject.id);
        this.basicDataFields.fields.examSubject.allowedDates = subject?.allowedDates;
      }
      this.basicDataFields.additionalData.licence = this.licence;
      this.$emit('input', { ...this.basicDataFields, isValid: this.isValid });
    },

    onLevelChange() {
      this.basicDataFields.fields.examSubject.id = '';
      this.basicDataFields.fields.examSubject.examType = '';
      this.basicDataFields.fields.examSubject.title = '';
      this.basicDataFields.fields.examSubject.language = '';
      this.basicDataFields.fields.examSubject.allowedDates = [];
      this.basicDataFields.fields.examSubject.format = null;
      this.emitValues();
    },

    onExamSubjectIdChange(newSubjectId) {
      const course = this.courses.find((c) => c.id === newSubjectId);
      this.basicDataFields.fields.examSubject.title = course.title;
      this.basicDataFields.fields.examSubject.language = course.language;
      this.basicDataFields.fields.examSubject.allowedDates = course.allowedDates;
      this.basicDataFields.fields.examSubject.level = course.level;
      this.basicDataFields.fields.examSubject.format = course.format;
      this.basicDataFields.additionalData.maxOralAttendees = course.maxAttendees;
      this.emitValues();
    }
  }
};
</script>

<style lang="scss" scoped>
  /*
   * This hack enables the edit icon for the KTR-ID even if the
   * field it's appended to is disabled
   */
  #ktr-id-field-col .v-input--is-disabled .v-icon {
    pointer-events: auto;
}

.checkbox-error-message {
  color: map-get($custom-colors, "error") !important;
  font-size: 0.8rem;
}

</style>
