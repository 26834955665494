<template>
  <v-dialog
    v-model="dialogs.importSurpass"
    max-width="500px"
    @click:outside="hideDialog"
    @keydown.enter="importData"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t('page.importSurpassDialog.header') }}</span>
      </v-card-title>
      <v-card-text>
        <v-container
          v-if="!importFinished"
          fluid
          class="pa-0"
        >
          <p class="text-subtitle-1">
            {{ $t('page.importSurpassDialog.instructions') }}
          </p>

          <v-img
            src="@/assets/import-illustration.svg"
            max-width="300px"
            class="mx-auto my-5"
            alt="Spreadsheet"
          />

          <v-divider />
          <v-form ref="form">
            <v-select
              v-model="formFields.examSubject"
              :items="selectableCourses"
              :item-text="item => item.title +' - '+ item.id"
              item-value="id"
              :label="$t('page.importSurpassDialog.selectExamSubject')"
              persistent-hint
              single-line
            />

            <v-file-input
              v-model="formFields.file"
              required
              :rules="[(v) => !!v || $t('page.importSurpassDialog.fileRequired')]"
              accept=".xlsx"
              prepend-icon="$vuetify.icons.fileExcel"
              single-line
              :label="$t('page.importSurpassDialog.fileInputLabel')"
              persistent-hint
            />
          </v-form>
        </v-container>

        <v-container v-if="importFinished">
          <v-expansion-panels>
            <bulk-import-section
              color="success"
              :status-elements="resultData.successItems"
              :header-prefix="$t('page.importSurpassDialog.successAttendeesTitle')"
            />

            <bulk-import-section
              color="secondary"
              :status-elements="resultData.failureItems"
              :header-prefix="$t('page.importSurpassDialog.failureAttendeesTitle')"
            />
          </v-expansion-panels>
        </v-container>
      </v-card-text>
      <v-card-actions v-if="!uploadInProgress">
        <v-spacer />
        <v-btn
          id="cancel"
          outlined
          depressed
          @click="hideDialog"
        >
          {{ $t('page.importSurpassDialog.actions.cancel') }}
        </v-btn>
        <v-btn
          v-if="!importFinished"
          color="primary"
          type="submit"
          depressed
          @click="importData"
        >
          {{ $t('page.importSurpassDialog.actions.upload') }}
          <v-icon
            right
            x-small
          >
            $vuetify.icons.importAction
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import BulkImportSection from '@/components/BulkImportSection';

export default {
  components: { BulkImportSection },
  inject: ['pruefungsfachverwaltung', 'teilnehmerverwaltung'],
  data: () => ({
    selectableCourses: [],
    formFields: {
      file: null,
      examSubject: null
    },
    uploadInProgress: false,
    importFinished: false,
    resultData: {
      successItems: [],
      failureItems: []
    }
  }),

  computed: {
    ...mapGetters('dialogs', {
      dialogs: 'get'
    })
  },

  created() {
    this.loadAndFilterCourses();
  },

  methods: {
    ...mapActions('dialogs', {
      hideSurpassImportDialog: 'HIDE_SURPASS_IMPORT'
    }),
    ...mapMutations('notice', {
      addNotice: 'add'
    }),

    async loadAndFilterCourses() {
      this.pruefungsfachverwaltung.getCourses().then((response) => {
        this.selectableCourses = this.filterCourses(response.data.examSubjects);
      }).catch(() => {
        this.addNotice({
          message: this.$t('page.importSurpassDialog.errorFetchingExamSubjects'),
          type: 'error'
        });
      });
    },

    filterCourses(subjects) {
      const possibleUploadCoursesIDs = [1023, 1032, 1036, 1061, 8023, 8032, 8036, 8061];

      const selectableSubjects = [];

      subjects.forEach((s) => {
        if (possibleUploadCoursesIDs.includes(s.id)) {
          selectableSubjects.push(s);
        }
      });

      return selectableSubjects;
    },

    async importData() {
      const multipartFormData = this.getFormDataFromSurpassImportForm();

      this.uploadInProgress = true;

      if (this.$refs.form.validate()) {
        this.teilnehmerverwaltung.uploadSurpassExcel(multipartFormData).then((response) => {
          this.uploadInProgress = false;

          if (response.status >= 200 && response.status <= 300) {
            return response;
          }
          return Promise.reject(response);
        }).then((response) => {
          this.importFinished = true;
          this.resultData = response?.data;
        }).catch((errResponse) => {
          this.addNotice({
            message: `${this.$t('page.importSurpassDialog.errorUploadingResults')}: ${errResponse?.data?.message}`,
            type: 'error'
          });
        });
      }
    },

    getFormDataFromSurpassImportForm() {
      const formData = new FormData();

      formData.set('file', this.formFields.file);
      formData.set('exam_subject_id', this.formFields.examSubject);

      return formData;
    },

    resetFormFields() {
      this.formFields.file = null;
      this.formFields.examSubject = null;
    },

    hideDialog() {
      this.resetFormFields();
      this.hideSurpassImportDialog();
      this.importFinished = false;
    }

  }
};
</script>
