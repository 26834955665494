<template>
  <v-container
    v-if="visibleSecondaryActions.length || visiblePrimaryActions.length"
    class="c-actionbar"
  >
    <div class="c-actionbar__inner">
      <div
        v-if="visibleSecondaryActions.length"
        class="c-actionbar__secondary"
      >
        <v-btn
          v-for="(action, index) in visibleSecondaryActions"
          :key="index"
          depressed
          :outlined="!!action.outlined"
          :color="action.color"
          :dark="!!action.dark"
          :loading="!!action.loading"
          :disabled="!!action.disabled"
          @click.stop="emit(action.event)"
        >
          {{ action.title }}
        </v-btn>
      </div>
      <div
        v-if="visiblePrimaryActions.length"
        class="c-actionbar__primary"
      >
        <v-btn
          v-for="(action, index) in visiblePrimaryActions"
          :key="index"
          depressed
          :outlined="!!action.outlined"
          :color="action.color"
          :dark="!!action.dark"
          :loading="!!action.loading"
          :disabled="!!action.disabled"
          @click.stop="emit(action.event)"
        >
          {{ action.title }} <v-icon
            v-if="action.icon"
            right
            x-small
          >
            {{ action.icon }}
          </v-icon>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    secondaryActions: {
      type: Array,
      default: () => []
    },
    primaryActions: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    /**
     * @returns {Object[]}
     */
    visibleSecondaryActions() {
      return this.secondaryActions.filter(({ hidden }) => !hidden);
    },

    /**
     * @returns {Object[]}
     */
    visiblePrimaryActions() {
      return this.primaryActions.filter(({ hidden }) => !hidden);
    }
  },

  mounted() {
    if (this.visibleSecondaryActions.length || this.visiblePrimaryActions.length) {
      document.body.classList.add('has-actionbar');
    }
  },

  destroyed() {
    document.body.classList.remove('has-actionbar');
  },

  methods: {
    /**
     * @param {string} event
     * @returns {void}
     */
    emit(event) {
      if (event) this.$emit(event, true);
    }
  }
};
</script>

<style lang="scss">
.c-actionbar {
  @include media-breakpoint-up("sm") {
    position: fixed;
    bottom: 1.5rem;
  }
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;

  &__inner {
    @include media-breakpoint-up("sm") {
      margin: 0 12px;
      padding-bottom: 0.75rem;
    }
    background-color: map-get($custom-colors, "grey");
    margin: 0 -1rem;
    padding-top: 0.75rem;
    display: flex;
    pointer-events: auto;
  }

  &__primary,
  &__secondary {
    padding: 0 0.75rem;

    .v-btn {
      @include media-breakpoint-down("sm") {
        font-size: 0.7rem;
        margin-bottom: 0.75rem;
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  &__secondary + &__primary {
    @include media-breakpoint-up("sm") {
      border-left: $border-width-root solid rgba(map-get($custom-colors, "white"), 0.25);
    }
  }
}
</style>
