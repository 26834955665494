<template>
  <v-app id="pruefungsverwaltung">
    <pv-meta-navigation />
    <pv-header />
    <info-banner v-if="isBlockedInstitute" />
    <template v-if="!permissionDenied">
      <router-view />
    </template>
    <template v-if="permissionDenied && !isLoading">
      <v-container>
        <p class="d-flex flex-column align-center text-center text-body-1 font-weight-medium my-10">
          <v-icon
            class="mb-4"
            color="error"
            x-large
          >
            $vuetify.icons.error
          </v-icon>
          {{ $t('page.errors.permissionDenied') }}
        </p>
      </v-container>
    </template>
    <pv-scroll-to-top />
  </v-app>
</template>

<script>
import { getCurrentLocale } from '@/utils/general';
import { getInstitute } from '@/services/institutverwaltung';
import pvMetaNavigation from '@/views/MetaNavigation';
import pvHeader from '@/views/Header';
import pvScrollToTop from '@/components/ScrollToTop';
import infoBanner from '@/components/InfoBanner';

export default {
  name: 'Pruefungsverwaltung',

  components: {
    pvMetaNavigation,
    pvHeader,
    pvScrollToTop,
    infoBanner
  },

  data: () => ({
    routes: {
      pruefungsliste: 'examList',
      anmelden: 'examList',
      bearbeiten: 'examList',
      nachmelden: 'examList',
      ansicht: 'examList',
      ruecklauf: 'examList'
    },

    permissionDenied: true,
    isLoading: true,
    isBlockedInstitute: false
  }),

  async created() {
    document.documentElement.setAttribute('lang', getCurrentLocale());
    this.getAccess();

    if (this.eiUuid) {
      const { data: { institute } } = await getInstitute(this.eiUuid);

      if (this.ecUuid !== '') {
        const examinationCenter = institute.examinationCenters.find((ec) => ec.uuid === this.ecUuid);
        if (examinationCenter.isBlocked) {
          this.isBlockedInstitute = true;
        }
      } else if (institute.isBlocked) {
        this.isBlockedInstitute = true;
      }

      localStorage.setItem('has-examination-centers', institute.examinationCenters?.length > 0);
    } else {
      localStorage.setItem('has-examination-centers', false);
    }

    // Dispatch a custom event that watches for a change to the 'has-examination-centers'
    // key in localStorage. This event is being listened for in the Frame-UI component.
    let event;
    if (typeof window.CustomEvent === 'function') {
      event = new CustomEvent('has-examination-centers-changed', {
        // eslint-disable-next-line
        detail: localStorage.getItem('has-examination-centers')
      });
    } else {
      // Provide fallback method for IE11
      event = document.createEvent('CustomEvent');
      event.initCustomEvent('has-examination-centers-changed', false, false, localStorage.getItem('has-examination-centers'));
    }
    window.dispatchEvent(event);
  },
  methods: {
    getAccess() {
      this.$root.$on('headerData', (permissions) => {
        let urlPath = this.$route.path.substring(1);
        // Fallback if route and path is empty
        if (this.$route.path.substring(1) === '') urlPath = 'pruefungsliste';
        const routePath = this.routes[urlPath];
        this.permissionDenied = permissions !== undefined ? Boolean(permissions.forbidden.find((x) => x === routePath)) : false;
        this.isLoading = false;
      });
    }
  }
};
</script>

<style lang="scss">
@font-face {
  font-family: 'FiraSans';
  src: url('assets/fonts/FiraSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'FiraSans';
  src: url('assets/fonts/FiraSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'FiraSans';
  src: url('assets/fonts/FiraSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-display: swap;
  font-style: normal;
}

[v-cloak] {
  display: none
}

.bg-grey {
  background-color: map-get($custom-colors, "lightgrey") !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.v-main__wrap {
  @at-root .has-actionbar & {
    @include media-breakpoint-up("sm") {
      padding-bottom: 8rem;
    }
  }
}

.theme--light.v-label {
  color: map-get($custom-colors, "black") !important;
}

.v-input--centered input {
  text-align: center !important;
}

.v-input:not(.v-input--radio-group) .v-label,
.v-input--radio-group__input > .v-label {
  font-weight: 500;
  max-width: 700px;
}

.v-input--radio-group--row .v-input--radio-group__input > .v-label {
  padding-right: 20px !important;
}

.v-input__icon i,
.v-input__icon .v-icon {
  font-size: 1rem !important;
  color: map-get($custom-colors, "red") !important;
}

.v-text-field__details {
  padding-left: 0 !important;
}

.v-application--is-ltr .v-input__prepend-outer {
  margin-right: 15px;
}

.theme--light.v-card .v-card {
  &__title {
    background-color: map-get($custom-colors, "red");
    color: map-get($custom-colors, "white");
  }

  &__text {
    background-color: map-get($custom-colors, "white");
    padding: 24px !important;

    .v-input:not(:last-child):not(:first-child) {
      padding-bottom: 0.5rem;
    }
  }

  &__actions {
    background-color: map-get($custom-colors, "lightgrey");
    padding: 16px 24px;

    .v-btn .v-icon {
      margin-right: 0 !important;
    }
  }
}

.theme--light.v-expansion-panels .v-expansion-panel {
  margin-bottom: 1rem;

  &-header {
    background-color: map-get($custom-colors, "red");
    color: map-get($custom-colors, "white") !important;

    &__icon .v-icon {
      color: map-get($custom-colors, "white") !important;
      font-size: 1rem;
    }

    small {
      font-weight: 500;
    }
  }

  &-content__wrap {
    padding: 24px;
    background-color: map-get($custom-colors, "lightgrey");
  }
}

.v-tooltip__content {
  font-weight: 500 !important;
  line-height: 1.5em !important;
  padding: 13px 16px !important;
}
</style>
