<template>
  <v-row class="align-end justify-space-between">
    <v-col :cols="columnWidth">
      <v-menu
        v-model="show"
        :close-on-content-click="false"
        transition="scale-transition"
        bottom
        offset-y
        offset-overflow
        max-width="300px"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            v-model="textFieldContent"
            class="editable-text-field"
            :prepend-icon="icon"
            :placeholder="$t('page.list.filters.examDate.dateFormat')"
            clearable
            :hide-details="false"
            v-bind="{...textFieldProps, ...attrs}"
            :error="!lastTextInputValid"
            :rules="[dateValidation]"
            :disabled="readonly"
            v-on="on"
            @click:clear="clear"
            @blur="handleTextInput"
            @keyup.enter="show = false; handleTextInput();"
          >
            <template slot="label">
              {{ label }}
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="currentDate"
          class="main-date-picker"
          color="primary"
          first-day-of-week="1"
          :min="minDate"
          :max="maxDate"
          :show-current="showCurrent"
          no-title
          scrollable
          full-width
        />
      </v-menu>
    </v-col>
    <v-col
      cols="2"
    >
      <v-tooltip
        v-if="tooltip"
        right
        max-width="400px"
      >
        <template #activator="{on, attrs}">
          <v-icon
            color="primary"
            v-bind="attrs"
            v-on="on"
          >
            $vuetify.icons.info
          </v-icon>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment';

const availableDateFormats = ['DD.MM.YYYY', 'D.MM.YYYY', 'D.M.YYYY', 'DD.M.YYYY'];
function isoToGermanDateFormat(date) {
  return date ? moment(date, moment.ISO_8601, false).format('DD.MM.YYYY') : null;
}

export default {
  props: {
    value: {
      type: String,
      required: true
    },
    textFieldProps: {
      type: Object,
      default: () => ({})
    },
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    minDate: {
      type: String,
      default: undefined
    },
    maxDate: {
      type: String,
      default: undefined
    },
    showCurrent: {
      type: [String, Boolean],
      default: true
    },
    tooltip: {
      type: String,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      lastTextInputValid: true,
      confirmedTextFieldContent: isoToGermanDateFormat(this.value),
      show: false
    };
  },

  computed: {
    columnWidth() {
      return this.tooltip ? 9 : 12;
    },

    dateValidation() {
      return this.lastTextInputValid ? true : this.$t('page.list.filters.examDate.dateFormatErrorMessage');
    },

    currentDate: {
      get() {
        return this.value;
      },
      set(val) {
        this.show = false;
        this.confirmedTextFieldContent = isoToGermanDateFormat(val);
        this.lastTextInputValid = true;
        this.$emit('input', val);
      }
    },

    textFieldContent: {
      get() {
        return isoToGermanDateFormat(this.value) ?? '';
      },

      set(val) {
        this.confirmedTextFieldContent = val;
      }
    }
  },

  methods: {
    handleTextInput() {
      const parsedDate = moment(this.confirmedTextFieldContent, availableDateFormats, true);

      if (this.confirmedTextFieldContent === '') {
        this.clear();
        return;
      }

      if (parsedDate.isValid()) {
        this.lastTextInputValid = true;
        this.$emit('input', parsedDate.format('YYYY-MM-DD'));
      } else {
        this.lastTextInputValid = this.confirmedTextFieldContent === null;
      }
    },

    clear() {
      this.lastTextInputValid = true;
      this.$emit('input', null);
    }
  }
};
</script>
