<template>
  <div>
    <v-container>
      <pv-hero
        ref="hero"
        :title="$t('page.lateRegistration.title')"
      />
    </v-container>
    <v-main>
      <v-container>
        <v-form ref="form">
          <v-row>
            <v-col
              cols="12"
              md="8"
            >
              <!-- Written Groups -->
              <v-card
                class="mb-8"
                flat
              >
                <v-card-title>
                  <v-icon
                    color="white"
                    class="mr-3"
                    small
                  >
                    $vuetify.icons.writingGroup
                  </v-icon>
                  <span>{{ $t('page.late.writingExamGroups.title') }}</span>
                </v-card-title>
                <v-card-text class="bg-grey">
                  <v-row
                    v-for="(group,i) in formFields.writingExamGroups"
                    :key="i + 'written'"
                  >
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        v-model.number="group.groupSize"
                        class="v-input--centered font-weight-bold"
                        type="number"
                        readonly
                        min="0"
                        outlined
                        hide-details="auto"
                        :label="`${$t('page.create.fields.attendeesAndDates.writtenGroups.group')} ${i + 1}`"
                      >
                        <template #prepend-inner>
                          <v-btn
                            id="decrease-writing-group-size"
                            color="primary"
                            depressed
                            small
                            :disabled="isMinWritingGroupSize()"
                            @click="group.groupSize = group.groupSize - 1; watchSize(formFields.writingExamGroups, i, group.groupSize, 'writing')"
                          >
                            <v-icon x-small>
                              $vuetify.icons.minus
                            </v-icon>
                          </v-btn>
                        </template>
                        <template #append>
                          <v-btn
                            id="increase-writing-group-size"
                            color="primary"
                            depressed
                            small
                            :disabled="isMaxWritingGroupSize(group.groupSize)"
                            @click="group.groupSize = group.groupSize + 1; watchSize(formFields.writingExamGroups, i, group.groupSize, 'writing')"
                          >
                            <v-icon x-small>
                              $vuetify.icons.plus
                            </v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        v-model.number="group.numberPartialExams"
                        class="v-input--centered font-weight-bold"
                        type="number"
                        readonly
                        min="0"
                        outlined
                        hide-details="auto"
                        :label="`${$t('page.create.fields.attendeesAndDates.partialGroupSize')}`"
                      >
                        <template #prepend-inner>
                          <v-btn
                            id="decrease-partial-exams-writing-group-size"
                            color="primary"
                            depressed
                            small
                            :disabled="group.numberPartialExams <= 0"
                            @click="group.numberPartialExams = group.numberPartialExams - 1;"
                          >
                            <v-icon x-small>
                              $vuetify.icons.minus
                            </v-icon>
                          </v-btn>
                        </template>
                        <template #append>
                          <v-btn
                            id="increase-partial-exams-writing-group-size"
                            color="primary"
                            depressed
                            small
                            :disabled="group.numberPartialExams >= group.groupSize"
                            @click="group.numberPartialExams = group.numberPartialExams + 1;"
                          >
                            <v-icon x-small>
                              $vuetify.icons.plus
                            </v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="1"
                      align-self="center"
                    >
                      <v-btn
                        v-if="i >= minWritingGroups"
                        id="remove-writing-group"
                        depressed
                        color="error"
                        fab
                        right
                        x-small
                        @click="removeGroup(formFields.writingExamGroups,i)"
                      >
                        <v-icon x-small>
                          $vuetify.icons.delete
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                        id="add-writing-group"
                        small
                        dark
                        depressed
                        @click="addGroup(formFields.writingExamGroups)"
                      >
                        {{ $t('page.create.fields.attendeesAndDates.writtenGroups.addGroup') }}
                        <v-icon
                          right
                          x-small
                        >
                          $vuetify.icons.addAction
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row class="mb-5">
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        v-model="allWrittenParticipants"
                        :label="$t('page.create.fields.attendeesAndDates.writtenGroups.totalAttendees')"
                        dense
                        type="number"
                        hide-details="auto"
                        outlined
                        readonly
                      />
                    </v-col>
                    <v-col
                      cols="10"
                      md="5"
                    >
                      <v-text-field
                        v-model="allPartialWrittenParticipants"
                        :label="$t('page.create.fields.attendeesAndDates.writtenGroups.totalPartialAttendees')"
                        dense
                        type="number"
                        hide-details="auto"
                        outlined
                        readonly
                      />
                    </v-col>
                    <v-col
                      cols="2"
                      align-self="center"
                    >
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                          >
                            <v-icon
                              color="primary"
                            >
                              $vuetify.icons.info
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('page.create.fields.attendeesAndDates.calculationInfo') }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Oral Groups -->
              <v-card
                class="mb-8"
                flat
              >
                <v-card-title>
                  <v-icon
                    color="white"
                    class="mr-3"
                    small
                  >
                    $vuetify.icons.oralGroup
                  </v-icon>
                  <span>{{ $t('page.late.oralExamGroups.title') }}</span>
                </v-card-title>
                <v-card-text class="bg-grey">
                  <v-row
                    v-for="(group,i) in formFields.oralExamGroups"
                    :key="i + 'written'"
                  >
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        v-model.number="group.groupSize"
                        class="v-input--centered font-weight-bold"
                        type="number"
                        readonly
                        min="0"
                        outlined
                        hide-details="auto"
                        :label="`${$t('page.create.fields.attendeesAndDates.oralGroups.group')} ${i + 1}`"
                      >
                        <template #prepend-inner>
                          <v-btn
                            id="decrease-oral-group-size"
                            color="primary"
                            depressed
                            small
                            :disabled="isMinOralGroupSize()"
                            @click="group.groupSize = group.groupSize - 1; watchSize(formFields.oralExamGroups, i, group.groupSize, 'oral')"
                          >
                            <v-icon x-small>
                              $vuetify.icons.minus
                            </v-icon>
                          </v-btn>
                        </template>
                        <template #append>
                          <v-btn
                            id="increase-oral-group-size"
                            color="primary"
                            depressed
                            small
                            :disabled="isMaxOralGroupSize(group.groupSize)"
                            @click="group.groupSize = group.groupSize + 1; watchSize(formFields.oralExamGroups, i, group.groupSize, 'oral')"
                          >
                            <v-icon x-small>
                              $vuetify.icons.plus
                            </v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        v-model.number="group.numberPartialExams"
                        class="v-input--centered font-weight-bold"
                        type="number"
                        readonly
                        min="0"
                        outlined
                        hide-details="auto"
                        :label="`${$t('page.create.fields.attendeesAndDates.partialGroupSize')}`"
                      >
                        <template #prepend-inner>
                          <v-btn
                            id="decrease-partial-exams-oral-group-size"
                            color="primary"
                            depressed
                            small
                            :disabled="group.numberPartialExams <= 0"
                            @click="group.numberPartialExams = group.numberPartialExams - 1;"
                          >
                            <v-icon x-small>
                              $vuetify.icons.minus
                            </v-icon>
                          </v-btn>
                        </template>
                        <template #append>
                          <v-btn
                            id="increase-partial-exams-oral-group-size"
                            color="primary"
                            depressed
                            small
                            :disabled="group.numberPartialExams >= group.groupSize"
                            @click="group.numberPartialExams = group.numberPartialExams + 1;"
                          >
                            <v-icon x-small>
                              $vuetify.icons.plus
                            </v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-menu
                        v-model="group.groupMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            :value="formatDate(group.examDate)"
                            prepend-icon="$vuetify.icons.startDate"
                            readonly
                            required
                            hide-details="auto"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <template slot="label">
                              {{ $t('page.create.fields.attendeesAndDates.oralGroups.dateExam') }}
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="group.examDate"
                          color="primary"
                          first-day-of-week="1"
                          no-title
                          scrollable
                          :allowed-dates="allowedDatesOralExam"
                          @input="group.groupMenu = false"
                        />
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="6"
                      md="2"
                    >
                      <v-menu
                        v-model="group.timeMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            :value="group.time"
                            prepend-icon="$vuetify.icons.clock"
                            readonly
                            hide-details="auto"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <template slot="label">
                              {{ $t('page.create.fields.attendeesAndDates.time') }}
                            </template>
                          </v-text-field>
                        </template>
                        <v-time-picker
                          v-if="group.timeMenu"
                          v-model="group.time"
                          format="24hr"
                          @click:minute="group.timeMenu = false"
                        />
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      md="1"
                      align-self="center"
                    >
                      <v-btn
                        v-if="i >= minOralGroups"
                        id="remove-oral-group"
                        depressed
                        color="error"
                        fab
                        right
                        x-small
                        @click="removeGroup(formFields.oralExamGroups,i)"
                      >
                        <v-icon x-small>
                          $vuetify.icons.delete
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                        id="add-oral-group"
                        small
                        dark
                        depressed
                        @click="addGroup(formFields.oralExamGroups, 'oral')"
                      >
                        {{ $t('page.create.fields.attendeesAndDates.oralGroups.addGroup') }}
                        <v-icon
                          right
                          x-small
                        >
                          $vuetify.icons.addAction
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        v-model="allOralParticipants"
                        :label="$t('page.create.fields.attendeesAndDates.oralGroups.totalAttendees')"
                        dense
                        type="number"
                        hide-details="auto"
                        outlined
                        readonly
                      />
                    </v-col>
                    <v-col
                      cols="10"
                      md="5"
                    >
                      <v-text-field
                        v-model="allPartialOralParticipants"
                        :label="$t('page.create.fields.attendeesAndDates.oralGroups.totalPartialAttendees')"
                        dense
                        type="number"
                        hide-details="auto"
                        outlined
                        readonly
                      />
                    </v-col>
                    <v-col
                      cols="2"
                      align-self="center"
                    >
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                          >
                            <v-icon
                              color="primary"
                            >
                              $vuetify.icons.info
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('page.create.fields.attendeesAndDates.calculationInfo') }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Overiew participants -->
              <v-card
                class="mb-8"
                flat
              >
                <v-card-title>
                  <v-icon
                    color="white"
                    class="mr-3"
                    small
                  >
                    $vuetify.icons.info
                  </v-icon>
                  <span>{{ $t('page.create.fields.attendeesAndDates.overview.headline') }}</span>
                </v-card-title>
                <v-card-text class="bg-grey">
                  <v-row>
                    <v-col
                      cols="10"
                      md="6"
                    >
                      <v-text-field
                        v-model.number="formFields.totalNumberAttendees"
                        :label="$t('page.create.fields.attendeesAndDates.individualAttendees')"
                        type="number"
                        :rules="isTotalNumberAttendeesValid"
                        dense
                        hide-details="auto"
                      />
                    </v-col>
                    <v-col
                      cols="2"
                      align-self="center"
                    >
                      <v-tooltip
                        top
                        max-width="400px"
                      >
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                          >
                            <v-icon
                              color="primary"
                            >
                              $vuetify.icons.info
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('page.create.fields.attendeesAndDates.individualAttendeesInfo') }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
        <pv-actionbar
          :secondary-actions="ui.secondaryActions"
          :primary-actions="ui.primaryActions"
          @cancel="onCancel"
          @save="onSave"
        />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import moment from 'moment';

import { totalNumberAttendeesMixin } from '@/mixins/totalNumberAttendees.js';
import pvActionbar from '@/components/Actionbar';
import pvHero from '@/components/Hero';

export default {
  name: 'Late',

  components: {
    pvActionbar,
    pvHero
  },

  mixins: [totalNumberAttendeesMixin],

  inject: [
    'institutverwaltung',
    'limitsetverwaltung',
    'pruefungsfachverwaltung',
    'lizenzverwaltung',
    'pruefungsverwaltung'
  ],
  data: (vm) => ({
    formFields: {
      totalNumberAttendees: 0,
      writingExamGroups: [{
        examDate: null,
        groupSize: 0,
        numberPartialExams: 0
      }],
      oralExamGroups: [{
        examDate: null,
        groupSize: 0,
        numberPartialExams: 0
      }]
    },
    limitSetId: null,
    minWritingAttendees: 1,
    maxWritingAttendees: 100,
    minOralAttendees: 1,
    maxOralAttendees: 100,
    minWritingGroups: 1,
    minOralGroups: 1,
    allPartialWrittenParticipants: 0,
    allPartialOralParticipants: 0,
    instituteData: null,
    licence: [],
    // validation rules
    isEmptyRuleBasic: [(v) => !!v || vm.$t('page.create.messages.requiredField')],
    isTotalNumberAttendeesValid: [
      () => (vm.isTotalNumberAttendeesInRange) || vm.$t('page.create.messages.totalNumberAttendeesOutOfRange', { min: vm.minNumberOfAllParticipants, max: vm.maxNumberOfAllParticipants })
    ]
  }),

  computed: {
    eiUuid() {
      return localStorage.getItem('eiUuid') || this.$route.query.institute;
    },
    ecUuid() {
      return localStorage.getItem('ecUuid');
    },
    ui() {
      return {
        secondaryActions: [
          {
            title: this.$t('page.create.cancel'),
            color: 'white',
            outlined: true,
            event: 'cancel'
          }
        ],
        primaryActions: [
          {
            title: this.$t('page.create.save'),
            color: 'primary',
            icon: '$vuetify.icons.saveAction',
            dark: true,
            event: 'save'
          }
        ]
      };
    }
  },

  created() {
    this.pruefungsverwaltung.getExam(this.eiUuid, this.$route.params.lateId).then(({ data: { exam } }) => {
      this.formFields = this.modifyInput(exam);
      this.debug(this.formFields);
      this.minWritingAttendees = this.attendeeSum(this.formFields.writingExamGroups);
      this.minOralAttendees = this.attendeeSum(this.formFields.oralExamGroups);
      this.minWritingGroups = this.formFields.writingExamGroups.length || 1;
      this.minOralGroups = this.formFields.oralExamGroups.length || 1;
      this.pullInstitute();
      this.pullCourse(this.formFields.examSubject.id);
    });
  },

  updated() {
    this.$nextTick(() => {
      const partialSizeCounter = ((acc, { numberPartialExams }) => {
        if (numberPartialExams) acc += +numberPartialExams;

        return acc;
      });
      this.allPartialWrittenParticipants = this.formFields.writingExamGroups.reduce(partialSizeCounter, 0);
      this.allPartialOralParticipants = this.formFields.oralExamGroups.reduce(partialSizeCounter, 0);
    });
  },

  methods: {
    ...mapMutations('notice', {
      addNotice: 'add'
    }),

    pullInstitute() {
      this.institutverwaltung.getInstitute(this.eiUuid).then((response) => {
        this.instituteData = response.data.institute;
        this.pullLicence();
        this.debug(this.instituteData);
      });
    },

    /**
     * Pull licence from service
     *
     * @returns {void}
     */
    pullLicence() {
      const licenceIds = this.instituteData.instLicences || null;
      if (licenceIds) {
        licenceIds.forEach((id) => {
          const licenceId = id.uuid || id.id; // Smooth switch from id to uuid
          this.lizenzverwaltung.getLicence(licenceId).then(({ data: { licence } }) => {
            this.licence.push(licence);
            this.limitSetId = licence.limitSet;
            this.pullLimitSet();
          });
        });
      }
    },

    /**
     * Pull limitSet from service
     *
     * @returns {void}
     */
    pullLimitSet() {
      this.limitsetverwaltung.getLimitSet(this.limitSetId).then(({ data: { limitSet } }) => {
        this.limits = limitSet.limits;
        if (moment(this.formFields.examDate).isBefore(moment().add(this.limits.registrationDeadline, 'd').format('YYYY-MM-DD')) && !this.$route.params.lateId) {
          this.formFields.oralExamGroups[0].groupDate = moment().add(this.limits.registrationDeadline, 'd').format('YYYY-MM-DD');
        }
      });
    },

    /**
     * Pull course from service
     *
     * @param {string} courseId
     * @returns {void}
     */
    pullCourse(courseId) {
      this.pruefungsfachverwaltung.getCourse(courseId).then(({ data: { examSubject } }) => {
        this.maxOralAttendees = examSubject.maxAttendees;
      });
    },

    /**
     * Check if oral exam date is valid
     *
     * @param {string} val
     * @returns {string}
     */
    allowedDatesOralExam(val) {
      return moment(val).isBetween(moment(this.formFields.examDate).subtract(1, 'd'), moment(this.formFields.examDate).add(this.limits.intervalExamDates, 'd').subtract(1, 'd'));
    },

    /**
     * Modify/Add fields of/to Exam
     *
     * @param {Object[]} fields
     * @returns {Object[]}
     */
    modifyInput(fields) {
      fields.oralExamGroups.forEach((data) => {
        data.groupMenu = false;
        data.timeMenu = false;
        data.time = moment(data.examDate).format('HH:mm');
        data.examDate = moment(data.examDate).format('YYYY-MM-DD');
      });
      return fields;
    },

    /**
     * Modify fields of Exam
     *
     * @param {Object[]} fields
     * @returns {Object[]}
     */
    modifyOutput(fields) {
      fields.oralExamGroups.forEach((data) => {
        if (moment(`${data.examDate} ${data.time}`).format() !== 'Invalid date') {
          data.examDate = moment.utc(moment(`${data.examDate} ${data.time}`).format()).format();
        } else {
          data.examDate = moment.utc(data.examDate).format();
        }
      });
      return fields;
    },

    /**
     * Adds a group
     *
     * @param {Object[]} groups
     * @param {string} type
     * @returns {void}
     */
    addGroup(groups, type = 'writing') {
      groups.push({
        examDate: type === 'oral' ? moment().add(this.limits.registrationDeadline, 'd').format('YYYY-MM-DD') : this.formFields.examDate,
        groupMenu: false,
        groupSize: 1,
        numberPartialExams: 0,
        time: '0:00',
        timeMenu: false
      });
    },

    /**
     * Removes a group
     *
     * @param {Object[]} groupType
     * @param {integer} idx
     * @returns {void}
     */
    removeGroup(groupType, idx) {
      this.updateGroup(groupType, idx);
      groupType.splice(idx, 1);
    },

    /**
     * React to groupsize change
     *
     * @returns {void}
     */
    watchSize(group, index, size, type) {
      if (type === 'writing') {
        if (size < 1 && this.minWritingGroups > index) group[index].groupSize = 1;
        if (size === 0 && this.minWritingGroups <= index) this.removeGroup(group, index);
      }
      if (type === 'oral') {
        if (size < 1 && this.minOralGroups > index) group[index].groupSize = 1;
        if (size === 0 && this.minOralGroups <= index) this.removeGroup(group, index);
      }
    },

    /**
     * React to groupsize change
     *
     * @returns {void}
     */
    watchPartialGroupSize(group, index, size, type) {
      if (type === 'writing') {
        if (size < 1 && this.minWritingGroups > index) group[index].groupSize = 1;
        if (size === 0 && this.minWritingGroups <= index) this.removeGroup(group, index);
      }
      if (type === 'oral') {
        if (size < 1 && this.minOralGroups > index) group[index].groupSize = 1;
        if (size === 0 && this.minOralGroups <= index) this.removeGroup(group, index);
      }
    },

    /**
     * Update group size
     *
     * @param {Object[]} groupType
     * @param {integer} idx
     * @returns {void}
     */
    updateGroup(groupType, idx) {
      const size = groupType[idx].groupSize;
      groupType[idx - 1].groupSize = groupType[idx - 1].groupSize + size;
    },

    /**
     * Sums up groups
     *
     * @param {Object[]} group
     * @returns {integer}
     */
    groupSum(group) {
      return group.reduce((a, b) => a + (b.groupSize || 0), 0);
    },

    /**
     * Sums up attendees
     *
     * @param {Object[]} group
     * @returns {integer}
     */
    attendeeSum(group) {
      return group.reduce((a, b) => a + (b.groupSize || 0), 0);
    },

    /**
     * Checks writing group size
     *
     * @returns {boolean}
     */
    isMinWritingGroupSize() {
      const currentSize = this.formFields.writingExamGroups.reduce((a, b) => a + (b.groupSize || 0), 0);
      return currentSize <= this.minWritingAttendees;
    },

    /**
     * Checks writing group size
     *
     * @returns {boolean}
     */
    isMaxWritingGroupSize(groupSize) {
      return groupSize >= this.maxWritingAttendees;
    },

    /**
     * Checks oral group size
     *
     * @returns {boolean}
     */
    isMinOralGroupSize() {
      const currentSize = this.formFields.oralExamGroups.reduce((a, b) => a + (b.groupSize || 0), 0);
      return currentSize <= this.minOralAttendees;
    },

    /**
     * Checks oral group size
     *
     * @param {integer} groupSize
     * @returns {boolean}
     */
    isMaxOralGroupSize(groupSize) {
      return groupSize >= this.maxOralAttendees;
    },

    /**
     * Format date
     *
     * @param {string} date
     * @returns {string}
     */
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },

    /**
     * Triggered cancel event
     *
     * @returns {void}
     */
    onCancel() {
      this.$router.push({ name: 'list' });
    },

    /**
     * Triggered save event
     *
     * @returns {void}
     */
    onSave() {
      if (this.$refs.form.validate()) {
        this.modifyOutput(this.formFields);
        this.save();
      } else {
        this.addNotice({
          message: this.$t('page.create.messages.checkDataGeneric'),
          type: 'error'
        });
      }
    },

    /**
     * Save to exam
     *
     * @returns {void}
     */
    save() {
      this.formFields.status = 'in_submission';
      this.pruefungsverwaltung.updateExam(this.eiUuid, this.$route.params.lateId, this.formFields).then((response) => {
        if (response.status === 200) {
          this.$router.push({ name: 'list' });
          this.addNotice({
            message: this.$t('page.create.messages.success'),
            type: 'success'
          });
        }
      });
    }
  }
};
</script>
