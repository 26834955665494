<template>
  <div>
    <template v-if="isTelcUser || isBamfUser">
      <pv-telc-user-list />
    </template>
    <template v-else>
      <pv-customer-list />
    </template>
  </div>
</template>

<script>

import pvCustomerList from '@/components/CustomerList';
import pvTelcUserList from '@/components/TelcUserList';

export default {
  name: 'List',

  components: {
    pvCustomerList,
    pvTelcUserList
  }
};
</script>

<style lang="scss">
.v-data-table {
  background-color: transparent !important;

  & > .v-data-table__wrapper {
    padding-top: 2rem;
    margin-top: 2rem;
    border-top: $border-width-root solid map-get($custom-colors, "grey");
  }

  tbody tr {
    td {
      .table-cell {
        &.actions a {
          text-decoration: none;
          padding-left: 2rem;
        }
        .table-cell-min-width {
          min-width: 300px !important;
        }
      }
    }

    &:not(.v-data-table__mobile-table-row) {
      background-color: transparent !important;

      &:hover {
        .table-cell {
          background-color: darken(map-get($custom-colors, "lightgrey"), 7%) !important;

          &.actions {
            background-color: darken(map-get($custom-colors, "lightgrey"), 10%) !important;
          }
        }
      }

      td {
        border-bottom: none !important;
        padding: 0 !important;
        height: 72px !important;

        .table-cell {
          overflow: hidden;
          position: relative;
          display: flex;
          align-items: center;
          padding: 15px;
          margin-bottom: 15px;
          background-color: map-get($custom-colors, "lightgrey");
          transition: background-color 0.2s;
          height: 57px;

          &.actions {
            background-color: darken(map-get($custom-colors, "lightgrey"), 3%);
            padding: {
              left: 0;
              right: 0;
            }

            &,
            & > span {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            a {
              padding: 0.25rem 1rem;
              display: inline-flex;
              align-items: center;

              &:not(:last-of-type) {
                border-right: $border-width-root solid map-get($custom-colors, "white");
              }
            }
          }

          &-inner {
            display: flex;
            align-items: center;
            max-width: 400px;

            span:first-of-type {
              display: block;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }

            .table-cell-line-wrap {
              white-space: normal !important;
            }

            &.single-button span {
              border-right: none !important;
            }
          }
        }
      }
    }
  }

  .v-data-table-header tr th {
    border-bottom: none !important;
    color: map-get($custom-colors, "black") !important;
    vertical-align: top;

    .v-data-table-header__icon {
      margin-left: 0.5rem;
    }
  }
}

//-----------------------------------------------------
// Workarounds to fix the data table footer in IE 11.
//-----------------------------------------------------
.v-data-footer__select {
  flex-basis: auto !important;
}

.v-data-footer__select .v-select {
  flex-basis: 50px !important;
}

.v-data-footer__select .v-select .v-select__selections {
  flex-basis: auto;
  min-width: 25px;
}

.v-list-item__content {
  flex: 1 1 auto;
  min-width: 50px;
}
</style>
