<template>
  <div>
    <v-container>
      <pv-hero
        ref="hero"
        :title="$t('page.readOnly.title')"
      />
    </v-container>
    <v-main>
      <v-container>
        <v-card
          v-if="audioCodeisValid(fields.audioPortalAccess)"
          class="mx-auto"
          elevation="0"
        >
          <v-card-title>
            <span>{{ $t(`page.audioPortal.title`) }}</span>
            <v-spacer />
          </v-card-title>
          <v-simple-table class="download-table">
            <template #default>
              <tbody>
                <tr>
                  <td
                    width="50%"
                  >
                    <span class="simple-table-cell">
                      <span class="simple-table-cell-inner">
                        {{ $t('page.audioPortal.fields.code') }}
                      </span>
                    </span>
                  </td>
                  <td
                    width="50%"
                  >
                    <span class="simple-table-cell">
                      <div>
                        <template v-if="fields.audioPortalAccess">
                          <b
                            class="simple-table-cell-inner"
                            v-html="fields.audioPortalAccess.code"
                          />
                          <br>
                        </template>
                        {{ $t('page.audioPortal.download') }}
                        <br>
                        <strong>{{ $t('page.audioPortal.important') }}</strong>
                        <br>
                        <a
                          :href="winClientDownloadUrl"
                          target="_blank"
                          rel="noopener"
                        >
                          Win
                        </a>&nbsp;|&nbsp;
                        <a
                          :href="macClientDownloadUrl"
                          target="_blank"
                          rel="noopener"
                        >
                          Mac
                        </a>
                      </div>
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-card
          v-if="fields.trackingInfos"
          class="mx-auto"
          elevation="0"
        >
          <v-card-title>
            <span>{{ $t(`page.trackingInfo.title`) }}</span>
            <v-spacer />
          </v-card-title>
          <v-simple-table>
            <template #default>
              <tbody>
                <tr
                  v-for="(row,i) in fields.trackingInfos[0]"
                  :key="i"
                >
                  <td
                    v-if="row != ''"
                    width="50%"
                  >
                    <span class="simple-table-cell">
                      <span class="simple-table-cell-inner">
                        {{ $t(`page.trackingInfo.fields.${i}`) }}
                      </span>
                    </span>
                  </td>
                  <td
                    v-if="row != ''"
                    width="50%"
                  >
                    <span class="simple-table-cell">
                      <div v-if="i === 'trackingLink' || i === 'directTrackingLink'">
                        <a
                          :href="row"
                          target="_blank"
                          rel="noopener"
                        >
                          <b
                            class="simple-table-cell-inner"
                            v-html="row"
                          />
                        </a>
                      </div>
                      <div v-else>
                        <b
                          class="simple-table-cell-inner"
                          v-html="row"
                        />
                      </div>
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-divider class="my-10" />
        <v-simple-table>
          <template #default>
            <tbody>
              <template
                v-for="(row,i) in readOnly"
              >
                <tr
                  v-if="row"
                  :id="i"
                  :key="i"
                >
                  <td width="50%">
                    <span class="simple-table-cell">
                      <span class="simple-table-cell-inner">
                        {{ $t(`page.readOnly.fields.${i}.label`) }}
                      </span>
                    </span>
                  </td>
                  <td width="50%">
                    <span class="simple-table-cell">
                      <b
                        class="simple-table-cell-inner"
                        v-html="row"
                      />
                    </span>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>

        <!-- telc interner Hinweis -->
        <div
          v-if="isTelcUser"
          class="mb-8"
        >
          <v-textarea
            v-model="fields.internalNotes"
            :label="$t('page.create.fields.internalNotes')"
            :hint="isReadonlyUser ? '' : $t('page.create.fields.internalNotesHint')"
            :disabled="isReadonlyUser"
            name="input-7-1"
            outlined
            auto-grow
            value=""
            persistent-hint
          />
        </div>

        <pv-actionbar
          :secondary-actions="ui.secondaryActions"
          :primary-actions="ui.primaryActions"
          @cancel="onCancel"
          @save="onSave"
          @print="onPrint"
        />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import moment from 'moment';
import {
  getDownloadportalURL as pvGetDownloadportalURL
} from '@/utils/general';

import pvActionbar from '@/components/Actionbar';
import pvHero from '@/components/Hero';

export default {
  name: 'Read',

  components: {
    pvActionbar,
    pvHero
  },

  inject: [
    'pruefungsfachverwaltung',
    'pruefungsverwaltung',
    'institutverwaltung'
  ],

  data: () => ({
    institute: null,
    fields: {},
    courses: [],
    readOnly: {},
    orderBy: [
      'examinationCenter.name',
      'status',
      'contactPersonName',
      'contactPersonTelephone',
      'contactPersonEmail',
      'examOrderNumber',
      'examSubject.level',
      'examSubject.language',
      'examSubject.examType',
      'writingExamGroups',
      'writingAttendeeSum',
      'oralExamGroups',
      'oralAttendeeSum',
      'totalNumberAttendees',
      'invoiceAddress',
      'deliveryAddress',
      'deliveryCertificatesAddress',
      'examinationLocation',
      // 'termsAndConditions.agbDifferentExamPlace',
      'termsAndConditions.bamf',
      'termsAndConditions.telcAgb',
      'termsAndConditions.telcLicence',
      'additionalNotes'
    ]
  }),

  computed: {
    eiUuid() {
      return localStorage.getItem('eiUuid') || this.$route.query.institute;
    },
    ui() {
      return {
        secondaryActions: [
          {
            title: this.$t('page.readOnly.cancel'),
            color: 'white',
            outlined: true,
            event: 'cancel'
          }
        ],
        primaryActions: [
          {
            title: this.$t('page.readOnly.saveNote'),
            icon: '$vuetify.icons.saveAction',
            color: 'primary',
            event: 'save',
            hidden: this.isReadonlyUser || !this.isTelcUser
          },
          {
            title: this.$t('page.readOnly.print'),
            icon: '$vuetify.icons.printAction',
            color: 'primary',
            event: 'print'
          }
        ]
      };
    },
    statusTranslation() {
      return [
        {
          code: 'open',
          translation: this.$t('page.list.states.open.text'),
          message: this.$t('page.list.states.open.message')
        },
        {
          code: 'in_submission',
          translation: this.$t('page.list.states.inSubmission.text'),
          message: this.$t('page.list.states.inSubmission.message')
        },
        {
          code: 'submission_failed',
          translation: this.$t('page.list.states.submissionFailed.text'),
          message: this.$t('page.list.states.submissionFailed.message')
        },
        {
          code: 'submitted',
          translation: this.$t('page.list.states.submitted.text'),
          message: this.$t('page.list.states.submitted.message')
        },
        {
          code: 'terminated',
          translation: this.$t('page.list.states.terminated.text'),
          message: this.$t('page.list.states.terminated.message')
        },
        {
          code: 'fulfilment_blocked',
          translation: this.$t('page.list.states.fulfilmentBlocked.text'),
          message: this.$t('page.list.states.fulfilmentBlocked.message')
        },
        {
          code: 'approved',
          translation: this.$t('page.list.states.approved.text'),
          message: this.$t('page.list.states.approved.message')
        },
        {
          code: 'return_incomplete',
          translation: this.$t('page.list.states.return_incomplete.text'),
          message: this.$t('page.list.states.return_incomplete.message')
        },
        {
          code: 'return_completed',
          translation: this.$t('page.list.states.return_completed.text'),
          message: this.$t('page.list.states.return_completed.message')
        },
        {
          code: 'in_evaluation',
          translation: this.$t('page.list.states.in_evaluation.text'),
          message: this.$t('page.list.states.in_evaluation.message')
        },
        {
          code: 'evaluated',
          translation: this.$t('page.list.states.evaluated.text'),
          message: this.$t('page.list.states.evaluated.message')
        },
        {
          code: 'certificate_in_dispatch',
          translation: this.$t('page.list.states.certificate_in_dispatch.text'),
          message: this.$t('page.list.states.certificate_in_dispatch.message')
        },
        {
          code: 'evaluation_for_exam',
          translation: this.$t('page.list.states.evaluation_for_exam.text'),
          message: this.$t('page.list.states.evaluation_for_exam.message')
        },
        {
          code: 'exam_conducted',
          translation: this.$t('page.list.states.examConducted.text'),
          message: this.$t('page.list.states.examConducted.message')
        },
        {
          code: 'order_in_process',
          translation: this.$t('page.list.states.order_in_process.text'),
          message: this.$t('page.list.states.order_in_process.message')
        },
        {
          code: 'preliminary_evaluation',
          translation: this.$t('page.list.states.preliminary_evaluation.text'),
          message: this.$t('page.list.states.preliminary_evaluation.message')
        },
        {
          code: 'exam_failed',
          translation: this.$t('page.list.states.exam_failed.text'),
          message: this.$t('page.list.states.exam_failed.message')
        },
        {
          code: 'in_assessment',
          translation: this.$t('page.list.states.in_assessment.text'),
          message: this.$t('page.list.states.in_assessment.message')
        }
      ];
    },
    winClientDownloadUrl() {
      return pvGetDownloadportalURL('win');
    },

    macClientDownloadUrl() {
      return pvGetDownloadportalURL('mac');
    }
  },

  created() {
    this.getCourses();
    this.institutverwaltung.getInstitute(this.eiUuid).then(({ data: { institute } }) => {
      this.institute = institute;
      this.pruefungsverwaltung.getExam(this.eiUuid, this.$route.params.readId).then(({ data: { exam } }) => {
        this.debug(exam);
        this.fields = exam;
        this.order();
      });
    });
  },

  methods: {
    ...mapMutations('notice', {
      addNotice: 'add'
    }),

    getExaminationCenter(uuid) {
      return this.institute.examinationCenters.find((ec) => ec.uuid === uuid);
    },

    getContactPerson(uuid) {
      let contactPerson = this.institute.contacts.find((contact) => contact.uuid === uuid);
      if (!contactPerson) contactPerson = this.getExaminationCenter(this.fields.examinationCenter.uuid).contacts.find((contact) => contact.uuid === uuid);
      return contactPerson;
    },

    getStateTranslation(code) {
      return this.statusTranslation.find((state) => state.code === code)?.translation;
    },

    getAddressAsString(type) {
      return `${this.fields[type].street} ${this.fields[type].houseId}, ${this.fields[type].zipCode} ${this.fields[type].city}`;
    },

    attendeeSum(groups) {
      const sum = groups.reduce((a, b) => a + (b.groupSize || 0), 0);
      const partial = groups.reduce((a, b) => a + (b.numberPartialExams || 0), 0);
      if (partial === 0) return sum;
      return this.$t('page.readOnly.fields.attendeeSumPartial.label', { sum, partial });
    },

    getGroupInfos(groups) {
      let out = '';
      groups.forEach((group, idx) => {
        out += this.$t('page.readOnly.fields.groups.label', { date: this.formatDate(group.examDate, 'DD.MM.YYYY HH:mm'), number: idx + 1, size: group.groupSize });
        if (group.numberPartialExams > 0) out += this.$t('page.readOnly.fields.groups.partialExam.label', { size: group.numberPartialExams });
        out += '<br>';
      });
      return out;
    },

    getTermsAndConditions(type) {
      return this.$t(`page.readOnly.fields.termsAndConditions.${this.fields.termsAndConditions[type].accepted}`);
    },

    onCancel() {
      this.$router.push({ name: 'list' });
    },

    onSave() {
      this.updateExam(this.eiUuid, this.$route.params.readId, this.fields);
    },

    async getCourses() {
      const response = await this.pruefungsfachverwaltung.getCourses();
      this.courses = response.data.examSubjects;
    },

    updateExam(instituteUuid, examId, data) {
      this.pruefungsverwaltung.updateExam(instituteUuid, examId, data).then((response) => {
        this.debug(response);
        if (response.status === 200) {
          this.$router.push({ name: 'list' });
          this.addNotice({
            message: this.$t('page.create.messages.success'),
            type: 'success'
          });
        }
      });
    },

    order() {
      const out = {};
      this.orderBy.forEach((data) => {
        if (data.includes('.')) {
          const splitted = data.split('.');
          const splitter1 = splitted[1].charAt(0).toUpperCase() + splitted[1].slice(1);
          if (splitted[0] === 'termsAndConditions') {
            out[splitted[0] + splitter1] = this.getTermsAndConditions(splitted[1]);
          } else if (splitted[0] === 'examSubject' && splitted[1] === 'language') {
            out[splitted[0] + splitter1] = this.fields.examSubject.language.length > 4 ? this.fields.examSubject.language : this.courses.find((course) => course.id === this.fields.examSubject.id)?.title;
          } else {
            out[splitted[0] + splitter1] = this.fields[splitted[0]][splitted[1]];
          }
        } else {
          out[data] = this.fields[data];
          if (data === 'contactPersonTelephone') out[data] = this.getContactPerson(this.fields.contactPerson.uuid).phone;
          if (data === 'contactPersonEmail') out[data] = this.getContactPerson(this.fields.contactPerson.uuid).email;
          if (data === 'contactPersonName') out[data] = `${this.getContactPerson(this.fields.contactPerson.uuid).firstName} ${this.getContactPerson(this.fields.contactPerson.uuid).lastName}`;
          if (data.includes('Address') || data.includes('Location')) out[data] = this.getAddressAsString(data);
          if (data.includes('ExamGroups')) out[data] = this.getGroupInfos(this.fields[data]);
          if (data.includes('writingAttendeeSum')) out[data] = this.attendeeSum(this.fields.writingExamGroups);
          if (data.includes('oralAttendeeSum')) out[data] = this.attendeeSum(this.fields.oralExamGroups);
          if (data === 'status') out[data] = this.getStateTranslation(this.fields[data]);
        }
      });
      this.readOnly = out;
    },

    audioCodeisValid(obj) {
      if (!obj) return false;
      return moment.utc().isBetween(obj?.from, obj?.to, 'day', '[]');
    },

    onPrint() {
      window.print();
    }
  }
};
</script>

<style lang="scss" scoped>
.v-data-table {
  tbody tr {
    td {
      .simple-table-cell {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 15px;
        background-color: map-get($custom-colors, "lightgrey");
        border-bottom: 15px solid map-get($custom-colors, "white");

        &-inner {
          display: block;
          max-width: 500px;
        }
      }
    }
  }
}

.download-table {
  background-color: map-get($custom-colors, "lightgrey") !important;

  tbody tr:not(.v-data-table__mobile-table-row) td {
    height: auto !important;

    .simple-table-cell {
      border-bottom: none !important;
    }
  }
}
</style>
