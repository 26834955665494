<template>
  <v-expansion-panel>
    <v-expansion-panel-header :color="color">
      {{ headerPrefix }} {{ statusElements.length }}
    </v-expansion-panel-header>
    <v-expansion-panel-content eager>
      <div
        v-for="item in statusElements"
        :key="item.itemId"
        class="result-item"
      >
        <span>
          {{ item.itemId }} {{ item.statusMessage }}
        </span>

        <link-to-blank-page
          v-if="item.examProperties && item.examProperties.instituteUuid"
          :link-title="$t('page.importSurpassDialog.linkToExamAttendees')"
          :link-href="getShowAttendeesLink(item)"
        />
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { getRemoteURL } from '@/utils/general';
import LinkToBlankPage from '@/components/LinkToBlankPage';

export default {
  components: { LinkToBlankPage },
  props: {
    headerPrefix: { type: String, required: true },
    statusElements: { type: Array, required: true },
    color: { type: String, required: true }
  },

  methods: {
    getShowAttendeesLink(statusItemWithExamProperties) {
      const { examProperties } = statusItemWithExamProperties;
      if (!examProperties) return '';
      return `${getRemoteURL()}ptv/teilnehmerverwaltung/institut/${examProperties.instituteUuid}/pruefung/${examProperties.examUuid}`;
    }

  }
};
</script>
