import {
  capitalize,
  getCurrentLocale
} from '@/utils/general';

import countriesJson from './countries';

export const getCountries = (lang = getCurrentLocale()) => countriesJson.map((country) => {
  const labelParts = country.label.split(' - ')[1].split('|');
  return {
    ...country,
    label: labelParts.length ? capitalize(labelParts[lang === 'de' ? 0 : 1]) : country.label
  };
}).sort((a, b) => {
  if (a.label < b.label) { return -1; }
  if (a.label > b.label) { return 1; }
  return 0;
});
