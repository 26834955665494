<template>
  <v-autocomplete
    id="filter-for-subject"
    ref="autocomplete"
    v-model="model"
    :items="items"
    :loading="false"
    :label="$t('page.list.filters.examSubject.label')"
    outlined
    dense
    hide-details="auto"
    :placeholder="$t('page.list.filters.examSubject.placeholder')"
    clearable
    @change="$emit('change')"
  />
</template>

<script>
const subjectFormats = ['digital', 'physical'];

function subject2item(subject) {
  return {
    text: `${subject.id}: ${subject.title}`,
    value: [subject.id]
  };
}

function compareSubjects(a, b) {
  return a.id - b.id;
}

function createCategoryHeader(name) {
  return { header: name };
}

function createCategoryItem(subjects, name) {
  return { text: name, value: subjects.map((subject) => subject.id) };
}

function createSubjectItems(subjects, formatNameFn, allNameFn) {
  return subjectFormats.flatMap((format) => {
    const allSubjectsOfFormat = subjects.filter((subject) => subject.format === format);
    return allSubjectsOfFormat.length ? [
      createCategoryHeader(formatNameFn(format)),
      createCategoryItem(allSubjectsOfFormat, allNameFn(format)),
      ...allSubjectsOfFormat.sort(compareSubjects).map(subject2item)
    ] : [];
  });
}

export default {

  inject: [
    'pruefungsfachverwaltung'
  ],

  props: {
    value: {
      default: () => [],
      type: Array
    }
  },

  data() {
    return {
      allSubjects: null
    };
  },

  computed: {
    // the model property implements v-model
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },

    items() {
      return this.allSubjects ? createSubjectItems(this.allSubjects, this.categoryHeader, this.selectAllString) : [];
    }
  },

  async created() {
    try {
      this.allSubjects = (await this.pruefungsfachverwaltung.getCourses()).data.examSubjects;
    } catch {
      this.allSubjects = null;
    }
  },

  methods: {
    focus() {
      this.$refs.autocomplete.$refs.input.focus();
    },

    categoryHeader(format) {
      return this.$t(`page.list.filters.examSubject.categoryHeader.${format}`);
    },

    selectAllString(format) {
      return this.$t(`page.list.filters.examSubject.selectAllString.${format}`);
    }
  }

};
</script>
