export const authorizationMixin = {
  computed: {
    /**
     * The examination institute uuid of the currently logged in user.
     *
     * @returns {string}
     */
    eiUuid() {
      return localStorage.getItem('eiUuid');
    },

    /**
     * The examination center uuid of the currently logged in user.
     *
     * @returns {string}
     */
    ecUuid() {
      return localStorage.getItem('ecUuid');
    },

    /**
     * Get the role of the currently logged in user.
     *
     * @returns {string}
     */
    userRole() {
      return localStorage.getItem('current-roles').split(',').find((role) => role.includes('pv_') || role.includes('tuq_'));
    },

    /**
     * Get the roles of the currently logged in user.
     * Only roles with the pv_ and tuq_ prefixes are considered.
     *
     * @returns {string[]}
     */
    userRoles() {
      return localStorage.getItem('current-roles').split(',').filter((role) => role.includes('pv_') || role.includes('tuq_'));
    },

    /**
     * Check if the currently logged in user is a telc user.
     *
     * @returns {boolean}
     */
    isTelcUser() {
      return (this.userHasAnyRole('pv_admin', 'pv_user', 'pv_reader', 'pv_helper', 'pv_super_user'));
    },

    /**
     * Check if the currently logged in user is a user without write access.
     *
     * @returns {boolean}
     */
    isReadonlyUser() {
      return (this.userHasAnyRole('pv_reader', 'pv_helper'));
    },

    /**
     * Check if the currently logged in user is a helper user.
     *
     * @returns {boolean}
     */
    isHelperUser() {
      return this.userHasAnyRole('pv_helper');
    },

    /**
     * Check if the currently logged in user is an institute customer.
     *
     * @returns {boolean}
     */
    isInstituteCustomer() {
      return !!(!this.ecUuid && this.eiUuid) && this.userHasAnyRole('pv_customer_user', 'pv_customer_admin');
    },

    /**
     * Check if the currently logged in user is an examinaton center customer.
     *
     * @returns {boolean}
     */
    isExamCenterCustomer() {
      return !!(this.ecUuid && this.eiUuid) && this.userHasAnyRole('pv_customer_user', 'pv_customer_admin');
    },

    /**
     * Check if the currently logged in user is an admin.
     *
     * @returns {boolean}
     */
    isAdminUser() {
      return this.userHasAnyRole('pv_customer_admin', 'pv_admin');
    },

    /**
     * Check if the currently logged in user is a Telc employee.
     *
     * @returns {boolean}
     */
    isTelcEmployee() {
      return (this.isTelcUser && !this.isAdminUser);
    },

    /**
     * Check if the currently logged in user is a Bamf reader.
     *
     * @returns {boolean}
     */
    isBamfUser() {
      return this.userHasAnyRole('pv_bamf_reader');
    }
  },

  methods: {
    /**
     * Check whether the logged in user has any of the passed roles.
     */
    userHasAnyRole(...roles) {
      return roles.find((r) => this.userRoles.includes(r)) !== undefined;
    }
  }
};
