import axios from 'axios';
import store from '@/plugins/vuex';

import { generateServiceBaseURL, capitalize } from '@/utils/general';
import { requestInterceptor } from '@/plugins/keycloak';

export const service = axios.create({
  baseURL: generateServiceBaseURL('ptv/teilnehmerverwaltung')
});

service.interceptors.request.use(requestInterceptor);

service.interceptors.response.use((response) => response, (error) => {
  if (error.response.status !== 404) {
    const { data } = error.response;
    store.commit('notice/add', {
      message: data.message ? `${capitalize(data.message)}.` : 'Es ist ein Fehler beim Abruf des Service "Teilnehmerverwaltung" aufgetreten. Bitte versuchen Sie es später erneut.',
      type: 'error'
    });
  }

  return error.response;
});

export const getExaminer = (examinationInstituteId, examId) => service({
  method: 'get',
  url: `${examinationInstituteId}/pruefungen/${examId}/pruefer`
});

export const setExaminer = (examinationInstituteId, examId, data) => service({
  method: 'put',
  url: `${examinationInstituteId}/pruefungen/${examId}/pruefer`,
  data
});

export const downloadDocket = ({ eiUuid, uuid }) => service({
  method: 'get',
  responseType: 'blob',
  url: `/${eiUuid}/pruefungen/${uuid}/laufzettel`
});

export const uploadSurpassExcel = (formDataWithExamSubjectAndFile) => service({
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  method: 'post',
  url: '/surpass-bulk-import',
  data: formDataWithExamSubjectAndFile
});

export const teilnehmerverwaltung = {
  getExaminer,
  setExaminer,
  downloadDocket,
  uploadSurpassExcel
};
