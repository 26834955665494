import axios from 'axios';
import store from '@/plugins/vuex';

import { generateServiceBaseURL, capitalize } from '@/utils/general';
import { requestInterceptor } from '@/plugins/keycloak';

export const pfvService = axios.create({
  baseURL: generateServiceBaseURL('pfv/lizenzen')
});

export const tuqService = axios.create({
  baseURL: generateServiceBaseURL('tuq/lizenzen')
});

pfvService.interceptors.request.use(requestInterceptor);
tuqService.interceptors.request.use(requestInterceptor);

pfvService.interceptors.response.use((response) => response, (error) => {
  const { data } = error.response;
  store.commit('notice/add', {
    message: data.message ? `${capitalize(data.message)}.` : 'Es ist ein Fehler beim Abruf des Service "Lizenzverwaltung (pfv)" aufgetreten. Bitte versuchen Sie es später erneut.',
    type: 'error'
  });

  return error;
});

tuqService.interceptors.response.use((response) => response, (error) => {
  const { data } = error.response;
  store.commit('notice/add', {
    message: data.message ? `${capitalize(data.message)}.` : 'Es ist ein Fehler beim Abruf des Service "Lizenzverwaltung (tuq)" aufgetreten. Bitte versuchen Sie es später erneut.',
    type: 'error'
  });

  return error;
});

export const getLicence = (id) => pfvService({
  method: 'get',
  url: `/${id}`
});

export const getLicences = () => pfvService({
  method: 'get'
});

export const createLicence = (data) => pfvService({
  method: 'post',
  data
});

export const updateLicence = (id, data) => pfvService({
  method: 'put',
  url: `/${id}`,
  data
});
export const deleteLicence = (id) => pfvService({
  method: 'delete',
  url: `/${id}`
});

export const validateLicences = (examiners, examSubject, examDate) => tuqService({
  method: 'post',
  url: '/validierung',
  data: {
    examSubject,
    examiners,
    examDate
  }
});

export const getUserLicences = (tuqRef) => tuqService({
  method: 'get',
  url: `/pruefer/${tuqRef}`
});

export const lizenzverwaltung = {
  getLicence,
  getLicences,
  createLicence,
  updateLicence,
  deleteLicence,
  validateLicences,
  getUserLicences
};
