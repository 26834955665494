<template>
  <v-dialog
    v-model="dialogs.lateRegistration"
    max-width="600px"
    @click:outside="hideDialog"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t(`page.lateRegistration.dialog.title`) }}</span>
        <v-spacer />
        <v-btn
          v-if="isShowing"
          icon
          @click="hideDialog"
        >
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container
          fluid
          class="pa-0"
        >
          <p
            v-if="original"
            class="text-subtitle-1 m-0 late-registration-original"
          >
            <strong>{{ $t(`page.lateRegistration.dialog.original.title`) }}</strong>:<br>
            {{ $t(`page.lateRegistration.dialog.original.allAttendees`) }}: {{ getTotalNumberAttendees(original) }}<br>
            {{ $t(`page.lateRegistration.dialog.original.writtenGroups`) }}: {{ original.writtenGroups.length }}<br>
            {{ $t(`page.lateRegistration.dialog.original.oralGroups`) }}: {{ original.oralGroups.length }}<br>
          </p>

          <p
            v-if="history"
            class="text-subtitle-1 m-0"
          >
            <strong>{{ $t(`page.lateRegistration.dialog.history.title`) }}</strong>:<br>
            <span
              v-for="(item, index) in history"
              :key="index"
              class="late-registration-history-item"
            >
              {{ $t(`page.lateRegistration.dialog.history.date`) }}: {{ item.formattedDate }}<br>
              {{ $t(`page.lateRegistration.dialog.history.allAttendees`) }}: {{ getTotalNumberAttendees(item) }} ({{ showChange(getTotalNumberAttendees(item), getTotalNumberAttendees(item.previous)) }})<br>
              {{ $t(`page.lateRegistration.dialog.history.writtenGroups`) }}: {{ item.writtenGroups.length }} ({{ showChange(item.writtenGroups.length, item.previous.writtenGroups.length) }})<br>
              {{ $t(`page.lateRegistration.dialog.history.oralGroups`) }}: {{ item.oralGroups.length }} ({{ showChange(item.oralGroups.length, item.previous.oralGroups.length) }})<br><br>
            </span>
          </p>
          <v-divider />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="white"
          depressed
          @click="editedDialog"
        >
          {{ $t(`page.lateRegistration.dialog.actions.edited`) }}
        </v-btn>
        <v-btn
          color="white"
          depressed
          @click="hideDialog"
        >
          {{ $t(`page.lateRegistration.dialog.actions.close`) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import moment from 'moment';

export default {
  props: {
    lateRegistrations: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    original: null,
    history: []
  }),

  computed: {
    ...mapGetters('dialogs', {
      dialogs: 'get'
    })
  },

  watch: {
    /**
     * @param {boolean} visible
     * @returns {void}
     */
    /* eslint-disable-next-line */
    'dialogs.lateRegistration': function (visible) {
      if (visible) {
        this.original = { ...this.lateRegistrations?.original };
        this.history = this.buildHistoryArray(this.lateRegistrations?.history);
      }

      this.$emit(visible ? 'show' : 'hide', this.lateRegistrations);
    }
  },

  methods: {
    ...mapActions('dialogs', {
      hideLateRegistrationDialog: 'HIDE_LATE_REGISTRATION'
    }),

    /**
     * Hide the dialog and reset the form.
     *
     * @returns {void}
     */
    hideDialog() {
      this.hideLateRegistrationDialog();
    },

    editedDialog() {
      this.hideLateRegistrationDialog();
      this.$emit('edited');
    },

    sortObjectByKeys(o) {
      /* eslint-disable-next-line */
      return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
    },

    buildHistoryArray(history) {
      if (!history) return [];
      const $newArray = [];
      let previous = { ...this.lateRegistrations?.original };
      Object.entries(history).forEach(([key, value], index) => {
        const temp = value;
        if (!temp.writtenGroups) {
          temp.writtenGroups = previous.writtenGroups;
        }
        if (!temp.oralGroups) {
          temp.oralGroups = previous.oralGroups;
        }
        temp.previous = previous;
        temp.date = key;
        temp.formattedDate = moment.unix(key).format('DD.MM.YYYY HH:mm');
        $newArray[index] = temp;
        previous = value;
      });
      return $newArray;
    },

    getTotalNumberAttendees(lateRegistration) {
      if (lateRegistration.totalNumberAttendees) {
        return lateRegistration.totalNumberAttendees;
      }
      return this.calculateTotalNumbers(lateRegistration.writtenGroups, lateRegistration.oralGroups);
    },

    calculateTotalNumbers(writtenGroups, oralGroups) {
      const sizeCounter = ((acc, { groupSize }) => {
        if (groupSize) acc += +groupSize;

        return acc;
      });
      const partialSizeCounter = ((acc, { numberPartialExams }) => {
        if (numberPartialExams) acc += +numberPartialExams;

        return acc;
      });

      // Count total across written groups
      const allWrittenParticipants = writtenGroups.reduce(sizeCounter, 0);
      const allPartialWrittenParticipants = writtenGroups.reduce(partialSizeCounter, 0);

      // Count total across oral groups
      const allOralParticipants = oralGroups.reduce(sizeCounter, 0);
      const allPartialOralParticipants = oralGroups.reduce(partialSizeCounter, 0);

      // Calculate the individual participants
      const allFullWrittenParticipants = allWrittenParticipants - allPartialWrittenParticipants;
      const allFullOralParticipants = allOralParticipants - allPartialOralParticipants;
      const totalNumberAttendees = Math.max(allFullWrittenParticipants, allFullOralParticipants) + (allPartialWrittenParticipants + allPartialOralParticipants);

      return totalNumberAttendees;
    },

    showChange(current, previous) {
      if (parseInt(current, 10) === parseInt(previous, 10)) return '-';
      if (previous > current) {
        return `-${(previous - current)}`;
      }
      return `+${(current - previous)}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.licence-type-label {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>
