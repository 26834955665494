import axios from 'axios';

import { generateServiceBaseURL, capitalize } from '@/utils/general';
import { requestInterceptor } from '@/plugins/keycloak';

export function pruefungsverwaltung(i18n, store) {
  const service = axios.create({
    baseURL: generateServiceBaseURL('pv/pruefungsverwaltung')
  });

  service.interceptors.request.use(requestInterceptor);

  service.interceptors.response.use((response) => response, (error) => {
    if (error.response.status !== 404) {
      const { data } = error.response;
      if (data.translation && i18n.te(data.translation.key)) {
        data.message = i18n.t(data.translation.key, data.translation.params);
      }
      store.commit('notice/add', {
        message: data.message ? `${capitalize(data.message)}.` : 'Es ist ein Fehler beim Abruf des Service "Prüfungsverwaltung" aufgetreten. Bitte versuchen Sie es später erneut.',
        type: 'error'
      });
    }

    return error.response;
  });

  const getExam = (examinationInstituteId, examId) => service({
    method: 'get',
    url: `/${examinationInstituteId}/pruefungen/${examId}`
  });

  const getExams = (examinationInstituteId, examDateFrom, examDateTo) => service({
    method: 'get',
    url: `/${examinationInstituteId}`,
    params: {
      examDateFrom,
      examDateTo: examDateTo || ''
    }
  });

  const createExam = (examinationInstituteId, data) => service({
    method: 'post',
    url: `/${examinationInstituteId}`,
    data
  });

  const deleteExam = (examinationInstituteId, examId) => service({
    method: 'delete',
    url: `/${examinationInstituteId}/pruefungen/${examId}`
  });

  const updateExam = (examinationInstituteId, examId, data) => service({
    method: 'put',
    url: `/${examinationInstituteId}/pruefungen/${examId}`,
    data
  });

  const validateExam = (examinationInstituteId, data) => service({
    method: 'post',
    url: `/${examinationInstituteId}/pruefungen/validierung`,
    data
  });

  const searchExam = (data) => service({
    method: 'post',
    data
  });

  const generateReport = (data) => service({
    method: 'post',
    url: '/report/bearbeitungszeiten',
    data,
    responseType: 'blob'
  });

  const setLateRegistrationStatus = (examinationInstituteId, examId) => service({
    method: 'post',
    url: `/${examinationInstituteId}/pruefungen/${examId}/nachmeldungs-status`
  });

  const returnInformation = (examinationInstituteId, examId, data) => service({
    method: 'put',
    url: `/${examinationInstituteId}/pruefungen/${examId}/ruecklauf`,
    data
  });

  const getExamsExportList = (examinationInstituteId, data) => service({
    method: 'get',
    url: `/${examinationInstituteId}/pruefungslisten-export`,
    params: data,
    responseType: 'blob'
  });

  const validateExaminerCommittees = (instituteUuid, examUuid, committees) => service({
    method: 'post',
    url: `/${instituteUuid}/pruefungen/${examUuid}/ruecklauf/pruefungskommissionen/validierung`,
    data: committees
  });

  const setExaminerCommittees = (instituteUuid, examUuid, committees) => service({
    method: 'put',
    url: `${instituteUuid}/pruefungen/${examUuid}/ruecklauf/pruefungskommissionen`,
    data: committees
  });

  const validateEvaluators = (instituteUuid, examUuid, tuqrefs) => service({
    method: 'post',
    url: `/${instituteUuid}/pruefungen/${examUuid}/ruecklauf/bewerter/validierung`,
    data: tuqrefs
  });

  return {
    getExam,
    getExams,
    createExam,
    deleteExam,
    updateExam,
    validateExam,
    searchExam,
    generateReport,
    setLateRegistrationStatus,
    returnInformation,
    getExamsExportList,
    validateExaminerCommittees,
    setExaminerCommittees,
    validateEvaluators
  };
}
