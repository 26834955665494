import axios from 'axios';
import store from '@/plugins/vuex';

import { capitalize } from '@/utils/general';

export const service = axios.create({
  baseURL: `${process.env.BASE_URL}/config.json`
});

service.interceptors.response.use((response) => response, (error) => {
  const { data } = error.response;
  store.commit('notice/add', {
    message: data.message ? `${capitalize(data.message)}.` : 'Es ist ein Fehler beim Abruf des Service "Config" aufgetreten. Bitte versuchen Sie es später erneut.',
    type: 'error'
  });

  return error;
});

export const getConfigs = () => service({
  method: 'get'
});

export const config = {
  getConfigs
};
