<template>
  <v-dialog
    v-model="dialogs.setInstitute"
    max-width="500px"
    @click:outside="hideDialog"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t(`page.setInstitute.title`) }}</span>
        <v-spacer />
        <v-btn
          v-if="isShowing"
          icon
          @click="hideDialog"
        >
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container
          fluid
          class="pa-0"
        >
          <p class="text-subtitle-1 m-0">
            {{ $t(`page.setInstitute.text`) }}
          </p>
          <v-divider />
          <v-form ref="form">
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="instituteFilterValue"
                  :loading="instituteFilterValueLoading"
                  :items="instituteList"
                  :disabled="customerNumberFilterValue"
                  :no-data-text="$t('page.list.filters.institute.noText')"
                  :label="$t('page.list.filters.institute.label')"
                  item-text="select"
                  item-value="uuid"
                  :search-input.sync="instituteFilterSearch"
                  cache-items
                  clearable
                  outlined
                  dense
                  hide-details
                  @click:clear="instituteFilterValue = ''"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="customerNumberFilterValue"
                  :loading="customerNumberFilterValueLoading"
                  :items="customerNumberList"
                  :disabled="instituteFilterValue"
                  :no-data-text="$t('page.list.filters.customerNumber.noText')"
                  :label="$t('page.list.filters.customerNumber.label')"
                  item-text="select"
                  item-value="uuid"
                  :search-input.sync="customerNumberFilterSearch"
                  cache-items
                  clearable
                  outlined
                  dense
                  hide-details
                  @click:clear="customerNumberFilterValue = ''"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="white"
          depressed
          @click="hideDialog"
        >
          {{ $t(`page.setInstitute.actions.cancel`) }}
        </v-btn>
        <v-btn
          :disabled="!isSelectedInstituteValid"
          color="primary"
          depressed
          @click="setInstitute"
        >
          {{ $t(`page.setInstitute.actions.set`) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import {
  searchInstitute as pvSearchInstitute
} from '@/services/institutverwaltung';
import MarkInactiveMixin from '@/mixins/MarkInactive.vue';

export default {

  mixins: [MarkInactiveMixin],
  props: {
    institutes: {
      type: Array,
      default: null
    }
  },
  data: (vm) => ({
    instituteFilterValue: null,
    instituteFilterSearch: null,
    examinationInstituteFilterTimeout: null,
    instituteFilterValueLoading: false,
    instituteList: [],

    customerNumberFilterValue: null,
    customerNumberFilterSearch: null,
    customerNumberFilterTimeout: null,
    customerNumberFilterValueLoading: false,
    customerNumberList: [],

    rules: {
      institute: [
        (v) => !!v || vm.$t('page.setInstitute.notChosen')
      ]
    }
  }),

  computed: {
    ...mapGetters('dialogs', {
      dialogs: 'get'
    }),

    isSelectedInstituteValid() {
      if (this.instituteFilterValue) {
        const institute = this.institutes.find((item) => item.uuid === this.instituteFilterValue);
        if (institute) {
          return !institute.inactive;
        }
      }

      if (this.customerNumberFilterValue) {
        const institute = this.institutes.find((item) => item.uuid === this.customerNumberFilterValue);
        if (institute) {
          return !institute.inactive;
        }
      }

      return false;
    }
  },

  watch: {
    instituteFilterSearch(val) {
      let value = val;
      if (val) [value] = val.split(':');
      return value && value !== this.instituteFilterValue && value.length > 2 && this.querySelections(value);
    },

    customerNumberFilterSearch(val) {
      let value = val;
      if (val) [value] = val.split(':');
      return value && value !== this.customerNumberFilterValue && value.length > 2 && this.customerNumberQuerySelections(value);
    }
  },

  methods: {
    ...mapActions('dialogs', {
      hideSetInstituteDialog: 'HIDE_SET_INSTITUTE'
    }),

    querySelections(v) {
      this.instituteFilterValueLoading = true;
      clearTimeout(this.examinationInstituteFilterTimeout);
      this.examinationInstituteFilterTimeout = setTimeout(() => {
        pvSearchInstitute(v, '').then(({ data: { institutes } }) => {
          this.instituteList = this.mapInstitutes(institutes);
          this.instituteFilterValueLoading = false;
        });
      }, 500);
    },

    customerNumberQuerySelections(v) {
      this.customerNumberFilterValueLoading = true;
      clearTimeout(this.customerNumberFilterTimeout);
      localStorage.setItem('customerNumberFilterSearch', v);
      this.customerNumberFilterTimeout = setTimeout(() => {
        pvSearchInstitute('', v).then(({ data: { institutes } }) => {
          this.customerNumberList = this.mapInstitutes(institutes);
          this.customerNumberFilterValueLoading = false;
        });
      }, 500);
    },

    mapInstitutes(institutes) {
      return institutes.map(({ name, uuid }) => {
        const institute = this.institutes.find((item) => item.uuid === uuid);
        const extRef = institute?.extRef;
        const selection = this.maybeInactiveSelectOption(institute);

        return {
          extRef,
          name,
          uuid,
          select: selection
        };
      });
    },

    /**
    * Route to 'Add' view and pass the institute via query parameter .
    *
    * @returns {void}
    */
    setInstitute() {
      if (this.validateForm()) {
        if (this.instituteFilterValue) {
          this.$router.push({
            name: 'add',
            query: {
              institute: this.instituteFilterValue
            }
          });
        }
        if (this.customerNumberFilterValue) {
          this.$router.push({
            name: 'add',
            query: {
              institute: this.customerNumberFilterValue
            }
          });
        }
        this.hideDialog();
      }
    },

    /**
     * Hide the dialog and reset the form.
     *
     * @returns {void}
     */
    hideDialog() {
      this.hideSetInstituteDialog();
      this.resetForm();
    },

    /**
     * Reset the form.
     *
     * @returns {void}
     */
    resetForm() {
      this.$refs.form.reset();
    },

    /**
     * Validate the form.
     *
     * @returns {boolean}
     */
    validateForm() {
      return this.$refs.form.validate();
    }
  }
};
</script>
