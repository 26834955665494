<template>
  <v-card
    outlined
    class="mb-4"
  >
    <v-card-title>
      <v-icon
        color="white"
        class="mr-3"
        small
      >
        $vuetify.icons.oralGroup
      </v-icon>
      <span>{{ $t('page.return.examinersAndEvaluators.examinerCommittees.header', {index: index+1}) }}</span>
      <v-spacer />
      <v-btn
        class="ml-2 delete-committee-btn"
        depressed
        light
        fab
        x-small
        @click="deleteThis"
      >
        <v-icon small>
          $vuetify.icons.delete
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <!-- General validation errors -->
      <v-row
        v-for="(message, idx) in validationMessages.general"
        :key="idx"
      >
        <v-col
          cols="12"
          class="px-8 py-1 error-text"
        >
          {{ message }}
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <!-- Oral exam metadata -->
        <v-col
          cols="12"
          md="4"
          class="pl-md-10 my-md-0 py-0"
        >
          <!-- Date -->
          <date-menu
            v-model="committee.date"
            class="py-0 my-0"
            :label="$t('page.return.examinersAndEvaluators.examinerCommittees.date')"
            icon="$vuetify.icons.calendar"
            :text-field-props="{
              errorCount: 4,
              errorMessages: validationMessages.date,
              success: validationSuccessDate,
              dense: true,
              hideDetails: 'auto'
            }"
          />
          <!-- Time -->
          <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template #activator="{on, attrs}">
              <v-text-field
                v-model="committee.time"
                dense
                hide-details="auto"
                class="py-0 my-0 time-text-field"
                :label="$t('page.return.examinersAndEvaluators.examinerCommittees.time')"
                :error-messages="validationMessages.time"
                :success="validationSuccessTime"
                clearable
                prepend-icon="$vuetify.icons.clock"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-time-picker
              v-model="committee.time"
              format="24hr"
            />
          </v-menu>
          <!-- Group size -->
          <v-text-field
            v-model.number="committee.groupSize"
            dense
            hide-details
            type="number"
            class="py-0 mt-10 group-size"
            :label="$t('page.return.examinersAndEvaluators.examinerCommittees.groupSize')"
            prepend-icon="$vuetify.icons.editParticipantsAction"
          />
        </v-col>
        <v-divider
          vertical
          class="hidden-sm-and-down"
        />
        <v-col
          cols="12"
          md="8"
          class="pl-md-10"
        >
          <v-row class="pl-3 pb-2 pt-5 pt-md-0">
            <h4>
              {{ $t('page.return.examinersAndEvaluators.examinerCommittees.examiners') }}
            </h4>
          </v-row>
          <v-row
            v-for="(examinerTuqRef, examinerIndex) in committee.examinerTuqRefs"
            :key="examinerIndex"
            class="mb-0 examiner"
          >
            <!-- TuQ ref -->
            <v-col
              cols="8"
              lg="10"
            >
              <v-text-field
                v-model="committee.examinerTuqRefs[examinerIndex]"
                dense
                hide-details="auto"
                type="number"
                :label="$t('page.return.examinersAndEvaluators.examiners.id')"
                :error-messages="validationMessages.examiner[examinerTuqRef]"
                :error-count="4"
                :success="validationSuccessExaminer[examinerTuqRef]"
                @drop="dropOnTextfield($event, examinerIndex)"
                @dragover="$event.preventDefault(); $event.target.focus()"
              />
            </v-col>
            <!-- Buttons -->
            <v-col
              cols="4"
              lg="2"
              class="pb-0"
            >
              <!-- Show licences -->
              <v-tooltip
                bottom
              >
                {{ $t('page.return.examinersAndEvaluators.examinerCommittees.tooltips.showLicences') }}
                <template #activator="{on, attrs}">
                  <v-btn
                    class="get-licences-btn"
                    v-bind="attrs"
                    depressed
                    light
                    fab
                    x-small
                    v-on="on"
                    @click="getExaminerLicences(examinerTuqRef)"
                  >
                    <v-icon small>
                      $vuetify.icons.viewAction
                    </v-icon>
                  </v-btn>
                </template>
              </v-tooltip>

              <!-- Delete examiner -->
              <v-tooltip
                bottom
              >
                {{ $t('page.return.examinersAndEvaluators.examinerCommittees.tooltips.deleteExaminer') }}
                <template #activator="{on, attrs}">
                  <v-btn
                    v-bind="attrs"
                    class="delete-examiner-btn ml-3"
                    depressed
                    light
                    fab
                    x-small
                    v-on="on"
                    @click="deleteExaminer(examinerIndex)"
                  >
                    <v-icon small>
                      $vuetify.icons.delete
                    </v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </v-col>
          </v-row>
          <!-- END Examiner -->

          <!-- Button: add examiner -->
          <v-row>
            <v-btn
              class="ml-2 add-examiner-btn"
              depressed
              light
              fab
              x-small
              @click="addExaminer"
              @drop="dropOnPlus"
              @dragover="$event.preventDefault(); $event.target.focus()"
            >
              <v-icon x-small>
                $vuetify.icons.plus
              </v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import dateMenu from '@/components/DateMenu.vue';

export default {

  components: {
    dateMenu
  },

  props: {
    value: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    validationErrors: {
      type: Array,
      default: () => []
    },
    validated: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    committee: {
      get() {
        return this.value;
      },
      set(committee) {
        this.$emit('input', committee);
      }
    },

    validationMessages() {
      const messages = {
        date: [],
        time: [],
        examiner: {},
        general: []
      };
      this.committee.examinerTuqRefs.forEach((tuqRef) => { messages.examiner[tuqRef] = []; });

      this.validationErrors.forEach((validationError) => {
        switch (validationError.type) {
          case 'DateNotSet':
            messages.date.push(this.$t('page.return.examinersAndEvaluators.examinerCommittees.validationError.DateNotSet'));
            break;
          case 'DateBeforeWrittenExam':
            messages.date.push(this.$t('page.return.examinersAndEvaluators.examinerCommittees.validationError.DateBeforeWrittenExam'));
            break;
          case 'DateLaterThanAllowed':
            messages.date.push(this.$t('page.return.examinersAndEvaluators.examinerCommittees.validationError.DateLaterThanAllowed'));
            break;
          case 'TimeNotSet':
            messages.time.push(this.$t('page.return.examinersAndEvaluators.examinerCommittees.validationError.TimeNotSet'));
            break;
          case 'ExaminerNotFound':
            validationError.tuqRefs?.forEach((tuqRef) => {
              messages.examiner[tuqRef]?.push(this.$t('page.return.examinersAndEvaluators.examinerCommittees.validationError.ExaminerNotFound'));
            });
            break;
          case 'DuplicateExaminers':
            validationError.tuqRefs?.forEach((tuqRef) => {
              messages.examiner[tuqRef]?.push(this.$t('page.return.examinersAndEvaluators.examinerCommittees.validationError.DuplicateExaminers'));
            });
            break;
          case 'NotEnoughExaminers':
            messages.general.push(this.$t('page.return.examinersAndEvaluators.examinerCommittees.validationError.NotEnoughExaminers', { expected: validationError.minNumberOfExaminers }));
            break;
          default:
            messages.general.push(this.$t('page.return.examinersAndEvaluators.examinerCommittees.validationError.UnknownError', { errorType: validationError.type }));
        }
      });
      return messages;
    },

    validationSuccessExaminer() {
      const resultMap = {};
      this.committee.examinerTuqRefs.forEach((tuqRef) => {
        resultMap[tuqRef] = tuqRef
        && this.validated
        && this.validationMessages.examiner[tuqRef]?.length === 0;
      });
      return resultMap;
    },

    validationSuccessDate() {
      return this.validated && this.validationMessages.date.length === 0;
    },

    validationSuccessTime() {
      return this.validated && this.validationMessages.time.length === 0;
    }
  },

  methods: {
    getExaminerLicences(tuqRef) {
      this.$emit('show-licences', tuqRef);
    },

    addExaminer() {
      this.committee.examinerTuqRefs.push('');
    },

    deleteThis() {
      this.$emit('delete');
    },

    deleteExaminer(idx) {
      this.committee.examinerTuqRefs.splice(idx, 1);
    },

    dropOnTextfield(event, examinerIndex) {
      event.preventDefault();
      const value = event.dataTransfer.getData('text');
      if (value) {
        this.committee.examinerTuqRefs[examinerIndex] = value;
        this.$forceUpdate();
      }
    },

    dropOnPlus(event) {
      event.preventDefault();
      const value = event.dataTransfer.getData('text');
      if (value) {
        this.committee.examinerTuqRefs.push(value);
      }
    }
  }

};
</script>

<style lang="scss">
.error-text {
  color: map-get($custom-colors, error)
}
</style>
