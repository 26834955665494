import axios from 'axios';
import store from '@/plugins/vuex';

import { generateServiceBaseURL, capitalize } from '@/utils/general';
import { requestInterceptor } from '@/plugins/keycloak';

export const service = axios.create({
  baseURL: generateServiceBaseURL('pfv/pruefungsfaecher')
});

service.interceptors.response.use((response) => response, (error) => {
  const { data } = error.response;
  store.commit('notice/add', {
    message: data.message ? `${capitalize(data.message)}.` : 'Es ist ein Fehler beim Abruf des Service "Prüfungsfachverwaltung" aufgetreten. Bitte versuchen Sie es später erneut.',
    type: 'error'
  });

  return error;
});

service.interceptors.request.use(requestInterceptor);

export const getCourse = (id) => service({
  method: 'get',
  url: `/${id}`
});

export const getCourses = () => service({
  method: 'get'
});

export const searchCourse = (id, title) => service({
  method: 'get',
  url: '/suche',
  params: {
    id: id || '',
    title: title || ''
  }
});

export const pruefungsfachverwaltung = {
  getCourse,
  getCourses,
  searchCourse
};
