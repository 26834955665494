import axios from 'axios';
import store from '@/plugins/vuex';

import { generateServiceBaseURL, capitalize } from '@/utils/general';
import { requestInterceptor } from '@/plugins/keycloak';

export const service = axios.create({
  baseURL: generateServiceBaseURL('pfv/limits')
});

service.interceptors.request.use(requestInterceptor);

service.interceptors.response.use((response) => response, (error) => {
  const { data } = error.response;
  store.commit('notice/add', {
    message: data.message ? `${capitalize(data.message)}.` : 'Es ist ein Fehler beim Abruf des Service "Limitsetverwaltung" aufgetreten. Bitte versuchen Sie es später erneut.',
    type: 'error'
  });

  return error;
});

export const getLimitSet = (id) => service({
  method: 'get',
  url: `/${id}`
});

export const getLimitSets = () => service({
  method: 'get'
});

export const createLimitSet = (data) => service({
  method: 'post',
  data
});

export const updateLimitSet = (id, data) => service({
  method: 'put',
  url: `/${id}`,
  data
});

export const deleteLimitSet = (id) => service({
  method: 'delete',
  url: `/${id}`
});

export const limitsetverwaltung = {
  getLimitSets,
  getLimitSet,
  createLimitSet,
  updateLimitSet,
  deleteLimitSet
};
