<template>
  <v-micro-frontend :micro-frontend="onlineweltMetaNavigation" />
</template>

<script>
import { getRemoteURL } from '@/utils/general';

export default {
  data: () => ({
    onlineweltMetaNavigation: {
      name: 'onlinewelt-meta-navigation',
      url: `${getRemoteURL()}meta-navigation/onlinewelt-meta-navigation.umd.js`
    }
  })
};
</script>
