import Vue from 'vue';
import VueRouter from 'vue-router';

import ListView from '@/views/List.vue';
import AddView from '@/views/Add.vue';
import ReadView from '@/views/Read.vue';
import LateView from '@/views/Late.vue';
import ReturnFlowView from '@/views/ReturnFlow.vue';

import storage from '../utils/storage';

Vue.use(VueRouter);

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'list',
      component: ListView,
      beforeEnter: (to, _, next) => {
        const filters = storage.parseFromQuery(to.query);
        if (filters) {
          storage.saveToLocalStorage(filters);
          next({ name: 'list' });
        } else {
          next();
        }
      }
    },
    {
      path: '/anmelden',
      name: 'add',
      component: AddView
    },
    {
      path: '/bearbeiten/:id',
      name: 'edit',
      component: AddView,
      props: true
    },
    {
      path: '/nachmelden/:lateId',
      name: 'lateRegistration',
      component: LateView,
      props: true
    },
    {
      path: '/ansicht/:readId',
      name: 'read',
      component: ReadView,
      props: true
    },
    {
      path: '/ruecklauf/:returnId',
      name: 'returnFlow',
      component: ReturnFlowView,
      props: true
    },
    {
      path: '*',
      redirect: '/'
    }
  ],
  scrollBehavior: () => ({ x: 0, y: 0 })
});
