import axios from 'axios';
import store from '@/plugins/vuex';

import { generateServiceBaseURL, capitalize } from '@/utils/general';
import { requestInterceptor } from '@/plugins/keycloak';

export const service = axios.create({
  baseURL: generateServiceBaseURL('pv/institut')
});

service.interceptors.request.use(requestInterceptor);

service.interceptors.response.use((response) => response, (error) => {
  const { data } = error.response;
  store.commit('notice/add', {
    message: data.message ? `${capitalize(data.message)}.` : 'Es ist ein Fehler beim Abruf des Service "Institutverwaltung" aufgetreten. Bitte versuchen Sie es später erneut.',
    type: 'error'
  });

  return error;
});

export const getInstitute = (examinationInstituteId, withRegistrationSubjects = false) => service({
  method: 'get',
  url: `/${examinationInstituteId}`,
  params: { withRegistrationSubjects }
});

export const getInstitutes = () => service({
  method: 'get',
  url: ''
});

export const searchInstitute = (name, extRef) => service({
  method: 'get',
  url: '/suche',
  params: {
    name,
    extRef
  }
});

// Contacts
export const createInstituteContact = (examinationInstituteId, contact) => service({
  method: 'post',
  url: `/${examinationInstituteId}/kontakte`,
  data: contact
});

export const createExaminationCenterContact = (examinationInstituteId, examinationCenterId, contact) => service({
  method: 'post',
  url: `/${examinationInstituteId}/zentrum/${examinationCenterId}/kontakte`,
  data: contact
});

export const institutverwaltung = {
  getInstitute,
  getInstitutes,
  searchInstitute,
  createInstituteContact,
  createExaminationCenterContact
};
