import moment from 'moment';

export const dateTimeMixin = {
  methods: {
  /*
   * Format a date.
   */
    formatDate(date, format = 'DD.MM.YYYY') {
      if (!date) return null;
      return moment(date).format(format);
    },

    /*
   * Format a time.
   */
    formatTime(time, format = 'HH:mm') {
      if (!time) return null;
      return moment(time).format(format);
    }
  }
};
