<template>
  <a
    :href="linkHref"
    class="c-hero__metaItemLink text-decoration-none"
    target="_blank"
  >
    {{ linkTitle }}
    <v-icon
      color="primary"
      x-small
      class="ml-2"
      style="transform: translateY(-1px);"
    >
      $vuetify.icons.externalLink
    </v-icon>
  </a>
</template>

<script>

export default {
  props: {
    linkTitle: { type: String, required: true },
    linkHref: { type: String, required: true }
  }
};
</script>
