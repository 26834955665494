export const CONTACT_PERSON_FIELDS = {
  uuid: '',
  title: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  organizationUuid: '',

  // Locally used, will be dismissed from the remote
  added: false,
  edited: false
};
