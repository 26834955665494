import moment from 'moment/moment';

const DEFAULT_BACKEND_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';

/**
 * Returns start of the day time for given day
 *
 * @param dayFormatted String in format YYYY-MM-DD which represents a day in client's time zone
 * @returns {moment.Moment}
 */
export const startOfTheDay = (dayFormatted) => {
  let parsed = moment(dayFormatted);
  if (!parsed.isValid()) {
    parsed = moment('1970-01-01');
  }
  return parsed.startOf('day');
};

/**
 * Returns end of the day time for given day
 *
 * @param dayFormatted String in format YYYY-MM-DD which represents a day in client's time zone
 * @returns {moment.Moment}
 */
export const endOfTheDay = (dayFormatted) => {
  let parsed = moment(dayFormatted);
  if (!parsed.isValid()) {
    parsed = moment('1970-01-01');
  }
  return parsed.endOf('day');
};

/**
 * Formats date as the string in the format understandable by backend
 *
 * @param dateValue Date in Local Time Zone, could be moment object of DateTime object
 * @returns {string} UTC Formatted Time to be used in backend requests e.g. 1978-01-07T22:59:59.999Z
 */
export const formatDateForBackendCall = (dateValue) => moment.utc(dateValue).format(DEFAULT_BACKEND_DATE_FORMAT);

export function timePickerToCivilTime(time) {
  return moment(time, 'HH:mm').format('HH:mm:ss');
}
export function civilTimeToTimePicker(time) {
  return moment(time, 'HH:mm:ss').format('HH:mm');
}
export function datePickerToCivilDate(date) {
  return moment(date).format('YYYY-MM-DD');
}
